// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, observable, computed } from "mobx";

// MARK: Libraries
import moment from "moment";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, routerStore } from "../_rootStore";

// MARK: Components
import { IRowItem} from "../../components/Table/TableRow";

export default class FinesStore extends VariableChangeHandler {
	// Controls
	@observable public fines: api.Fine[] = [];
	@observable public finesLoading: boolean = false;
	@observable public finesPageOffset: number = 0;
	@observable public enumsValuesLoading: boolean = false;

	@computed public get loading(): boolean {
		return this.finesLoading;
	}

	@observable public selectedFine: api.Fine | null = null;

	// Variables
	@observable public id: string =  "";
	@observable public transactionId: string = "";
	@observable public payedBy: api.User | null = null;
	@observable public payedTo: api.User | null = null;
	@observable public request: api.Request | null = null;
	@observable public amount: number = 0;
	@observable public reason: string = "";

	// DetailsDialog
	@observable public detailsDialogIsOpen: boolean = false;

	@computed
	public get header() {
		const  header = strings.fines.table.header;
		return [
			header.id,
			header.transactionId,
			header.payedBy,
			header.payedTo,
			header.createdAt,
		];
	}
	private static formatFine(fine: api.Fine): IRowItem {
		return {
			id: fine.id,
			name: fine.id,
			columns: [
				{
					value: fine.id,
				},
				{
					value: fine.transactionId.toString(),
				},
				{
					value: fine.payedBy ? fine.payedBy.name : "",
				},
				{
					value: fine.payedTo ? fine.payedTo.name : "",
				},
				{
					value: moment(fine.createdAt).format(strings.moment.date),
				},
			],
		};
	}

	@computed
	public get finesTableRows(): IRowItem[] {
		return this.fines.map(FinesStore.formatFine);
	}

	@action
	public getFine = async (fineId: string) => {
		if (this.finesLoading) {
			return;
		}
		this.finesLoading = true;
		try {
			this.selectedFine = await api.getFine(fineId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.finesLoading = false;
		}
	};

	@action
	public getFines = async (finesPageOffset?: number) => {
		if (this.finesLoading) {
			return;
		}

		this.finesLoading = true;

		if (!finesPageOffset) {
			finesPageOffset = 0;
		}

		if (finesPageOffset < 0) {
			this.finesLoading = false;
			return;
		}

		try {
			this.fines = await api.getFines(finesPageOffset);
			this.finesPageOffset = finesPageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.finesLoading = false;
		}
	};

	@action
	public nextPage = async () => {
		await this.getFines(this.finesPageOffset + 1);
	};

	@action
	public previousPage = async () => {
		await this.getFines(this.finesPageOffset - 1);
	};

	@action
	public openDetailsDialog = async (fineId: string) => {
		this.detailsDialogIsOpen = true;
		await this.getFine(fineId);
	}

	@action
	public closeDetailsDialog = () => {
		this.detailsDialogIsOpen = false;
	}

}

// MARK: React
import * as React from "react";
import { match as Match } from "react-router";

// MARK: API
import { translatePromotionType, translatePromotionUsageType, PromotionType, AdminUserType, PromotionUsageType } from "@startapp/loc-admin-api";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Styles
import "./style.scss";

// MARK: Components
import TextField from "../../../../components/TextField";
import Button from "../../../../components/Button";
import SelectEnum from "../../../../components/SelectEnum";
import DatePicker from "../../../../components/DatePicker";
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Stores
import AuthStore from "../../../../stores/AuthStore";
import PromotionsStore from "../../../../stores/PromotionsStore";
import { routerStore } from "../../../../stores/_rootStore";

// MARK: Resources
import strings from "../../../../resources/strings";
import InputText from "../../../../components/InputText";
import ArrayField from "../../../../components/ArrayField";

interface IProps {
	authStore: AuthStore;
	promotionsStore: PromotionsStore;
	match: Match<{ promotionId?: string }>;
}

@inject("authStore", "promotionsStore")
@observer
export default class PromotionsCreateOrEdit extends React.Component<IProps> {
	public componentDidMount = async () => {

		const promotionId = this.props.match.params.promotionId;
		if (promotionId) {
			await this.props.promotionsStore.getPromotion(promotionId);
		}
		this.props.promotionsStore.getEnumsValues();
	};

	// tslint:disable-next-line:max-func-body-length
	public render() {
		const { promotionsStore, authStore } = this.props;
		const promotionStrings = strings.promotions;
		return (
			<DashboardLayout
				backButton={true}
				loading={promotionsStore.loading}
			>
				<div className="offerCreateOrEdit">
					{!promotionsStore.promotionsLoading && (
						<div className="offerCreateOrEditWrapper">
							<h2 className="createOrEditTitle">
								{
									promotionsStore.selectedPromotion ?
										strings.promotions.createOrEdit.title.edit :
										strings.promotions.createOrEdit.title.new
								}
							</h2>
							<div className="offerFormContainer">
								<TextField
									label={promotionStrings.createOrEdit.fields.id}
									name="id"
									value={promotionsStore.id}
									onChange={promotionsStore.handleValueChange}
									disabled={promotionsStore.loading}
									required={true}
								/>
								<div className="rowTwoEnum">
									{
										authStore.adminUser &&
										authStore.adminUser.type === AdminUserType.admin && (
											<div className="enum">
												<SelectEnum
													label={promotionStrings.createOrEdit.fields.type}
													enumTranslator={translatePromotionType}
													selectedEnum={promotionsStore.type}
													onChange={promotionsStore.togglePromotionType}
													enumValues={promotionsStore.promotionTypes}
												/>
											</div>
										)
									}
									<div className="enum">
										<SelectEnum
											label={promotionStrings.createOrEdit.fields.UsageType}
											enumTranslator={translatePromotionUsageType}
											selectedEnum={promotionsStore.usageType}
											onChange={promotionsStore.togglePromotionUsageType}
											enumValues={promotionsStore.promotionUsageTypes}
										/>
									</div>
									<div className="enum">
										<DatePicker
											label={strings.register.labels.expirationDate}
											name="expirationDate"
											value={promotionsStore.expirationDate}
											onChange={promotionsStore.toggleExpirationDate}
											minDate={new Date()}

										/>
									</div>
								</div>
								{
									authStore.adminUser &&
									authStore.adminUser.type === AdminUserType.licensed && (
										<TextField
											label={promotionStrings.createOrEdit.fields.percentage}
											name="percentage"
											value={promotionsStore.percentage}
											onChange={promotionsStore.handleValueChange}
											disabled={promotionsStore.loading}
											required={false}
										/>

									)
								}
								{
									authStore.adminUser &&
									authStore.adminUser.type === AdminUserType.admin && (
										promotionsStore.type === PromotionType.value ? (
											<TextField
												label={promotionStrings.createOrEdit.fields.value}
												name="value"
												value={promotionsStore.value}
												onChange={promotionsStore.handleValueChange}
												disabled={promotionsStore.loading}
												required={false}
											/>
										) : (
												<TextField
													label={promotionStrings.createOrEdit.fields.percentage}
													name="percentage"
													value={promotionsStore.percentage}
													onChange={promotionsStore.handleValueChange}
													disabled={promotionsStore.loading}
													required={false}
												/>
											)

									)
								}
								<TextField
									label={promotionStrings.createOrEdit.fields.maxUsesPerUser}
									name="maxUsesPerUser"
									value={
										promotionsStore.usageType === PromotionUsageType.firstRentOnly ? "1" :
										promotionsStore.maxUsesPerUser
									}
									onChange={promotionsStore.handleValueChange}
									disabled={
										promotionsStore.usageType === PromotionUsageType.firstRentOnly ? true :
										promotionsStore.loading
									}
									required={false}
								/>
								<TextField
									label={promotionStrings.createOrEdit.fields.maxUses}
									name="maxUses"
									value={promotionsStore.maxUses}
									onChange={promotionsStore.handleValueChange}
									disabled={promotionsStore.loading}
									required={true}
								/>
							</div>
							<ArrayField
								array={promotionsStore.userIds}
								buttonLabel={strings.banners.component.bannerContentGroup.addUsers}
								onAdd={promotionsStore.addUserId}
								onRemove={promotionsStore.removeUserId}
							>
								<InputText
									label={strings.banners.component.bannerContentGroup.userId}
									name="userId"
									type="text"
									value={promotionsStore.userId}
									onChange={promotionsStore.handleValueChange}
									disabled={promotionsStore.loading}
								/>
							</ArrayField>
							<div className="buttonsContainer">
								<Button
									onClick={() => promotionsStore.createOrEditPromotion()}
								>
									{strings.buttons.save}
								</Button>
								<Button
									onClick={() => routerStore.push("/dashboard/promotions")}
									style={{ backgroundColor: "#fff", color: "#FC6371" }}
								>
									{strings.buttons.back}
								</Button>
							</div>
						</div>
					)}
				</div>
			</DashboardLayout>
		);
	}
}

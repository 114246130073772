// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, computed, observable } from "mobx";

// MARK: Resources
import strings from "../../resources/strings";
import { cutLongStrings } from "./../../resources/cutLongStrings";

// MARK: Resources
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { ColumnType, IRowItem } from "../../components/Table/TableRow";

// MARK: Stores
import { routerStore, uiStore } from "../_rootStore";

export default class FeedStore extends VariableChangeHandler {

	// Controls
	@observable public postLoading: boolean = false;
	@observable public feedPageOffset: number = 0;
	@observable public amountPosts: number = 0;

	@observable public post: api.FeedPost[] = [];
	@observable public isOpenModal: boolean = false;
	@observable public selectedPost: api.FeedPostDetails | null = null;
	@observable public user: api.User | null = null;

	// Variables
	@observable public userId: string = "";
	@observable public text: string = "";

	@computed
	public get loading(): boolean {
		return [
			this.postLoading,
		].reduce((l, r) => l || r);
	}

	@computed
	public get header() {
		const header = strings.feed.table.header;
		return [
			header.image,
			header.author,
			header.nick,
			header.text,
		];
	}

	private static formatPost(post: api.FeedPost): IRowItem {
		return {
			id: post.id,
			name: post.text,
			columns: [
				{
					value: post.image?.url || "",
					type: ColumnType.avatar,
				},
				{
					value: post.userInfo.name,
				},
				{
					value: post.userInfo.nick,
				},
				{
					value: cutLongStrings(post.text),
				},
			],
		};
	}

	@computed
	public get feedTableRows(): IRowItem[] {
		return this.post.map(FeedStore.formatPost);
	}

	@action
	public closeModal = () => this.isOpenModal = false;

	@action
	public openModal = () => this.isOpenModal = true;

	@action
	public getPost = async (postId: string) => {
		if (this.postLoading) {
			return;
		}
		this.postLoading = true;
		try {
			this.selectedPost = await api.getFeedPostForId(postId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.postLoading = false;
		}
	};

	@action
	public getPosts = async (pageOffset: number) => {
		if (this.postLoading) {
			return;
		}
		this.postLoading = true;
		try {
			this.post = await api.getAllFeedPost(pageOffset);
			this.feedPageOffset = pageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.postLoading = false;
		}
	};

	@action
	public nextPage = async () => {
		await this.getPosts(this.feedPageOffset + 1);
	};

	@action
	public redirectToPostDetails = (postId: string) => {
		routerStore.push(`/dashboard/feed/postDetails/${postId}`);
	};

	@action
	public previousPage = async () => {
		if (this.feedPageOffset > 0) {
			await this.getPosts(this.feedPageOffset - 1);
		}
	};

	@action
	public openDeletePostDialog = async (postId: string) => {
		uiStore.showDialog(
			strings.feed.deletePostDialog,
			() => this.deletePost(postId),
		);
	};

	@action
	public deletePost = async (postId: string) => {
		if (this.postLoading) {
			return;
		}
		this.postLoading = true;
		try {
			await api.deleteFeedPost(postId);
			this.post = this.post.filter((item) => item.id !== postId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.postLoading = false;
		}
	};

	public getOnePost = async (postId: string) => {
		if (this.postLoading) {
			return;
		}
		this.postLoading = true;
		try {
			const findItem = this.post.find((item) => postId === item.id);
			this.selectedPost = findItem ? findItem : await api.getFeedPostForId(postId);
			this.user = await api.getUser(this.selectedPost.userId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.postLoading = false;
		}
	};

	@action
	public getAmountPosts = async () => {
		if (this.postLoading) {
			return;
		}
		this.postLoading = true;
		try {
			this.amountPosts = await api.getAmountPosts();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.postLoading = false;
		}
	};
}

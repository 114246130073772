// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Pages
import AdminUsersTable from "./AdminUsersTable";
import AdminUserCreateOrEdit from "./AdminUserCreateOrEdit";

interface IProps extends IStores {}
interface IStores {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class AdminUsers extends React.Component<IProps> {
	public static defaultProps: IStores;

	public render() {
		return (
			<Switch>
				<Route exact={true} path="/dashboard/adminUsers" component={AdminUsersTable} />
				<Route path="/dashboard/adminUsers/editor/:adminUserId" component={AdminUserCreateOrEdit} />
				<Route path="/dashboard/adminUsers/editor" component={AdminUserCreateOrEdit} />
			</Switch>
		);
	}
}

// MARK: React
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { allValuesCategory, allValuesColor, allValuesGender, allValuesOcasion, allValuesSize, translateCategory, translateColor, translateGender, translateOcasion, translateSize } from "@startapp/loc-admin-api";
import * as React from "react";
import { match as Match } from "react-router";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import ArrayField from "../../../../components/ArrayField";
import ListSelect from "../../../../components/ListSelect";

// MARK: Components
import SaveAndCancelFormButtons from "../../../../components/SaveAndCancelFormButtons";
import SelectEnum from "../../../../components/SelectEnum";
import TextField from "../../../../components/TextField";
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Resources
import strings from "../../../../resources/strings";
import { currencyForBR } from "../../../../resources/format";

import { routerStore } from "../../../../stores/_rootStore";

// MARK: Stores
import ItemsStore from "../../../../stores/ItemsStore";

interface IProps {
	itemsStore: ItemsStore;
	match: Match<{ itemId?: string }>;
}

@inject("itemsStore")
@observer
export default class ItemCreateOrEdit extends React.Component<IProps> {
	public componentDidMount = async () => {
		const itemId = this.props.match.params.itemId;
		if (itemId) {
			await this.props.itemsStore.getItem(itemId, true);
		}
	};

	public render() {
		const { itemsStore } = this.props;
		return (
			<DashboardLayout backButton={true} loading={itemsStore.loading}>
				<div className="itemCreateOrEdit">
					<div className="itemCreateOrEditWrapper">
						<h1 className="itemCreateOrEditWrapperTitle">{strings.item.createOrEdit.title}</h1>
						<div className="itemCreateOrEditForm">
							<TextField
								label={strings.item.table.header.brand}
								name="brand"
								value={itemsStore.brand}
								onChange={itemsStore.handleValueChange}
								disabled={itemsStore.loading}
							/>
							<TextField
								label={strings.common.originalPrice}
								name="originalPrice"
								value={itemsStore.originalPrice}
								onChange={itemsStore.handleValueChange}
								disabled={itemsStore.loading}
								required={true}
							/>
							<TextField
								label={strings.item.table.header.rentPrice}
								name="rentPrice"
								value={itemsStore.rentPrice}
								onChange={itemsStore.handleValueChange}
								disabled={itemsStore.loading}
								required={true}
							/>
							<TextField
								label={strings.item.table.header.salePrice}
								name="salePrice"
								value={itemsStore.salePrice}
								onChange={itemsStore.handleValueChange}
								disabled={itemsStore.loading}
								required={true}
							/>
							<div className="rowTwoEnum">
								<div className="enum">
									<SelectEnum
										label={strings.item.table.header.color}
										enumValues={allValuesColor()}
										enumTranslator={translateColor}
										onChange={itemsStore.toggleColor}
										selectedEnum={itemsStore.color}
										labelSpacing={0}
									/>

								</div>
								<div className="enum">
									<SelectEnum
										label={strings.item.table.header.category}
										enumValues={allValuesCategory()}
										enumTranslator={translateCategory}
										onChange={itemsStore.toggleCategory}
										selectedEnum={itemsStore.category}
										labelSpacing={0}
									/>
								</div>
							</div>
							<div className="rowTwoEnum">
								<div className="enum">
									<SelectEnum
										label={strings.item.table.header.gender}
										enumValues={allValuesGender()}
										enumTranslator={translateGender}
										onChange={itemsStore.toggleGender}
										selectedEnum={itemsStore.gender}
										labelSpacing={0}
									/>
									</div>
									<div className="enum">
										<SelectEnum
											label={strings.item.table.header.size}
											enumValues={allValuesSize()}
											enumTranslator={translateSize}
											onChange={itemsStore.toggleSize}
											selectedEnum={itemsStore.size}
											labelSpacing={0}
										/>
									</div>
							</div>
							<TextField
								label={strings.item.table.header.details}
								name="details"
								multiline={true}
								rows={2}
								value={itemsStore.details}
								onChange={itemsStore.handleValueChange}
								disabled={itemsStore.loading}
							/>
							<ArrayField
								array={itemsStore.occasions}
								buttonLabel={strings.common.add}
								onAdd={itemsStore.addOccasion}
								onRemove={itemsStore.removeOccasion}
								translator={translateOcasion}
							>
								<ListSelect
									label={strings.item.table.header.occasions}
									name="permission"
									value={itemsStore.selectedOccasion}
									handleChange={itemsStore.toggleOccasion}
									items={allValuesOcasion()}
									translator={translateOcasion}
								/>
							</ArrayField>
							<FormControlLabel
								control={(
									<Checkbox
										checked={itemsStore.adjustment}
										value={itemsStore.adjustment}
										onChange={() => itemsStore.toggleAdjustment()}
									/>
								)}
								label={strings.item.table.header.adjustable}
							/>
						</div>

						<SaveAndCancelFormButtons
							onSave={itemsStore.createOrEditItem}
							onCancel={() => routerStore.push("/dashboard/items")}
							loading={itemsStore.loading}
						/>
					</div>
				</div>
			</DashboardLayout>
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Components
import ComponentButton from "../../../../../components/ComponentButton";

// MARK: Libraries
import moment from "moment";

// MARK: Stores
import { TypeButtons } from "../../../../../stores/HomeStore";

// MARK: Resources
import strings from "../../../../../resources/strings";

interface IProps {
	typeButton: TypeButtons;
	setPeriod: (startDate: Date, endDate: Date) => Promise<void>;
	setDatePeriod: (amountDays: number) => Promise<void>;
	setTypeButton: (button: TypeButtons) => void;
	toggleModalCustomPeriod: () => void;
	startDate: Date;
	endDate: Date;
	percent: number;
	loading: boolean;
	loadingPercent: boolean;
}

@observer
export default class LineButtons extends React.Component<IProps> {

	private getMomentPeriod = (diff: number, target: "days" | "month") => {
		return {
			start: strings.formatter.moment.dayAndMonth(moment().subtract(diff, target)),
			end: strings.formatter.moment.dayAndMonth(moment()),
		};
	};

	// tslint:disable-next-line:max-func-body-length cognitive-complexity cyclomatic-complexity
	public render() {
		const { percent, loading } = this.props;

		return (
			<div className="buttonsContainer">
				<ComponentButton
					disabled={loading}
					className={this.props.typeButton === TypeButtons.yesterday ? "selectedButton" : "componentButton"}
					descriptionDate={strings.components.componentButton.yesterday}
					descriptionLabel={strings.formatter.moment.dayAndMonth(moment().subtract(1, "days"))}
					arrowUP={percent > 0}
					loadingPercentage={
						this.props.typeButton === TypeButtons.yesterday ?
							this.props.loadingPercent :
							false
					}
					percentage={
						this.props.typeButton === TypeButtons.yesterday ?
							percent !== 0 ? strings.formatter.percent(percent) : undefined :
							undefined
					}
					onClick={async () => {
						this.props.setTypeButton(TypeButtons.yesterday);
						await this.props.setPeriod(
							moment().subtract(1, "days").toDate(),
							moment().subtract(1, "days").toDate(),
						);
					}}
				/>
				<ComponentButton
					disabled={loading}
					className={this.props.typeButton === TypeButtons.today ? "selectedButton" : "componentButton"}
					descriptionDate={strings.components.componentButton.today}
					descriptionLabel={strings.formatter.moment.dayAndMonth(moment())}
					arrowUP={percent > 0}
					loadingPercentage={
						this.props.typeButton === TypeButtons.today ?
							this.props.loadingPercent :
							false
					}
					percentage={
						this.props.typeButton === TypeButtons.today ?
							percent !== 0 ? strings.formatter.percent(percent) : undefined
							: undefined
					}
					onClick={async () => {
						this.props.setTypeButton(TypeButtons.today);
						await this.props.setPeriod(moment().toDate(), moment().toDate());
					}}
				/>
				<ComponentButton
					disabled={loading}
					className={this.props.typeButton === TypeButtons.sevenBefore ? "selectedButton" : "componentButton"}
					descriptionDate={strings.components.componentButton.sevenBefore}
					arrowUP={percent > 0}
					loadingPercentage={
						this.props.typeButton === TypeButtons.sevenBefore ?
							this.props.loadingPercent :
							false
					}
					descriptionLabel={
						strings.components.componentButton.period(this.getMomentPeriod(7, "days"))
					}
					percentage={
						this.props.typeButton === TypeButtons.sevenBefore ?
							percent !== 0 ? strings.formatter.percent(percent) : undefined :
							undefined
					}
					onClick={async () => {
						this.props.setTypeButton(TypeButtons.sevenBefore);
						await this.props.setDatePeriod(7);
					}}
				/>
				<ComponentButton
					disabled={loading}
					className={this.props.typeButton === TypeButtons.fifteenBefore ? "selectedButton" : "componentButton"}
					descriptionDate={strings.components.componentButton.fifteenBefore}
					arrowUP={percent > 0}
					loadingPercentage={
						this.props.typeButton === TypeButtons.fifteenBefore ?
							this.props.loadingPercent :
							false
					}
					descriptionLabel={
						strings.components.componentButton.period(this.getMomentPeriod(15, "days"))
					}
					percentage={
						this.props.typeButton === TypeButtons.fifteenBefore ?
							percent !== 0 ? strings.formatter.percent(percent) : undefined :
							undefined
					}
					onClick={async () => {
						this.props.setTypeButton(TypeButtons.fifteenBefore);
						await this.props.setDatePeriod(15);
					}}
				/>
				<ComponentButton
					disabled={loading}
					className={this.props.typeButton === TypeButtons.thirtyBefore ? "selectedButton" : "componentButton"}
					descriptionDate={strings.components.componentButton.thirtyBefore}
					arrowUP={percent > 0}
					loadingPercentage={
						this.props.typeButton === TypeButtons.thirtyBefore ?
							this.props.loadingPercent :
							false
					}
					descriptionLabel={
						strings.components.componentButton.period(this.getMomentPeriod(1, "month"))
					}
					percentage={
						this.props.typeButton === TypeButtons.thirtyBefore ?
							percent !== 0 ? strings.formatter.percent(percent) : undefined :
							undefined
					}
					onClick={async () => {
						this.props.setTypeButton(TypeButtons.thirtyBefore);
						await this.props.setDatePeriod(30);
					}}
				/>
				<ComponentButton
					className={this.props.typeButton === TypeButtons.custom ? "selectedButtonModal" : "componentButton"}
					descriptionDate="Customizado"
					arrowUP={percent > 0}
					loadingPercentage={
						this.props.typeButton === TypeButtons.custom ?
							this.props.loadingPercent :
							false
					}
					percentage={
						this.props.typeButton === TypeButtons.custom ?
							percent !== 0 ? strings.formatter.percent(percent) : undefined :
							undefined
					}
					descriptionLabel={
						this.props.typeButton === TypeButtons.custom ?
							strings.components.componentButton.period({
								start: strings.formatter.date.dayAndMonth(this.props.startDate),
								end: strings.formatter.date.dayAndMonth(this.props.endDate),
							}) :
							strings.components.componentButton.customPeriod
					}
					onClick={() => {
						this.props.toggleModalCustomPeriod();
					}}
				/>
			</div>
		);
	}
}

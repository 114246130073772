// MARK: React
import * as React from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";

// MARK: Components
import BlogCreateOrEdit from "./BlogCreateOrEdit";
import BlogTable from "./BlogTable";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Layout
import DashboardLayout from "../../../layouts/DashboardLayout";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Blog extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
		this.props.authStore.openPageIfIsAdmin();
	};

	public render() {
		return (
			<div className="blog">
				<Switch>
					<Route exact={true} path="/dashboard/blog/editor" component={BlogCreateOrEdit} />
					<Route path="/dashboard/blog/editor/:postId" component={BlogCreateOrEdit} />
					<Route path="/dashboard/blog/" component={BlogTable} />
				</Switch>
			</div>
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

interface IProps {
	title: string;
	info: string | number;
	className?: string;
}
export default class ItemDetail extends React.Component<IProps> {
	public render() {
		return (
			<>
				<div className={`itemDetail ${this.props.className}`}>
					<h3 className="title">{this.props.title}:</h3>
					<p className="info">{this.props.info}</p>
				</div>
			</>
		);
	}
}

// MARK: React
import { observer } from "mobx-react";
import * as React from "react";

// MARK: Style
import "./style.scss";

import TextField from "../../../../../components/TextField";

// MARK: Resource
import strings from "../../../../../resources/strings";
import AddressService from "../../../../../services/AddressService";
import MaskedTextField from "../../../../../components/MaskedTextField";

interface IProps {
	addressService : AddressService;
	loading: boolean;
}
@observer
export default class EditAddress extends React.Component<IProps> {
	public render() {
		const { addressService, loading} = this.props;
		return (
			<>
				<div className="containerEditAddress">
					<h2 className="title">{strings.common.createOrEdit.fields.address.titleUser}</h2>
					<div className="inputsContainer">
						<MaskedTextField
							name="cep"
							mask="99.999-999"
							label={strings.common.createOrEdit.fields.address.cep}
							onChange={addressService.handleValueChange}
							value={addressService.cep}
							errorMessage={addressService.cepValidationErrorMessage}
							disabled={
								loading ||
								addressService.cepBeeingValidated
							}
							onMaskComplete={(cepString) => {
								addressService.cepValidate(cepString);
							}}
						/>
						<TextField
							label={strings.common.createOrEdit.fields.address.street}
							name="street"
							value={addressService.street}
							disabled={true}
							onChange={addressService.handleValueChange}
						/>
						<TextField
							label={strings.common.createOrEdit.fields.address.number}
							name="number"
							value={addressService.number}
							disabled={loading}
							onChange={addressService.handleValueChange}
						/>
						<TextField
							label={strings.common.createOrEdit.fields.address.neighborhood}
							name="neighborhood"
							value={addressService.neighborhood}
							disabled={true}
							onChange={addressService.handleValueChange}
						/>
						<TextField
							label={strings.common.createOrEdit.fields.address.city}
							name="city"
							value={addressService.city}
							disabled={true}
							onChange={addressService.handleValueChange}
						/>
						<TextField
							label={strings.common.createOrEdit.fields.address.state}
							name="state"
							value={addressService.state}
							disabled={true}
							onChange={addressService.handleValueChange}
						/>
						<TextField
							name="adicionalInfo"
							label={strings.common.createOrEdit.fields.address.adicionalInfo}
							value={addressService.additionalInfo}
							type="text"
							onChange={addressService.handleValueChange}
							disabled={loading}
						/>
						<TextField
							name="reference"
							label={strings.common.createOrEdit.fields.address.reference}
							value={addressService.reference}
							type="text"
							onChange={addressService.handleValueChange}
							disabled={loading}
						/>
					</div>
				</div>
			</>
		);
	}
}

// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Api
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: Router
import { match } from "react-router";

// MARK: Components
import ImageCase from "../../../../components/ImageCase";
import SaveAndCancelFormButtons from "../../../../components/SaveAndCancelFormButtons";
import InputText from "../../../../components/InputText";
import ListSelect from "../../../../components/ListSelect";
import DashboardLayout from "../../../../layouts/DashboardLayout";

import BannerContentQuery from "../components/BannerContentQuery";
import BannerContentUrl from "../components/BannerContentUrl";
import BannerContentGroup from "../components/BannerContentGroup";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Stores
import { routerStore } from "../../../../stores/_rootStore";
import BannerStore from "../../../../stores/BannerStore";

interface IProps {
	bannerStore: BannerStore;
	match: match<{ bannerId?: string }>;
}

@inject("bannerStore")
@observer
export default class BannerCreateOrEdit extends React.Component<IProps> {
	public componentDidMount = async () => {
		const bannerId = this.props.match.params.bannerId;
		if (bannerId) {
			await this.props.bannerStore.getBanner(bannerId);
		}
	};

	private showBannerOptions = (contentType: api.BannerContentType | string) => {
		const bannerStore = this.props.bannerStore;
		switch (contentType) {
			case api.BannerContentType.query: {
				return <BannerContentQuery bannerStore={bannerStore} />;
			}
			case api.BannerContentType.url: {
				return <BannerContentUrl bannerStore={bannerStore} />;
			}
			case api.BannerContentType.group: {
				return <BannerContentGroup bannerStore={bannerStore} />;
			}
			default: {
				return null;
			}
		}
	};

	public render() {
		const { bannerStore } = this.props;
		return (
			<DashboardLayout
				loading={bannerStore.loading}
				backButton={true}
			>
				<div className="bannerCreateOrEditWrapper">
					<div className="bannerCreateOrEditHeader">
						<h2 className="bannerCreateOrEditTitle">{bannerStore.selectedBanner ? "Editar Banner" : "Cadastrar Banner"}</h2>
					</div>
					<div className="bannerFormContainer">
						<ImageCase
							title={strings.banners.fields.image}
							onAddItem={bannerStore.imageService.selectSingleImage}
							onRemoveItem={bannerStore.imageService.removeImage}
							onReloadItem={bannerStore.imageService.reloadImage}
							imageUploaders={bannerStore.imageService.singleImageUploader}
							loading={bannerStore.loading}
						/>
						<InputText
							label={strings.banners.fields.nameBanner}
							name="nameBanner"
							type="text"
							value={bannerStore.nameBanner}
							onChange={bannerStore.handleValueChange}
							disabled={bannerStore.loading}
						/>
						<InputText
							label={strings.banners.fields.slug}
							name="slug"
							type="text"
							value={bannerStore.slug}
							onChange={bannerStore.handleValueChange}
							disabled={bannerStore.loading}
						/>
						<div className="rowTwoEnum">
							<div className="enum">
								<ListSelect
									label={strings.banners.fields.typeBanner}
									items={api.allValuesBannerType()}
									translator={api.translateBannerType}
									handleChange={bannerStore.toggleBannerType}
									value={bannerStore.type}
									name="typeBanner"
								/>
							</div>
							<div className="enum">
								<ListSelect
									label={strings.banners.fields.groupBanner}
									handleChange={bannerStore.toggleBannerContentType}
									value={bannerStore.contentType}
									translator={bannerStore.setValueEnum}
									items={api.allValuesBannerContentType().concat("null" as api.BannerContentType)}
									name="contentType"
								/>
							</div>
						</div>
						{this.showBannerOptions(bannerStore.contentType)}
					</div>
					<SaveAndCancelFormButtons
						onSave={bannerStore.createOrEditBanner}
						onCancel={() => routerStore.push("/dashboard/banners")}
						loading={bannerStore.loading}
					/>
				</div>
			</DashboardLayout>
		);
	}
}

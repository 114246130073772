// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Api
import { Image } from "@startapp/loc-admin-api";

// MARK: Components
import { Table as MTable, TableCell as MTableCell } from "@material-ui/core";
import { IconButton as MIconButton } from "@material-ui/core";
import { Checkbox as MCheckbox } from "@material-ui/core";
import { CheckBox as MCheckBox } from "@material-ui/icons";
import { CheckBoxOutlineBlank as MUncheckBox } from "@material-ui/icons";
import { TableRow as MTableRow } from "@material-ui/core";
import ActionsContainer from "./ActionsContainer";
import TableDropDown, { IDropDownItem } from "./TableDropDown";

// MARK: Resources
import strings from "../../../resources/strings";

export enum ColumnType {
	image,
	avatar,
	text,
	link,
	boolean,
}

export interface IRowItem {
	id: string;
	name: string;
	columns: IColumnItem[];
	selected?: boolean;
	isActive?: boolean;
}

export interface IColumnItem {
	type?: ColumnType;
	// tslint:disable-next-line: max-union-size
	value: string | number | ILinkValue | Image | null;
}

interface ILinkValue {
	src: string;
	name: string;
}

interface IProps {
	rowItem: IRowItem;
	onClick?: (id: string) => void;
	onEditClick?: (id: string) => void;
	onDetailsClick?: (id: string) => void;
	onDeleteClick?: (id: string, name: string) => void;
	onDropDownClick?: IDropDownItem[];
	onSelectClick?: (id: string) => void;
	onActiveClick?: (id: string, name: string) => void;
	densePadding: boolean;
}

@observer
export default class TableRow extends React.Component<IProps> {
	/**
	 * Each item of the array is a column value in the in the Table row
	 * with you choose to add a edit/delete buttonm make sure adding -1 item on the
	 * data array, to the row matches with the header size.
	 */

	public render() {
		const { rowItem, onDropDownClick, onEditClick, onDeleteClick, onDetailsClick, onSelectClick, onClick, onActiveClick } = this.props;
		const helperTitle = strings.components.table.title;

		return (
			<MTableRow
				hover={true}
				tabIndex={-1}
				onClick={() => onClick && onClick(rowItem.id)}
			>
				{onSelectClick && (
					<MTableCell
						align="center"
						padding="none"
					>
						<MCheckbox
							onClick={() => onSelectClick(rowItem.id)}
							checked={rowItem.selected}
						/>
					</MTableCell>
				)}
				{
					/**
					 * The additional Cell will only be placed if
					 * there is one of the callback functions: "onEditClick"
					 * or "onDeleteClick"
					 */
					(onEditClick || onDeleteClick || onDetailsClick) && (
						<ActionsContainer
							rowItem={rowItem}
							onEditClick={onEditClick}
							onDeleteClick={onDeleteClick}
							onDetailsClick={onDetailsClick}
						/>
					)}
				{(onActiveClick && rowItem.isActive !== undefined) && (
					<MTableCell
						key={`${rowItem.id}-active`}
						align="center"
						padding="none"
					>
						<MIconButton
							onClick={(event) => {
								event.stopPropagation();
								onActiveClick(rowItem.id, rowItem.name);
							}}
						>
							{rowItem.isActive ?
								(
									<div className="checkedIcon" title={helperTitle.isActive}>
										<MCheckBox />
									</div>
								) :
								(
									<div className="unCheckedIcon" title={helperTitle.isNotActive}>
										<MUncheckBox />
									</div>
								)
							}
						</MIconButton>
					</MTableCell>
				)}
				{
					rowItem.columns.map((columnValue, index) => {
						return (
							<MTableCell
								className="tableCell"
								key={`${rowItem.id}${index}`}
								align="center"
							>
								{(() => {
									switch (columnValue.type) {
										case ColumnType.avatar:
										case ColumnType.image: {
											return (
												<div className="roundImageContainer">
													<img
														className="roundImage"
														src={columnValue.value as string || "/userPlaceholder.svg"}
														alt="avatar-image"
													/>
												</div>
											);
										}
										case ColumnType.link: {
											return columnValue.value && (
												<a href={(columnValue.value as ILinkValue).src} target="_blank" rel="noreferrer" onClick={(event) => event.stopPropagation()}>
													{(columnValue.value as ILinkValue).name}
												</a>
											);
										}
										case ColumnType.text: {
											return columnValue.value;
										}
										default: {
											return columnValue.value;
										}
									}
								})()}
							</MTableCell>
						);
					})
				}
				{onDropDownClick && (
					<MTableCell
						key={`${rowItem.id}-dropdown`}
						align="center"
					>
						<TableDropDown
							rowItemId={rowItem.id}
							dropDownItems={onDropDownClick}
						/>
					</MTableCell>
				)}
			</MTableRow>
		);
	}
}

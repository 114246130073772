// MARK: React
import * as React from "react";
import "./style.scss";
import CircularProgress from "../../components/CircularProgress";

// MARK: Layouts
import BaseLayout from "../BaseLayout";

// MARK: Components
import Navbar from "./Navbar";

interface IProps extends IStores {
	backButton?: boolean;
	loading?: boolean;
}

interface IStores {}
export default class DashboardLayout extends React.Component<IProps> {
	public render() {
		const { children, backButton, loading } = this.props;

		return (
			<>
				<CircularProgress
					hidden={!loading}
				/>
				<div className={loading ? "dashboardLayoutContainerLoading" : "dashboardLayoutContainer"}>
					<Navbar backButton={backButton} />
					<div className="dashboardLayoutContainerChildrenContainer">
						{children}
					</div>
				</div>
				<BaseLayout />
			</>
		);
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Components
import ComponentButton from "../../../../../components/ComponentButton";

// MARK: Resources
import strings from "../../../../../resources/strings";
import { currencyForBR } from "../../../../../resources/format";

interface IProps {
	previousHomeData: api.AdminHomeData | null;
	previousHomeDataLoading: boolean;
	homeData: api.AdminHomeData | null;
	homeDataLoading: boolean;
}

@observer
export default class LineDetails extends React.Component<IProps> {

	// tslint:disable-next-line: cyclomatic-complexity
	public render = () => {
		const { homeData, homeDataLoading, previousHomeData, previousHomeDataLoading } = this.props;

		return (
			<div className="detailsContainer">
				<div className="firstField">
					<ComponentButton
						className="componentButton"
						descriptionDate={strings.components.componentButton.totalAmount}
						descriptionLabel={currencyForBR(homeData ? homeData.rentsAndSales.totalPrice : 0)}
						loadingLabel={homeDataLoading}
						arrowUP={
							(
								homeData && previousHomeData &&
								((homeData.rentsAndSales.totalPrice - previousHomeData.rentsAndSales.totalPrice) > 0)
							) ||
							undefined
						}
						percentage={
							homeData && previousHomeData && previousHomeData.rentsAndSales.totalPrice > 0 ?
							strings.formatter.percent(
								(homeData.rentsAndSales.totalPrice - previousHomeData.rentsAndSales.totalPrice) /
								previousHomeData.rentsAndSales.totalPrice,
							) :
							undefined
						}
						loadingPercentage={homeDataLoading || previousHomeDataLoading}
					/>
					<ComponentButton
						className="componentButton"
						descriptionDate={strings.components.componentButton.totalTicket}
						descriptionLabel={currencyForBR(homeData ? homeData.rentsAndSales.ticket : 0)}
						loadingLabel={homeDataLoading}
						arrowUP={
							(
								homeData && previousHomeData &&
								((homeData.rentsAndSales.ticket - previousHomeData.rentsAndSales.ticket) > 0)
							) ||
							undefined
						}
						percentage={
							homeData && previousHomeData && previousHomeData.rentsAndSales.ticket > 0 ?
							strings.formatter.percent(
								(homeData.rentsAndSales.ticket - previousHomeData.rentsAndSales.ticket) /
								previousHomeData.rentsAndSales.ticket,
							) :
							undefined
						}
						loadingPercentage={homeDataLoading || previousHomeDataLoading}
					/>
					<ComponentButton
						className="componentButton"
						descriptionDate={strings.components.componentButton.newUsers}
						descriptionLabel={homeData ? homeData.createdUsers : 0}
						loadingLabel={homeDataLoading}
						arrowUP={
							(
								homeData && previousHomeData &&
								((homeData.createdUsers - previousHomeData.createdUsers) > 0)
							) ||
							undefined
						}
						percentage={
							homeData && previousHomeData && previousHomeData.createdUsers > 0 ?
							strings.formatter.percent(
								(homeData.createdUsers - previousHomeData.createdUsers) /
								previousHomeData.createdUsers,
							) :
							undefined
						}
						loadingPercentage={homeDataLoading || previousHomeDataLoading}
					/>
					<ComponentButton
						className="componentButton"
						descriptionDate={strings.components.componentButton.registeredItens}
						descriptionLabel={homeData ? homeData.createdItems : 0}
						loadingLabel={homeDataLoading}
						arrowUP={
							(
								homeData && previousHomeData &&
								((homeData.createdItems - previousHomeData.createdItems) > 0)
							) ||
							undefined
						}
						percentage={
							homeData && previousHomeData && previousHomeData.createdItems > 0 ?
							strings.formatter.percent(
								(homeData.createdItems - previousHomeData.createdItems) /
								previousHomeData.createdItems,
							) :
							undefined
						}
						loadingPercentage={homeDataLoading || previousHomeDataLoading}
					/>
				</div>
					{/*<ComponentButton*/}
					{/*	className="componentButton"*/}
					{/*	descriptionDate={strings.components.componentButton.newLockers}*/}
					{/*	descriptionLabel={homeData.firstItems.toString()}*/}
					{/*/>*/}
			</div>
		);
	}
}

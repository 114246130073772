// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Stores
import HomeStore from "../../../../../stores/HomeStore";

// MARK: Components
import { Bar } from "react-chartjs-2";
import ListSelect from "../../../../../components/ListSelect";
import { CircularProgress as MCircularProgress } from "@material-ui/core";

// MARK: Resources
import { currencyForBR } from "../../../../../resources/format";
import strings from "../../../../../resources/strings";
import CircularProgress from "../../../../../components/CircularProgress";

interface IProps {
	homeStore: HomeStore;
}

@observer
export default class Graphic extends React.Component<IProps> {
	public render() {
		const { homeStore } = this.props;
		const options = {
			responsive: true,
			tooltips: {
				mode: "label",
				callbacks: {
					label: (tooltipItem, data) => {
						const label = data.datasets[tooltipItem.datasetIndex].label || 0;

						if (homeStore.selectedAmountFilter === api.HomeGraphicType.value) {
							return `${label} ${currencyForBR(tooltipItem.yLabel)}`;
						}

						return `${label} ${tooltipItem.yLabel}`;
					},
				},
			},
			elements: {
				line: {
					fill: false,
				},
			},
			scales: {
				xAxes: [
					{
						display: true,
						gridLines: {
							display: false,
						},
						ticks: {
							beginAtZero: true,
						},
					},
				],
				yAxes: [
					{
						type: "linear",
						display: true,
						position: "left",
						gridLines: {
							display: false,
						},
						labels: {
							show: true,
						},
						ticks: {
							beginAtZero: true,
							precision: 0,
							callback: (value) => homeStore.selectedAmountFilter === api.HomeGraphicType.value ? currencyForBR(value) : value,
						},
					},

				],
			},
		};

		return (
			<div className="graphicDataContainer">
				{homeStore.graphicDataLoading ? (
					<div className="loading">
						<MCircularProgress size={25} color={"secondary"} />
					</div>
				) : null}
				<div className="graphicSelector">
					<ListSelect
						disabled={homeStore.graphicDataLoading}
						label=""
						name=""
						value={homeStore.selectedPeriodFilter}
						handleChange={homeStore.setSelectedPeriodGraphic}
						items={["date", "hour", "dateAndHour"]}
						translator={strings.home.components.selectFilterTranslator}
					/>
					<ListSelect
						disabled={homeStore.graphicDataLoading}
						label=""
						name=""
						value={homeStore.selectedAmountFilter}
						handleChange={homeStore.setSelectedAmountGrafic}
						items={api.allValuesHomeGraphicType()}
						translator={api.translateHomeGraphicType}
					/>
				</div>
				<Bar
					data={{
						labels: homeStore.getGraphicData.map((data) => data.label),
						datasets: [{
							label: strings.common.sum,
							type: "line",
							data: homeStore.getGraphicData.map((data) => data.totalAmount),
							borderColor: "#FC6371",
							backgroundColor: "#FC6371",
						}, {
							label: strings.sales.title,
							type: "bar",
							data: homeStore.getGraphicData.map((data) => data.saleAmount),
							fill: false,
							backgroundColor: "rgb(99,183,135)",
							borderColor: "rgb(99,183,135)",
						}, {
							type: "bar",
							label: strings.rents.title,
							data: homeStore.getGraphicData.map((data) => data.rentAmount),
							fill: false,
							borderColor: "rgb(122,156,182)",
							backgroundColor: "rgb(122,156,182)",
						}],
					}}
					options={options}
				/>
			</div>
		);
	}
}

import { observer, inject } from "mobx-react";
import * as React from "react";
import "./style.scss";

// Components
import { default as MDialog } from "@material-ui/core/Dialog";
import { default as MDialogContent } from "@material-ui/core/DialogContent";
import { default as MDialogTitle } from "@material-ui/core/DialogTitle";
import { default as MDialogActions } from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Stores
import HomeStore from "../../../../../stores/HomeStore";

// Resources
import strings from "../../../../../resources/strings";
import DatePicker from "../../../../../components/DatePicker";

interface IProps {
	homeStore: HomeStore;
}

@inject("homeStore")
@observer
export default class ChangePeriodModal extends React.Component<IProps> {

	public render() {
		const { homeStore } = this.props;

		return (
			<MDialog
				open={homeStore.modalCustomPeriod}
				onClose={homeStore.toggleModalCustomPeriod}
			>
				<div className="changeDateDialog">
					<MDialogTitle className="itemAvaliabilityDialogTitle">{strings.home.customPeriod}</MDialogTitle>
					<MDialogContent className="itemAvaliabilityDialogContent" >
						<DatePicker
							label={strings.item.table.period.start}
							type="name"
							name="inputEnd"
							value={homeStore.startDate}
							disabled={homeStore.loading}
							onChange={homeStore.onStartDateChange}
						/>
						<DatePicker
							label={strings.item.table.period.end}
							type="name"
							name="inputEnd"
							value={homeStore.endDate}
							disabled={homeStore.loading}
							onChange={homeStore.onEndDateChange}
						/>
					</MDialogContent>
					<MDialogActions>
						<Button onClick={homeStore.toggleModalCustomPeriod}>
							{strings.buttons.cancel}
						</Button>
						<Button
							onClick={homeStore.setCustomPeriod}
							variant="contained"
						>
							{strings.buttons.save}
						</Button>
					</MDialogActions>
				</div>
			</MDialog>
		);
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";
import { IBadgesFilter, IColumnDynamicItem } from "../../components/PageTable";

// MARK: Components
import { ColumnType, IColumnItem, IRowItem } from "../../components/Table/TableRow";

// MARK: Libraries
import moment from "moment";

// MARK: Mobx
import { action, computed, observable } from "mobx";

// MARK: Resources
import strings from "../../resources/strings";
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { downloadFile } from "../../resources/FileManager";

// MARK: Service
import AddressService from "../../services/AddressService";
import BankAccountService from "../../services/BankAccountService";
import FilterPeriodService from "../../services/FilterService/FilterPeriodService";
import FilterTextService from "../../services/FilterService/FilterTextService";
import SortTableService from "../../services/SortTableService";

// MARK: Stores
import { authStore } from "../_rootStore";
import { routerStore, uiStore } from "../_rootStore";

export default class UsersStore extends VariableChangeHandler {
	// Services
	public sortTableService: SortTableService<api.UserFieldsOrderBy>;

	public bankAccountService = new BankAccountService();

	public filterTextService: FilterTextService;
	public filterPeriodCreatedService: FilterPeriodService;

	// Controls
	@observable public bankAccountLoading: boolean = false;
	@observable public usersLoading: boolean = false;
	@observable public userItemsLoading: boolean = false;
	@observable public verifiedDocument: boolean = false;
	@observable public usersPageOffset: number = 0;

	// Variables
	@observable public users: api.User[] = [];
	@observable public selectedUser: api.User | null = null;

	// Profile variables
	@observable public userName: string = "";
	@observable public userNick: string = "";
	@observable public userEmail: string = "";
	@observable public userBirthday: Date | null = null;
	@observable public userPhone: string = "";
	@observable public userGender: api.Gender = api.Gender.female;
	@observable public userHeight: number = 0;
	@observable public userBasePopularity: number = 0;
	@observable public userType: api.LocUserType = api.LocUserType.LOC;
	@observable public blockedUntil: Date | null = null;

	// Address
	@observable public addressService: AddressService = new AddressService();

	constructor() {
		super();

		this.selectedColumns = this.allColumns;

		this.filterTextService = new FilterTextService(this.onFilter);
		this.filterPeriodCreatedService = new FilterPeriodService(this.onFilter);

		this.sortTableService = new SortTableService<api.UserFieldsOrderBy>(
			this.onFilter,
			api.valueFromTranslationUserFieldsOrderBy,
			api.allDisplayableValuesUserFieldsOrderBy,
		);

	}

	@computed
	public get loading(): boolean {
		return [
			this.usersLoading,
			this.topUsersLoading,
			this.usersStatsLoading,

		].reduce((l , r) => (l || r));
	}

	@action
	public editUser = async () => {
		if (this.usersLoading) {
			return;
		}

		this.usersLoading = true;
		try {
			if (this.selectedUser) {
				const editUser: api.EditUser = {
					phone: {
						ddi: "55",
						ddd: this.userPhone.substring(0, 2).trim(),
						number: this.userPhone.substring(2).trim(),
					},
					email: this.userEmail,
					gender: this.userGender,
					name: this.userName,
					nick: this.userNick,
					blockedUntil: this.blockedUntil,
					type: this.userType,
					basePopularity: Number(this.userBasePopularity),
					documentsVerified: this.verifiedDocument,
				};
				await api.editUser(this.selectedUser.id, editUser);
				routerStore.replace("/dashboard/users");
				window.location.reload();
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersLoading = false;
		}
	};

	@action
	public clearEditorFields = () => {
		// Profile variables
		this.userName = "";
		this.userNick = "";
		this.userEmail = "";
		this.userBirthday = null;
		this.userPhone = "";
		this.userGender = api.Gender.female;
		this.userHeight = 0;

	};

	@action
	public setEditorFields = (user: api.User) => {
		this.userName = user.name;
		this.userNick = user.nick;
		this.userEmail = user.email;
		this.verifiedDocument = user.documentsVerified;
		this.userBirthday = user.birthday;
		this.userPhone = `${user.phone.ddd} ${user.phone.number}`;
		this.userGender = user.gender || api.Gender.female;
		this.blockedUntil = user.blockedUntil;
		this.userBasePopularity = user.basePopularity;
		this.userType = user.type;
		if (user.address) {
			this.addressService.setFields({
				...user.address,
				state: user.address.uf,
			});
		}
	};

	@action
	public getInitialUserData = async () => {
		this.getUsers();
		this.getTopTeenUsers();
		this.getUsersStats();
	};

	@action
	public getUsers = async (usersPageOffset?: number) => {
		if (this.usersLoading) {
			return;
		}

		this.usersLoading = true;

		if (!usersPageOffset) {
			usersPageOffset = 0;
		}

		if (usersPageOffset < 0) {
			this.usersLoading = false;
			return;
		}

		try {
			this.users = await api.getUsersForAdmin(usersPageOffset, this.filter);
			this.usersPageOffset = usersPageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersLoading = false;
		}
	};

	@action
	public getUser = async (userId: string, onEdit: boolean = false) => {
		if (this.usersLoading) {
			return;
		}
		this.usersLoading = true;
		try {
			this.selectedUser = await api.getUser(userId);
			if (onEdit && this.selectedUser) {
				this.setEditorFields(this.selectedUser);
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersLoading = false;
		}
	};

	@action
	private redirectInSuccess = () => {
		routerStore.push(`/dashboard/licensed`);
	};

	@action
	public redirectToUserDetails = (userId: string) => {
		routerStore.push(`/dashboard/users/${userId}`);
	};

	@action
	public redirectToUserEdit = (userId: string) => {
		routerStore.push(`/dashboard/users/editor/${userId}`);
	};

	@action
	public clear = () => {
		this.users = [];
		this.usersPageOffset = 0;
	};

	@action
	public nextPage = async () => {
		await this.getUsers(this.usersPageOffset + 1);
	};

	@action
	public previousPage = async () => {
		await this.getUsers(this.usersPageOffset - 1);
	};

	@action
	public deleteUser = async (userId: string) => {
		if (this.usersLoading) {
			return;
		}
		this.usersLoading = true;
		try {
			await api.deleteUser(userId);
			this.users = this.users.filter((user) => user.id !== userId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersLoading = false;
		}
	};

	@action
	public openDeleteUserDialog = async (userId: string, userName: string) => {
		uiStore.showDialog(
			{
				title: strings.users.table.deleteUserDialog.title,
				message: `${strings.users.table.deleteUserDialog.message}: ${userName}`,
			},
			() => this.deleteUser(userId),
		);
	};

	@action
	public onFilter = async () => {
		this.getUsersStats();
		this.getUsers();
	};

	@computed
	public get filter(): api.UsersFilter {
		return {
			createdAtUserDate: this.filterPeriodCreatedService.period,
			search: this.filterTextService.searchText,
			orderBy: this.sortTableService.sortOrder,
			topLoc: null,
		};
	}

	@action
	public toggleLocType = (type: api.LocUserType) => {
		this.userType = type;
	};

	@action
	public toggleVerifiedDocument = (isVerified: boolean) => {
		this.verifiedDocument = isVerified;
	};

	@action
	public handleBlock = (blockedDate: Date | null) => {
		this.blockedUntil = blockedDate;
	}

	/// EditUser
	@action
	public toggleGender = (gender: api.Gender) => {
		this.userGender = gender;
	};

	@action
	public handleDateChange = (date: Date) => {
		if (date) {
			this.userBirthday = date;
		}
	};

	@action
	public startCreateOrEditBankAccount = () => {
		const currentUser = authStore.adminUser;
		if (currentUser) {
			if (currentUser.licensedInfo) {
				this.bankAccountService.setBankAccount(currentUser.licensedInfo.bankAccount);
			} else {
				this.bankAccountService.clear();
			}
		}
	};

	@action
	public createOrBankAccount = async () => {
		if (this.bankAccountLoading) {
			return;
		}
		this.bankAccountLoading = true;

		try {
			const adminUser = authStore.adminUser;
			if (adminUser) {
				const newAdminUser = await api.createOrEditBankAccount(
					{
						...this.bankAccountService.getBankAccount,
					},
				);
				authStore.setAdminUser(newAdminUser);
				uiStore.showSnackbar(strings.common.added);
				this.bankAccountService.clear();
				this.redirectInSuccess();
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.bankAccountLoading = false;
		}
	};

	// Notifications
	@observable public notificationLoading: boolean = false;

	// Variables
	@observable public userIds: string[] = [];
	@observable public message: string = "";
	@observable public dialogNotification: boolean = false;

	// Export CSV

	@action
	public getUsersReport = async () => {
		if (this.usersLoading) {
			return;
		}
		this.usersLoading = true;
		try {
			const url = await api.getUsersReportForAdmin(this.filter);
			downloadFile(url);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersLoading = false;
		}
	};

	private isUserInNotificationArray = (userId: string) => {
		return this.userIds.indexOf(userId) > -1;
	};

	@action
	public toggleUserSelection = (userId: string) => {
		const indexUserID = this.userIds.indexOf(userId);
		if (indexUserID > -1) {
			this.userIds.splice(indexUserID, 1);
		} else {
			this.userIds.push(userId);
		}
	};

	@action
	public sendNotification = async () => {
		if (this.notificationLoading) {
			return;
		}
		this.notificationLoading = true;
		try {
			if (this.message === "") {
				return uiStore.showErrorSnackbar("preencha os campos");
			} else {
				await api.sendNotification(
					null,
					api.NotificationType.display,
					this.message,
					this.userIds,
				);
			}
			uiStore.showSnackbar(strings.success.sendNotification);
			this.clearNotificationFields();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.notificationLoading = false;
		}
	};

	@action
	public toggleNotificationModal = () => {
		this.dialogNotification = !this.dialogNotification;
	};

	@action
	public clearNotificationFields() {
		this.userIds = [];
		this.message = "";
		this.dialogNotification = false;
	}

	// UsersStats
	@observable public usersStats: api.UserStats | null;
	@observable public usersStatsLoading: boolean = false;

	@action
	public getUsersStats = async () => {
		if (this.usersStatsLoading) {
			return;
		}

		this.usersStatsLoading = true;

		try {
			this.usersStats = await api.getUsersStats(this.filter);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersStatsLoading = false;
		}
	};

	// TopTeenUsers
	@observable public topUsers: api.TopTeenUsers;
	@observable public topUsersLoading: boolean = false;

	@action
	public getTopTeenUsers = async () => {
		if (this.topUsersLoading) {
			return;
		}

		this.topUsersLoading = true;

		try {
			this.topUsers = await api.getTopTeenUsers();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.topUsersLoading = false;
		}
	};

	// Columns
	@observable public allColumns: string[] = [
		strings.users.table.header.id,
		strings.users.table.header.avatar,
		strings.enum.UserFieldsOrderBy.name,
		strings.enum.UserFieldsOrderBy.nick,
		strings.enum.UserFieldsOrderBy.email,
		strings.enum.UserFieldsOrderBy.cpf,
		strings.enum.UserFieldsOrderBy.createdAt,
	];
	@observable public selectedColumns: string[] = [];

	@action
	public saveSelectedColumns = (selectedColumns: string[]) => {
		this.selectedColumns = selectedColumns;
	};

	@computed
	public get headerTable() {
		return this.selectedColumns;
	}

	@computed
	public get rowsTable() {
		return this.users.map((user) => {
			const allColumns: IColumnDynamicItem[] = [
				{
					header: strings.users.table.header.id,
					value: user.id,
				},
				{
					header: strings.users.table.header.avatar,
					type: ColumnType.avatar,
					value: user.avatar ? user.avatar.url : null,
				},
				{
					header: strings.enum.UserFieldsOrderBy.name,
					value: user.name,
				},
				{
					header: strings.enum.UserFieldsOrderBy.nick,
					value: user.nick,
				},
				{
					header: strings.enum.UserFieldsOrderBy.email,
					value: user.email,
				},
				{
					header: strings.enum.UserFieldsOrderBy.cpf,
					value: user.cpf,
				},
				{
					header: strings.enum.UserFieldsOrderBy.createdAt,
					value: moment(user.createdAt).format(strings.moment.date),
				},
			];

			return {
				id: user.id,
				name: user.name,
				selected: this.isUserInNotificationArray(user.id),
				columns: this.selectedColumns
					.map((selectedColumn) => {
						return allColumns.find((column) => column.header === selectedColumn);
					})
					.filter((x) => !!x) as IColumnItem[],
			};
		});
	}

	// Badges
	@observable public showBages: boolean = false;

	@computed
	public get badgesFilter() : IBadgesFilter[] {
		let listBadges: IBadgesFilter[] = [];
			if (this.filterPeriodCreatedService.period) {
				if (this.filterPeriodCreatedService.periodStartDate && this.filterPeriodCreatedService.periodEndDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.startAndEnd(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedService.periodStartDate),
							strings.formatter.date.date(this.filterPeriodCreatedService.periodEndDate),
						),
						onClear: () => {
							this.filterPeriodCreatedService.onPeriodStartDateChange(null);
							this.filterPeriodCreatedService.onPeriodEndDateChange(null);
						},
					});
				} else if (this.filterPeriodCreatedService.periodStartDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.onlyStart(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedService.periodStartDate),
						),
						onClear: () => this.filterPeriodCreatedService.onPeriodStartDateChange(null),
					});
				} else if (this.filterPeriodCreatedService.periodEndDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.onlyEnd(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedService.periodEndDate),
						),
						onClear: () => this.filterPeriodCreatedService.onPeriodEndDateChange(null),
					});
				}
			}
			if (this.filterTextService.searchText) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.nameOrNick(this.filterTextService.searchText),
					onClear: this.filterTextService.clear,
				});
			}

		return listBadges;
	}

	@action
	public clearFilter = async () => {
		this.filterTextService.clear();
		this.filterPeriodCreatedService.clear();

		this.onFilter();
	};
}

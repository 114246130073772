// MARK: React
import { IconButton as MIconButton } from "@material-ui/core";
import { Delete as MDeleteIcon } from "@material-ui/icons";
import * as React from "react";

// MARK: Styles
import "./style.scss";

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import ImageCase from "../../../../../components/ImageCase";

// MARK: Components
import InputText from "../../../../../components/InputText";
import ArrayField from "../../../../../components/ArrayField";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Stores
import BannerStore from "../../../../../stores/BannerStore";

interface IProps {
	bannerStore: BannerStore;
}

@inject("bannerStore")
@observer
export default class BannerContentGroup extends React.Component<IProps> {
	public render() {
		const { bannerStore } = this.props;
		return (
			<>
				<ImageCase
					title={strings.banners.component.bannerContentGroup.image}
					onAddItem={bannerStore.bannerGroupService.imageService.selectSingleImage}
					onRemoveItem={bannerStore.bannerGroupService.imageService.removeImage}
					onReloadItem={bannerStore.bannerGroupService.imageService.reloadImage}
					imageUploaders={bannerStore.bannerGroupService.imageService.singleImageUploader}
					loading={bannerStore.loading}
				/>
				<InputText
					label={strings.banners.component.bannerContentGroup.url}
					name="url"
					type="text"
					value={bannerStore.bannerGroupService.url}
					onChange={bannerStore.bannerGroupService.handleValueChange}
					disabled={bannerStore.loading}
				/>
				<h2 className="bannerSectionTitle">{strings.banners.component.bannerContentGroup.title}</h2>
					a
					<h2 className="title">{strings.banners.component.title}</h2>
					<div className="arrayField">
						<div className="addField">
							<InputText
								label={strings.banners.component.bannerContentGroup.sections}
								name="sectionTitle"
								type="text"
								value={bannerStore.bannerGroupService.sectionTitle}
								onChange={bannerStore.bannerGroupService.handleValueChange}
								disabled={bannerStore.loading}
							/>
							<button
								className="addButton"
								onClick={bannerStore.bannerGroupService.addSection}
							>
								{strings.banners.component.bannerContentGroup.addSection}
							</button>
						</div>
					</div>
					{bannerStore.bannerGroupService.sections.map((section) => {
						return (
							<div key={section.title} className="sectionItems">
								{/* tslint:disable-next-line:react-a11y-accessible-headings */}
								<h4 className="sectionTitle">
									{strings.banners.component.bannerContentGroup.sectionTitle(section.title)}
									<MIconButton onClick={() => bannerStore.bannerGroupService.removeSection(section.title)}>
										<MDeleteIcon />
									</MIconButton>
								</h4>
								<div className="arrayField arrayFieldSmall">
									<div className="addField">
										<ArrayField
											array={section.itemIds}
											buttonLabel={strings.banners.component.bannerContentGroup.addItem(section.title)}
											onAdd={() => {
												bannerStore.bannerGroupService.addItemId(section.title);
											}}
											onRemove={(itemId: string) => {
												bannerStore.bannerGroupService.removeItemId(section.title, itemId);
											}}
										>
											<InputText
												label={strings.banners.component.bannerContentGroup.inputItemId}
												name="inputItemId"
												type="text"
												value={section.inputItemId}
												onChange={(e) => {
													bannerStore.bannerGroupService.handleItemId(e.target.value, section.title);
												}}
												disabled={bannerStore.loading}
											/>
										</ArrayField>
									</div>
								</div>
							</div>
						);
					})}
			</>
		);
	}
}

// MARK: React
import * as React from "react";
import ReactDOM from "react-dom";
import "./style.scss";

// MARK: Mobx
import { Provider } from "mobx-react";
import { rootStore } from "./stores/_rootStore";

// MARK: Routing
import { Router, Route, Switch } from "react-router-dom";
import * as history from "history";
import { syncHistoryWithStore } from "mobx-react-router";

const browserHistory = history.createBrowserHistory();
syncHistoryWithStore(browserHistory, rootStore.routerStore);

// MARK: Date Picker
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

// MARK: Resources
import strings from "./resources/strings";

// MARK: API
import * as api from "@startapp/loc-admin-api";

const apiUrls = {
	production: "https://api.meuloc.com/admin",
	development: "https://api.meuloc.com/admin-staging",
};

const currentApiUrl = process.env.NODE_ENV === "production"
	? apiUrls.production
	: apiUrls.development;

api.setUrl(currentApiUrl);
api.setStrings(strings);

// MARK: Pages
import Auth from "./pages/Auth";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Recovery from "./pages/Recovery";

ReactDOM.render(
	(
		<Provider {...rootStore}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
				<Router history={rootStore.routerStore.history}>
					<Switch>
						<Route exact={true} path="/" component={Auth} />
						<Route path="/dashboard" component={Dashboard} />
						<Route path="/login" component={Login} />
						<Route path="/recovery/:token" component={Recovery} />
						<Route path="/recovery" component={Recovery} />
					</Switch>
				</Router>
			</MuiPickersUtilsProvider>
		</Provider>
	),
	document.getElementById("app"),
);

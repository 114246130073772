// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Pages
import FinesTable from "./FinesTable";

interface IProps extends IStores {}
interface IStores {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Fines extends React.Component<IProps> {
	public static defaultProps: IStores;

	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
		this.props.authStore.openPageIfIsAdmin();
	};

	public render() {
		return (
			<Switch>
				<Route exact={true} path="/dashboard/fines/" component={FinesTable} />
			</Switch>
		);
	}
}

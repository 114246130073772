// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import { currencyForBR } from "../../resources/format";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: API
import { translatePaymentStatus, Request} from "@startapp/loc-admin-api";

// MARK: Libraries
import moment from "moment";

// MARK: Components
import ItemDetail from "../../components/ItemDetail";
import { Button } from "@material-ui/core";
import CancelConfirmationModal from "../CancelConfirmationModal";

interface IProps {
	request: Request;
	isAdmin: boolean;
	onCancelRequest: (id: string) => void;
}
@inject("authStore")
@observer
export default class RequestCard extends React.Component<IProps> {

	public render() {
		const text = strings.requests.details;
		const  { request, onCancelRequest, isAdmin } = this.props;
		return (
			<>
				<div className="requestRow">
						<div className="requestContainerImage">
							<div className="requestTreeColumns">
							<div className="requestImageAvatar">
								<img src={request.item.images[0].url} alt="pic" />
							</div>
						</div>
					</div>
					<div className="requestTreeColumns">
						<ItemDetail
							title={text.labels.originalPrice}
							info={currencyForBR(request.originalPrice)}
						/>
						<ItemDetail
							title={text.labels.rentPrice}
							info={currencyForBR(request.rentPrice)}
						/>
						<ItemDetail
							title={text.labels.paidAmount}
							info={request.payment ? currencyForBR(request.payment.paidAmount) : ""}
						/>
						{request.currentStatus.status !== api.LocStatus.canceled &&
						request.currentStatus.status !== api.LocStatus.notApproved &&
						isAdmin &&
						(
							<CancelConfirmationModal onCancel={() => onCancelRequest(request.id)}>
								<Button
									variant="contained"
								>
									{strings.requests.cancelRequest}
								</Button>
							</CancelConfirmationModal>
						)}
					</div>
					<div className="requestTreeColumns">
						<ItemDetail
							title={text.labels.paymentStatus}
							info={request.payment ? translatePaymentStatus(request.payment.status) : ""}
						/>
						{request.promotion.total !== 0 && (
							<ItemDetail
								title={text.labels.promotion}
								info={currencyForBR(request.promotion.total)}
							/>
						)}
						<ItemDetail
							title={text.labels.period}
							info={`${moment(request.period.start).format(strings.moment.date)} - ${moment(request.period.end).format(strings.moment.date)}`}
						/>
						<ItemDetail
							title={text.labels.creatAt}
							info={moment(request.createdAt).format(strings.moment.date)}
						/>
					</div>
				</div>
			</>
		);
	}
}

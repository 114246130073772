// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Components
import { CircularProgress as MCircularProgress } from "@material-ui/core";

// Style
import "./style.scss";

// MARK: Resources
import strings from "../../../../../../resources/strings";

export interface ITopUser {
	user: api.User;
	amount: number;
}

interface IProps {
	topUser: ITopUser[];
	titleUserCard: string;
	topUserCardLoading: boolean;
}

@observer
export default class TopUserCard extends React.Component<IProps> {
	public render() {
		return (
			<div className="usersField">
				<div className="listTopTitle">
					<p>{this.props.titleUserCard}</p>
					<p className="amout">{strings.users.listTopUser.amout} </p>
				</div>
					{this.props.topUserCardLoading ? (
						<div className="loadingField">
							<MCircularProgress size={20} color={"primary"} />
						</div>
					) :
						this.props.topUser.map((userType) => {
							return (
								<div
									className="user"
									key={userType.user.id}
								>
									<div className="listTopDetail">
										<label>{userType.user.name}</label>
									</div>
									<div className="listTopDetail">
										<p>{userType.amount}</p>
									</div>
								</div>
							);
						})
					}
			</div>
		);
	}
}

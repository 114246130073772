// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Api
import { Image } from "@startapp/loc-admin-api";

// MARK: Components
import { TableCell as MTableCell } from "@material-ui/core";
import { IconButton as MIconButton } from "@material-ui/core";

// MARK: Icons
import { Edit as MEditIcon } from "@material-ui/icons";
import { Delete as MDeleteIcon } from "@material-ui/icons";
import { Search as MDetailsIcon } from "@material-ui/icons";

export enum ColumnType {
	image,
	avatar,
	text,
	link,
	boolean,
}

export interface IRowItem {
	id: string;
	name: string;
	columns: IColumnItem[];
}

export interface IColumnItem {
	type?: ColumnType;
	// tslint:disable-next-line: max-union-size
	value: string | number | ILinkValue | Image | null;
}

interface ILinkValue {
	src: string;
	name: string;
}

interface IProps {
	rowItem: IRowItem;
	onEditClick?: (id: string) => void;
	onDetailsClick?: (id: string) => void;
	onDeleteClick?: (id: string, name: string) => void;
}

@observer
export default class ActionsContainer extends React.Component<IProps> {
	public render() {
		const { rowItem, onEditClick, onDeleteClick, onDetailsClick } = this.props;
		return (
			<MTableCell
				key={`${rowItem.id}-edit-or-delete`}
				align="center"
			>
				{onEditClick && (
					<MIconButton
						onClick={(event) => {
							event.stopPropagation();
							onEditClick(rowItem.id);
						}}
					>
						<MEditIcon />
					</MIconButton>
				)}
				{onDeleteClick && (
					<MIconButton
						onClick={(event) => {
							event.stopPropagation();
							onDeleteClick(rowItem.id, rowItem.name);
						}}
					>
						<MDeleteIcon />
					</MIconButton>
				)}
				{onDetailsClick && (
					<MIconButton
						onClick={(event) => {
							event.stopPropagation();
							onDetailsClick(rowItem.id);
						}}
					>
						<MDetailsIcon />
					</MIconButton>
				)}
			</MTableCell>
		);
	}
}

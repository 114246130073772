// MARK: React
import {inject, observer} from "mobx-react";
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Store
import UsersStore from "../../../stores/UsersStore";
import {routerStore} from "../../../stores/_rootStore";

// MARK: Components
import FormBankAccount from "../../../components/FormBankAccount";
import SaveAndCancelFormButtons from "../../../components/SaveAndCancelFormButtons";
import DashboardLayout from "../../../layouts/DashboardLayout";
import BankAccountService from "../../../services/BankAccountService";

interface IProps {
	usersStore: UsersStore;
	bankAccountService: BankAccountService;
}
@inject("usersStore")
@observer
export default class BankAccount extends React.Component<IProps> {
	public componentDidMount = () => {
		this.props.usersStore.startCreateOrEditBankAccount();
		console.log("didMount");
	};

	public render() {
		const { usersStore } = this.props;
		return (
			<DashboardLayout>
				<div className="bankAccountContainer">
					<div className="bankAccountWrapper">
						<FormBankAccount
							bankAccountService={usersStore.bankAccountService}
							loading={usersStore.loading}
						/>
						<SaveAndCancelFormButtons
							onSave={usersStore.createOrBankAccount}
							onCancel={() => routerStore.push (`/dashboard/licensed`)}
							loading={usersStore.loading}
						/>
					</div>
				</div>
			</DashboardLayout>
		);
	}
}

// MARK: React
import * as React from "react";

// MARK: Styles
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Material-UI
import { Table as MTable } from "@material-ui/core";
import { TableBody as MTableBody } from "@material-ui/core";
import { Toolbar as MToolbar } from "@material-ui/core";
import { Typography as MTypography } from "@material-ui/core";
import { Paper as MPaper } from "@material-ui/core";
import { LinearProgress as MLinearProgress } from "@material-ui/core";

// MARK: Services
import SortTableService from "../../services/SortTableService";

// MARK: Components
import TableHeader from "./TableHeader";
import TableRow, { IRowItem } from "./TableRow";
import TableFooter from "./TableFooter";
import { IDropDownItem } from "./TableRow/TableDropDown";

// MAKR: Implementation
interface IProps {
	rows: IRowItem[];
	header: string[];
	title?: string;
	loading: boolean;
	pageOffset?: number;
	tableIsEmptyMessage?: string;
	toolbar?: React.ReactNode;
	activeSelectionsToolbar?: React.ReactNode;
	headerView?: React.ReactNode;
	nextPage?: () => void;
	prevPage?: () => void;
	onAddClick?: () => void;
	onClick?: (id: string) => void;
	onEditClick?: (id: string) => void;
	onDetailsClick?: (id: string) => void;
	onDeleteClick?: (id: string, name: string) => void;
	onSelectClick?: (id: string) => void;
	onDropDownClick?: IDropDownItem[];
	onActiveClick?: (id: string, name: string) => void;
	sortService?: SortTableService<any>;
}

interface IState {
	densePadding: boolean;
}

@observer
export default class Table extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			densePadding: false,
		};
	}

	private toggleDensePadding = () => {
		this.setState({ densePadding: !this.state.densePadding });
	};

	public render() {
		const {
			pageOffset, nextPage, prevPage,
			loading, rows, onAddClick,
			onEditClick, onDeleteClick, onClick,
			title, headerView, header,
			toolbar, onDetailsClick,
			onDropDownClick, tableIsEmptyMessage, onSelectClick,
			activeSelectionsToolbar, onActiveClick,
		} = this.props;

		const selectionIsActive = !!rows.find((row) => !!row.selected);

		return (
			<MPaper>
				<MLinearProgress style={{ visibility: loading ? "visible" : "hidden", borderRadius: "20px" }} />
				{title && (
					<MToolbar style={{ display: "flex", justifyContent: "space-between", boxSizing: "border-box", width: "100%" }}>
						<MTypography variant="h6">{title}</MTypography>
						<div className="tableTools">
							{selectionIsActive ?
								activeSelectionsToolbar :
								toolbar
							}
						</div>
					</MToolbar>
				)}
				{headerView}
				{rows.length > 0 ? (
					<div className="overflowX">
						<MTable
							stickyHeader={true}
							size={this.state.densePadding ? "small" : "medium"}
							className="material-table"
						>
							<TableHeader
								header={header}
								hasActions={!!onEditClick || !!onDeleteClick || !!onDetailsClick}
								hasSelection={!!onSelectClick}
								hasActive={!!onActiveClick}
								sortService={this.props.sortService}
								densePadding={this.state.densePadding}
							/>
							<MTableBody>
								{rows.map((item, index) => (
									<TableRow
										key={`${item.id}-row-${index}`}
										rowItem={item}
										onDeleteClick={onDeleteClick}
										onEditClick={onEditClick}
										onDetailsClick={onDetailsClick}
										onClick={onClick}
										onDropDownClick={onDropDownClick}
										onSelectClick={onSelectClick}
										onActiveClick={onActiveClick}
										densePadding={this.state.densePadding}
									/>
								))}
							</MTableBody>
						</MTable>
					</div>
				) : (
					!loading && (
						<MTypography align="center">
							{tableIsEmptyMessage}
						</MTypography>
					)
				)}
					<TableFooter
						pageOffset={(pageOffset || 0) + 1}
						onNextClick={rows.length > 0 ? nextPage : () => { }}
						onPrevClick={prevPage}
						onAddClick={onAddClick}
						numberOfColumns={rows.length > 0 ? rows[0].columns.length : 0}
						densePadding={this.state.densePadding}
						toggleDensePadding={this.toggleDensePadding}
					/>
			</MPaper>
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import HomeStore from "../../../stores/HomeStore";

// MARK: Layout
import DashboardLayout from "../../../layouts/DashboardLayout";

// MARK: Components
import ChangePeriodModal from "./components/ChangePeriodModal";
import ComponentButton from "../../../components/ComponentButton";
import Graphic from "./components/Graphic";
import LineButtons from "./components/LineButtons";
import LineDetails from "./components/LineDetails";

// MARK: Resources
import strings from "../../../resources/strings";
import { currencyForBR } from "../../../resources/format";

interface IProps {
	homeStore: HomeStore;
}

@inject("authStore", "homeStore")
@observer
export default class Home extends React.Component<IProps> {

	public componentDidMount = async () => {
		await this.props.homeStore.getInitialHomeData();
		this.props.homeStore.selectAllCities();
	};

	// tslint:disable-next-line: max-func-body-length cognitive-complexity cyclomatic-complexity
	public render() {
		const { homeStore } = this.props;

		return (
			<DashboardLayout>
				<div className="homeContainer">
					<LineButtons
						loading={homeStore.loading}
						setPeriod={homeStore.setPeriod}
						setTypeButton={homeStore.setTypeButton}
						typeButton={homeStore.typeButton}
						setDatePeriod={homeStore.setDatePeriod}
						toggleModalCustomPeriod={homeStore.toggleModalCustomPeriod}
						startDate={homeStore.startDate}
						endDate={homeStore.endDate}
						percent={
							homeStore.homeData && homeStore.previousHomeData && homeStore.previousHomeData.rentsAndSales.totalPrice > 0 ?
								(
									(homeStore.homeData.rentsAndSales.totalPrice - homeStore.previousHomeData.rentsAndSales.totalPrice) /
									homeStore.previousHomeData.rentsAndSales.totalPrice
								) :
								0
						}
						loadingPercent={false}
					/>

					<div className="row">
						<Graphic homeStore={homeStore} />
						<div className="componentButtonGroups">
							<div className="componentButtonGroup">
								<ComponentButton
									descriptionDate={strings.home.total.sales}
									descriptionLabel={homeStore.homeData ? homeStore.homeData.sales.amount : 0}
									loadingLabel={homeStore.homeDataLoading}
									footerData={
										currencyForBR(homeStore.homeData ? homeStore.homeData.sales.totalPrice : 0)
									}
									arrowUP={
										(
											homeStore.homeData && homeStore.previousHomeData &&
											((homeStore.homeData.sales.amount - homeStore.previousHomeData.sales.amount) > 0)
										) ||
										undefined
									}
									percentage={
										homeStore.homeData && homeStore.previousHomeData && homeStore.previousHomeData.sales.amount > 0 ?
											strings.formatter.percent(
												(homeStore.homeData.sales.amount - homeStore.previousHomeData.sales.amount) /
												homeStore.previousHomeData.sales.amount,
											) :
											undefined
									}
									loadingPercentage={homeStore.homeDataLoading || homeStore.previousHomeDataLoading}
								/>
								<ComponentButton
									descriptionDate={strings.home.total.rents}
									loadingLabel={homeStore.homeDataLoading}
									descriptionLabel={homeStore.homeData ? homeStore.homeData.rents.amount : 0}
									footerData={
										currencyForBR(homeStore.homeData ? homeStore.homeData.rents.totalPrice : 0)
									}
									arrowUP={
										(
											homeStore.homeData && homeStore.previousHomeData &&
											((homeStore.homeData.rents.amount - homeStore.previousHomeData.rents.amount) > 0)
										) ||
										undefined
									}
									percentage={
										homeStore.homeData && homeStore.previousHomeData && homeStore.previousHomeData.rents.amount > 0 ?
											strings.formatter.percent(
												(homeStore.homeData.rents.amount - homeStore.previousHomeData.rents.amount) /
												homeStore.previousHomeData.rents.amount,
											) :
											undefined
									}
									loadingPercentage={homeStore.homeDataLoading || homeStore.previousHomeDataLoading}
								/>
							</div>
							{/*<div className="componentButtonGroup">*/}
							{/*	<ComponentButton*/}
							{/*		descriptionDate={strings.components.componentButton.firstRents}*/}
							{/*		descriptionLabel={*/}
							{/*			homeStore.homeData ? homeStore.homeData.firstRents : 0*/}
							{/*		}*/}
							{/*	/>*/}
							{/*	<ComponentButton*/}
							{/*		descriptionDate={strings.components.componentButton.firstSales}*/}
							{/*		descriptionLabel={*/}
							{/*			homeStore.homeData ? homeStore.homeData.firstSales : 0*/}
							{/*		}*/}
							{/*	/>*/}
							{/*</div>*/}
							<div className="componentButtonGroup">
								<ComponentButton
									descriptionDate={strings.home.ticket.sales}
									loadingLabel={homeStore.homeDataLoading}
									descriptionLabel={
										currencyForBR(homeStore.homeData ? homeStore.homeData.sales.ticket : 0)
									}
									arrowUP={
										(
											homeStore.homeData && homeStore.previousHomeData &&
											((homeStore.homeData.sales.ticket - homeStore.previousHomeData.sales.ticket) > 0)
										) ||
										undefined
									}
									percentage={
										homeStore.homeData && homeStore.previousHomeData && homeStore.previousHomeData.sales.ticket > 0 ?
											strings.formatter.percent(
												(homeStore.homeData.sales.ticket - homeStore.previousHomeData.sales.ticket) /
												homeStore.previousHomeData.sales.ticket,
											) :
											undefined
									}
									loadingPercentage={homeStore.homeDataLoading || homeStore.previousHomeDataLoading}
								/>
								<ComponentButton
									descriptionDate={strings.home.ticket.rents}
									loadingLabel={homeStore.homeDataLoading}
									descriptionLabel={
										currencyForBR(homeStore.homeData ? homeStore.homeData.rents.ticket : 0)
									}
									arrowUP={
										(
											homeStore.homeData && homeStore.previousHomeData &&
											((homeStore.homeData.rents.ticket - homeStore.previousHomeData.rents.ticket) > 0)
										) ||
										undefined
									}
									percentage={
										homeStore.homeData && homeStore.previousHomeData && homeStore.previousHomeData.rents.ticket > 0 ?
											strings.formatter.percent(
												(homeStore.homeData.rents.ticket - homeStore.previousHomeData.rents.ticket) /
												homeStore.previousHomeData.rents.ticket,
											) :
											undefined
									}
									loadingPercentage={homeStore.homeDataLoading || homeStore.previousHomeDataLoading}
								/>
							</div>
						</div>
					</div>
					<LineDetails
						previousHomeData={homeStore.previousHomeData}
						previousHomeDataLoading={homeStore.previousHomeDataLoading}
						homeData={homeStore.homeData}
						homeDataLoading={homeStore.homeDataLoading}
					/>
				</div>
				<ChangePeriodModal homeStore={homeStore} />
			</DashboardLayout>
		);
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, computed, observable } from "mobx";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, authStore, routerStore } from "../_rootStore";

// MARK: Components
import { ColumnType, IRowItem } from "../../components/Table/TableRow";

// MARK: Services
import BankAccountService from "../../services/BankAccountService";

// MARK: Strings
import strings from "../../resources/strings";

export default class RegisterStore extends VariableChangeHandler {
	// Services
	@observable public bankAccountService: BankAccountService = new BankAccountService();

	// Controls
	@observable public registerLoading: boolean = false;
	@observable public adminUserLoading: boolean = false;
	@observable public citiesLoading: boolean = false;
	@observable public pageOffSet: number = 0;

	// Variables
	@observable public selectedAdminUser: api.AdminUser | null = null;
	@observable public licenseds: api.AdminUser[] = [];

	//// User Variables
	@observable public name: string = "";
	@observable public email: string = "";
	@observable public password: string = "";
	@observable public adminUserType: api.AdminUserType = api.AdminUserType.admin;

	//// Licensed Variables
	////// Licensed Info
	@observable public dialogLicensed: boolean = false;
	@observable public licenses: api.CreateLicense[] = [];
	@observable public document: string = "";
	@observable public percentage: number = 0;
	@observable public expirationDate: Date = new Date();

	////// Location
	@observable public selectedState: api.StateUF = api.StateUF.AC;
	@observable public cities: api.City[] = [];
	@observable public cityName: string = "";
	@observable public selectedCity: api.City | null = null;

	private static formatAdminUser(adminUser: api.AdminUser): IRowItem {
		return {
			id: adminUser.id,
			name: adminUser.id,
			columns: [
				{
					value: adminUser.id,
				},
				{
					value: adminUser.name,
				},
				{
					value: adminUser.email,
				},
				{
					value: adminUser.licensedInfo ? adminUser.licensedInfo.document : "",
				},
				{
					value: adminUser.licensedInfo ? adminUser.licensedInfo.licenses.map((license) => license.city.name).join(", ") : "",
				},
			],
		};
	}

	@computed
	public get header() {
		const header = strings.adminUser.table.header;
		return [
			header.id,
			header.name,
			header.email,
			header.document,
			header.cityName,
		];
	}

	@computed
	public get licensedsTableRows(): IRowItem[] {
		return this.licenseds.map(RegisterStore.formatAdminUser);
	}

	@computed
	public get loading(): boolean {
		return [
			this.registerLoading,
			this.citiesLoading,
		].reduce((l, r) => l || r);
	}

	@computed
	public get states(): api.StateUF[] {
		return api.allValuesStateUF();
	}

	@computed
	public get filteredCities(): api.City[] {
		if (this.cityName.length > 0) {
			return this.cities.map((city) => {
				if (city.name.toLowerCase().indexOf(this.cityName.toLowerCase()) !== -1) {
					return city;
				}
				return null;
			}).reduce((l, r) => {
				if (r) {
					l.push(r);
				}
				return l;
			}, [] as api.City[]);
		}
		return [];
	}

	@action
	public selectState = async (state: api.StateUF) => {
		this.selectedState = state;
		await this.getCitiesFromState();
	};

	@action
	public getCitiesFromState = async () => {
		if (this.citiesLoading) {
			return;
		}

		this.citiesLoading = true;

		try {
			this.cities = await api.getStateCities(this.selectedState);
			this.cities.sort();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.citiesLoading = false;
		}
	};

	@action
	public selectCity = (city: api.City) => {
		this.selectedCity = city;
		this.cities = [];
		this.cityName = city.name;
	};

	@action
	public toggleDialogLicensed = (dialogLicensed: boolean) => {
		this.dialogLicensed = dialogLicensed;
	};

	@action
	public addLicense = () => {
		if (this.selectedCity) {
			this.licenses = [
				...this.licenses,
				{
					cityId: this.selectedCity.id,
					expirationDate: this.expirationDate,
					percentage: +this.percentage,
				},
			];
			this.clearLicensedForm();
			this.dialogLicensed = false;
		} else {
			uiStore.showSnackbar(strings.register.error.cityNull);
		}
	};

	@action
	public removeLicense = (license: api.CreateLicense) => {
		const index = this.licenses.indexOf(license);
		if (index > -1) {
			this.licenses.splice(index, 1);
		}
	};

	@action
	public getAdminUser = async (adminUserId: string) => {
		if (this.adminUserLoading) {
			return;
		}

		this.adminUserLoading = true;

		try {
			this.selectedAdminUser = await api.getAdminUser(adminUserId);
			this.setEditorFields(this.selectedAdminUser);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.adminUserLoading = false;
		}
	};

	@action
	public getLinceseds = async (pageOffset?: number) => {
		if (this.loading === true) {
			return;
		}

		this.registerLoading = true;

		if (!pageOffset) {
			pageOffset = 0;
		}

		try {
			this.licenseds = await api.getLicenseds(pageOffset);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.registerLoading = false;
		}

	}

	@action
	public createOrEditAdminUser = async (adminUserId: string) => {
		if (this.registerLoading) {
			return;
		}

		this.registerLoading = true;

		try {
			const adminUser = authStore.adminUser;

			let licensedInfo: api.CreateLicensedInfo | null = null;
			if (this.adminUserType === api.AdminUserType.licensed) {
				licensedInfo = {
					bankAccount: this.bankAccountService.getBankAccount,
					document: this.bankAccountService.documentNumber,
					licenses: this.licenses,
				};
			}

			if (adminUserId && adminUser) {
				const editedAdminUser: api.EditAdminUser = {
					name: this.name,
					email: this.email,
					type: this.adminUserType,
					password: this.password,
					licensedInfo,
				};
				await api.editAdminUser(adminUserId, editedAdminUser);
				uiStore.showSnackbar(strings.register.successEdited);
			} else {
				const newAdminUser: api.NewAdminUser = {
					name: this.name,
					email: this.email,
					password: this.password,
					type: this.adminUserType,
					licensedInfo,
				};
				await api.createAdminUser(newAdminUser);
				uiStore.showSnackbar(strings.register.successCreated);
			}
			this.clearEditorFields();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.registerLoading = false;
		}
	};

	@action
	public onDateChange = (date: Date) => {
		this.expirationDate = date;
	};

	@action
	public startCreateOrEdit = async (adminUserId?: string) => {
		if (adminUserId) {
			await this.getAdminUser(adminUserId);
			routerStore.push(`/dashboard/adminUsers/editor/${adminUserId}`);
		} else {
			routerStore.push("/dashboard/adminUsers/editor/");
		}
	};

	@action
	public toggleAdminUserType = (adminUserType: api.AdminUserType) => {
		this.adminUserType = adminUserType;
	};

	@action
	public clearEditorFields = () => {
		this.name = "";
		this.email = "";
		this.expirationDate = new Date();
		this.licenses = [];
		this.document = "";
		this.clearLicensedForm();
		this.bankAccountService.clear();
	};

	@action
	public setEditorFields = (adminUser: api.AdminUser) => {
		if (adminUser.licensedInfo) {
			this.document = adminUser.licensedInfo.document;
			this.bankAccountService.setBankAccount(adminUser.licensedInfo.bankAccount);
			this.licenses = adminUser.licensedInfo.licenses.map((license) => {
				return {
					percentage: license.percentage,
					expirationDate: license.expirationDate,
					cityId: license.city.id,
				};
			});
		}
		this.name = adminUser.name;
		this.email = adminUser.email;
		this.adminUserType = adminUser.type;
	};

	@action
	public clearLicensedForm = () => {
		this.selectedCity = null;
		this.selectedState = api.StateUF.AC;
		this.expirationDate = new Date();
		this.percentage = 0;
		this.dialogLicensed = false;
	};

	@action
	public nextPage = async () => {
		await this.getLinceseds(this.pageOffSet + 1);
	};

	@action
	public previousPage = async () => {
		await this.getLinceseds(this.pageOffSet - 1);
	};
}

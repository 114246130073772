// MARK: Api
import { AdminUserType, allValuesAdminUserType, translateAdminUserType } from "@startapp/loc-admin-api";

// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import { match } from "react-router";
import Divider from "../../../../components/Divider";

// MARK: Stores
import RegisterStore from "../../../../stores/RegisterStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Resource
import moment from "moment";

// MARK: Components
import TextField from "../../../../components/TextField";
import DateSelect from "../../../../components/DateSelect";
import FormBankAccount from "../../../../components/FormBankAccount";
import SelectCity from "../../../../components/SelectCity";
import SelectEnum from "../../../../components/SelectEnum";
import Dialog from "../../../../components/Dialog";
import Button from "../../../../components/Button";

interface IProps {
	match: match<{ adminUserId: string }>;
	registerStore: RegisterStore;
}

@inject("registerStore")
@observer
export default class AdminUserCreateOrEdit extends React.Component<IProps> {
	public async componentDidMount() {
		const adminUserId = this.props.match.params.adminUserId;
		if (adminUserId) {
			await this.props.registerStore.getAdminUser(adminUserId);
		}
	}

	public render() {
		const { registerStore } = this.props;
		return (
			<DashboardLayout>
				<div className="registerPageContainer">
					<div className="registerBox">
						<h1 className="title">{strings.register.title}</h1>
						<div className="registerForm">
							<div className="twoFields">
								<TextField
									label={strings.register.labels.name}
									name="name"
									value={registerStore.name}
									onChange={registerStore.handleValueChange}
								/>
								<TextField
									label={strings.register.labels.email}
									name="email"
									value={registerStore.email}
									onChange={registerStore.handleValueChange}
								/>
							</div>
							<div className="twoFields">
								<SelectEnum
									label={strings.register.labels.typeLicense}
									enumValues={allValuesAdminUserType()}
									enumTranslator={translateAdminUserType}
									onChange={registerStore.toggleAdminUserType}
									selectedEnum={registerStore.adminUserType}
								/>
								<TextField
									label={strings.register.labels.password}
									type="password"
									name="password"
									value={registerStore.password}
									onChange={registerStore.handleValueChange}
								/>
							</div>
							<div className="licenseField">
								<div className="addLicense">
									{registerStore.adminUserType === AdminUserType.licensed && (
										<>
											<Divider />
											<h2>{strings.register.licenseTitle}</h2>
											<FormBankAccount
												bankAccountService={registerStore.bankAccountService}
												loading={registerStore.loading}
											/>
											<button
												className="addButton"
												onClick={() => registerStore.toggleDialogLicensed(true)}
											>
												{strings.register.buttons.addLicense}
											</button>
										</>
									)}
								</div>
							</div>
							<div className="buttonArea">
								<button
									className="registerButton"
									onClick={() => registerStore.createOrEditAdminUser(this.props.match.params.adminUserId)}
								>
									{registerStore.selectedAdminUser ?
										strings.common.edit :
										strings.register.buttons.register
									}
								</button>
							</div>
							{
								registerStore.licenses.map((license) => {
									return (
										<div
											className="showLicenses"
											key={license.cityId}
										>
											<div className="section">
												<p className="title"> {strings.register.labels.expirationDate} </p>
												<p className="info">{moment(license.expirationDate).format(strings.moment.date)} </p>
											</div>
											<div className="section">
												<p className="title"> {strings.register.labels.percentage}</p>
												<p className="info"> {license.percentage}% </p>
											</div>
											<Button
												className="buttonRemoveLicense"
												onClick={() => registerStore.removeLicense(license)}
											>
												{strings.register.buttons.removeLicense}
											</Button>
										</div>
									);
								})
							}
						</div>
					</div>
				</div>
				<Dialog
					isOpen={registerStore.dialogLicensed}
					onDialogConfirm={() => registerStore.addLicense()}
					clearDialog={registerStore.clearLicensedForm}
				>
					<DateSelect
						label={strings.register.labels.expirationDate}
						name="expirationDate"
						value={registerStore.expirationDate}
						handleChangeDate={registerStore.onDateChange}
					/>
					<TextField
						label={strings.register.labels.percentage}
						name="percentage"
						value={registerStore.percentage}
						onChange={registerStore.handleValueChange}
					/>
					<SelectCity registerStore={registerStore} />
				</Dialog>
			</DashboardLayout>
		);
	}
}

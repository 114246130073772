// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import { currencyForBR } from "../../resources/format";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Libraries
import moment from "moment";

// MARK: Components
import ItemDetail from "../../components/ItemDetail";
import CancelConfirmationModal from "../CancelConfirmationModal";
import { Button } from "@material-ui/core";

interface IProps {
	sale: api.Sale;
	isAdmin: boolean;
	onCancelRequest: () => void;
}

@observer
export default class SaleCard extends React.Component<IProps> {

	public render() {
		const text = strings.sales.details;
		const  { sale, onCancelRequest, isAdmin } = this.props;
		return (
			<>
				<div className="requestRow">
						<div className="requestContainerImage">
							<div className="requestTreeColumns">
							<div className="requestImageAvatar">
								<img src={sale.item.images[0].url} alt="pic" />
							</div>
						</div>
					</div>
					<div className="requestTreeColumns">
						<ItemDetail
							title={text.labels.price}
							info={currencyForBR(sale.price)}
						/>
						{sale.currentStatus.status !== api.SaleStatusType.canceled &&
						sale.currentStatus.status !== api.SaleStatusType.notApproved &&
						isAdmin &&
						(
							<CancelConfirmationModal onCancel={onCancelRequest}>
								<Button
									variant="contained"
								>
										{strings.requests.cancelRequest}
								</Button>
							</CancelConfirmationModal>
						)}
					</div>
					<div className="requestTreeColumns">
						{
							sale.promotion && (
								sale.promotion.total !== 0 && (
									<ItemDetail
										title={text.labels.promotion}
										info={currencyForBR(sale.promotion.total)}
									/>
								)

							)
						}
						<ItemDetail
							title={text.labels.period}
							info={`${moment(sale.createdAt).format(strings.moment.date)}`}
						/>
					</div>
				</div>
			</>
		);
	}
}

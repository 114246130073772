// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Components
import { IColumnItem } from "../../components/Table/TableRow";

// MARK: Mobx
import { action, observable, computed } from "mobx";
import strings from "../../resources/strings";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, routerStore } from "../_rootStore";

// MARK: Service
import FilterPeriodService from "../../services/FilterService/FilterPeriodService";
import FilterTextService from "../../services/FilterService/FilterTextService";
import SortTableService from "../../services/SortTableService";

// MARK: Libraries
import moment from "moment";
import { IBadgesFilter } from "src/components/PageTable";

// MARK: Resources

interface IColumnDynamicItem extends IColumnItem {
	header: string;
}

export default class PromotionsStore extends VariableChangeHandler {

	// Services
	public sortTableService: SortTableService<api.PromotionFieldOrderBy>;
	public filterTextService: FilterTextService;
	public filterPeriodCreatedService: FilterPeriodService;
	public filterPeriodExpiredService: FilterPeriodService;

	// Controls
	@observable public promotionsLoading: boolean = false;
	@observable public enumsValuesLoading: boolean = false;
	@observable public promotionsPageOffset: number = 0;

	@computed public get loading(): boolean {
		return this.promotionsLoading;
	}

	@observable public promotions: api.Promotion[] = [];
	@observable public selectedPromotion: api.Promotion | null = null;

	// Variables
	@observable public id: string = "";
	@observable public value: string = "";
	@observable public percentage: string = "";
	@observable public type: api.PromotionType = api.PromotionType.percentage;
	@observable public usageType: api.PromotionUsageType = api.PromotionUsageType.firstRentOnly;
	@observable public maxUsesPerUser: string = "";
	@observable public maxUses: string = "";
	@observable public expirationDate: Date | null = null;
	@observable public userIds: string[] = [];
	@observable public userId: string = "";

	@observable public promotionTypes: api.PromotionType[] = [];
	@observable public promotionUsageTypes: api.PromotionUsageType[] = [];

	constructor() {
		super();
		this.selectedColumns = this.allColumns;

		this.filterTextService = new FilterTextService(this.onFilter);
		this.filterPeriodCreatedService = new FilterPeriodService(this.onFilter);
		this.filterPeriodExpiredService = new FilterPeriodService(this.onFilter);

		this.sortTableService = new SortTableService<api.PromotionFieldOrderBy>(
			this.onFilter,
			api.valueFromTranslationPromotionFieldOrderBy,
			api.allDisplayableValuesPromotionFieldOrderBy,
		);

	}

	@computed
	public get headerTable() {
		return this.selectedColumns;
	}
	// Table
	@computed
	public get rowsTable() {
		return this.promotions.map((promotion) => {
			const allColumns: IColumnDynamicItem[] = [
				{
					header: strings.promotions.table.header.id,
					value: promotion.id,
				},
				{
					header: strings.promotions.table.header.type,
					value: api.translatePromotionType(promotion.type),
				},
				{
					header: strings.promotions.table.header.value,
					value: promotion.type === api.PromotionType.percentage ?
						promotion.percentage ? (promotion.percentage.toString() + "%") : "porcentage"
						:
						promotion.value ? promotion.value.toString() : "valor",
				},
				{
					header: strings.promotions.table.header.usageType,
					value: api.translatePromotionUsageType(promotion.usageType),
				},
				{
					header: strings.promotions.table.header.maxUsesPerUser,
					value: promotion.maxUsesPerUser ? promotion.maxUsesPerUser.toString() : "",
				},
				{
					header: strings.promotions.table.header.maxUses,
					value: promotion.maxUses.toString(),
				},
				{
					header: strings.enum.PromotionFieldOrderBy.expirationDate,
					value: moment(promotion.expirationDate).format(strings.moment.date),
				},
				{
					header: strings.enum.PromotionFieldOrderBy.createdAt,
					value: moment(promotion.createdAt).format(strings.moment.date),
				},
			];

			return {
				id: promotion.id,
				name: promotion.type,
				columns: this.selectedColumns
					.map((selectedColumn) => {
						return allColumns.find((column) => column.header === selectedColumn);
					})
					.filter((x) => !!x) as IColumnItem[],
			};
		});
	}

	// Columns Table
	@observable public allColumns: string[] = [
		strings.enum.PromotionFieldOrderBy.id,
		strings.promotions.table.header.type,
		strings.promotions.table.header.value,
		strings.promotions.table.header.usageType,
		strings.promotions.table.header.maxUsesPerUser,
		strings.promotions.table.header.maxUses,
		strings.enum.PromotionFieldOrderBy.expirationDate,
		strings.enum.PromotionFieldOrderBy.createdAt,
	];

	@observable public selectedColumns: string[] = [];

	@action
	public saveSelectedColumns = (selectedColumns: string[]) => {
		this.selectedColumns = selectedColumns;
	};

	@action
	public createOrEditPromotion = async () => {
		if (this.loading) {
			return;
		}
		this.promotionsLoading = true;
		try {
			const promotionDetails: api.PromotionDetails = {
				id: this.id,
				value: this.type === api.PromotionType.value ? +this.value : null,
				percentage: this.type === api.PromotionType.percentage ? +this.percentage : null,
				type: this.type,
				usageType: this.usageType,
				maxUsesPerUser: this.usageType === api.PromotionUsageType.firstRentOnly ? 1 : +this.maxUsesPerUser,
				maxUses: +this.maxUses,
				expirationDate: this.expirationDate || new Date(),
				userIds: this.userIds.length > 0 ? this.userIds : null,
			};
			this.selectedPromotion = await api.createOrEditPromotion(promotionDetails);
			routerStore.replace("/dashboard/promotions");
			window.location.reload();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.promotionsLoading = false;
		}
	};

	@action
	public addUserId = () => {
		if (!this.userIds.includes(this.userId)) {
			this.userIds = [this.userId, ...this.userIds];

			this.userId = "";
		}
	};

	@action
	public removeUserId = (userId: string) => {
		const index = this.userIds.indexOf(userId);

		if (index > -1) {
			this.userIds.splice(index, 1);
		}
	};

	@action
	public getPromotion = async (promotionId: string) => {
		if (this.promotionsLoading) {
			return;
		}
		this.promotionsLoading = true;
		try {
			this.selectedPromotion = await api.getPromotion(promotionId);
			this.setEditorFields(this.selectedPromotion);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.promotionsLoading = false;
		}
	};

	@action
	public getPromotions = async (pageOffset?: number) => {
		if (this.loading) {
			return;
		}

		this.promotionsLoading = true;

		if (!pageOffset) {
			pageOffset = 0;
		}

		if (pageOffset < 0) {
			this.promotionsLoading = false;
			return;
		}
		try {
			this.promotions = await api.getPromotions(pageOffset, this.filter);
			this.promotionsPageOffset = pageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.promotionsLoading = false;
		}
	};

	@computed
	public get filter(): api.PromotionFilter {
		return {
			createdAtDate: this.filterPeriodCreatedService.period,
			orderBy: this.sortTableService.sortOrder,
			id: this.filterTextService.searchText,
			expirationDate: this.filterPeriodExpiredService.period,
		};
	}
	@action
	public getEnumsValues = () => {
		if (this.enumsValuesLoading) {
			return;
		}
		this.enumsValuesLoading = true;
		try {
			this.promotionUsageTypes = api.allValuesPromotionUsageType();
			this.promotionTypes = api.allValuesPromotionType();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.enumsValuesLoading = false;
		}
	};

	@action
	public togglePromotionType = (promotionType: api.PromotionType) => {
		this.type = promotionType;
	};

	@action
	public togglePromotionUsageType = (promotionUsageType: api.PromotionUsageType) => {
		this.usageType = promotionUsageType;
	};

	@action
	public toggleExpirationDate = (date: Date) => {
		this.expirationDate = date;
	};

	@action
	public nextPage = async () => {
		await this.getPromotions(this.promotionsPageOffset + 1);
	};

	@action
	public previousPage = async () => {
		if (this.promotionsPageOffset > 0) {
			await this.getPromotions(this.promotionsPageOffset - 1);
		}
	};

	// OnFilter
	@action
	public onFilter = async () => {
		this.getPromotions(0);
	};

	@action
	public clearFilter = async () => {
		this.filterTextService.clear();
		this.filterPeriodCreatedService.clear();
		this.filterPeriodExpiredService.clear();

		this.onFilter();
	};

	// Badges
	@observable public showBadges: boolean = false;

	@computed
	// tslint:disable-next-line:cognitive-complexity
	public get badgesFilter(): IBadgesFilter[] {
		let listBadges: IBadgesFilter[] = [];

			if (this.filterPeriodCreatedService.period) {
				if (this.filterPeriodCreatedService.periodStartDate && this.filterPeriodCreatedService.periodEndDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.startAndEnd(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedService.periodStartDate),
							strings.formatter.date.date(this.filterPeriodCreatedService.periodEndDate),
						),
						onClear: () => {
							this.filterPeriodCreatedService.onPeriodStartDateChange(null);
							this.filterPeriodCreatedService.onPeriodEndDateChange(null);
						},
					});
				} else if (this.filterPeriodCreatedService.periodStartDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.onlyStart(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedService.periodStartDate),
						),
						onClear: () => this.filterPeriodCreatedService.onPeriodStartDateChange(null),
					});
				} else if (this.filterPeriodCreatedService.periodEndDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.onlyEnd(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedService.periodEndDate),
						),
						onClear: () => this.filterPeriodCreatedService.onPeriodEndDateChange(null),
					});
				}
			}
			if (this.filterPeriodExpiredService.period) {
				if (this.filterPeriodExpiredService.periodStartDate && this.filterPeriodExpiredService.periodEndDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.startAndEnd(
							strings.filter.expirationDate,
							strings.formatter.date.date(this.filterPeriodExpiredService.periodStartDate),
							strings.formatter.date.date(this.filterPeriodExpiredService.periodEndDate),
						),
						onClear: () => {
							this.filterPeriodExpiredService.onPeriodStartDateChange(null);
							this.filterPeriodExpiredService.onPeriodStartDateChange(null);
						},
					});
				} else if (this.filterPeriodExpiredService.periodStartDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.onlyStart(
							strings.filter.expirationDate,
							strings.formatter.date.date(this.filterPeriodExpiredService.periodStartDate),
						),
						onClear: () => this.filterPeriodExpiredService.onPeriodStartDateChange(null),
					});
				} else if (this.filterPeriodExpiredService.periodEndDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.onlyEnd(
							strings.filter.expirationDate,
							strings.formatter.date.date(this.filterPeriodExpiredService.periodEndDate),
						),
						onClear: () => this.filterPeriodExpiredService.onPeriodEndDateChange(null),
					});
				}
			}

			if (this.filterTextService.searchText) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.id(this.filterTextService.searchText),
					onClear: this.filterTextService.clear,
				});
			}

		return listBadges;
	}

	@action
	public clear = () => {
		this.id = "";
		this.value = "";
		this.percentage = "";
		this.maxUses = "";
		this.maxUsesPerUser = "";
		this.expirationDate = new Date();
		this.userIds = [];
		this.userId = "";
	};

	@action
	public setEditorFields = (promotion: api.Promotion) => {
		this.id = promotion.id;
		this.value = promotion.value ? promotion.value.toString() : "";
		this.percentage = promotion.percentage ? promotion.percentage.toString() : "";
		this.type = promotion.type;
		this.usageType = promotion.usageType;
		this.maxUsesPerUser = promotion.maxUsesPerUser ? promotion.maxUsesPerUser.toString() : "";
		this.maxUses = promotion.maxUses.toString();
		this.expirationDate = promotion.expirationDate;
		this.userIds = promotion.userIds || [];
	};

	@action
	public startPromotionCreatOrEdit = (promotionId?: string) => {
		if (promotionId) {
			this.getPromotion(promotionId);
			routerStore.push(`/dashboard/promotions/editor/${promotionId}`);
		} else {
			this.selectedPromotion = null;
			this.clear();
			routerStore.push("/dashboard/promotions/editor");
		}
	};

}

// MARK: React
import * as React from "react";
import "./style.scss";
import { DragDropContext, Droppable, Draggable, DropResult,  DraggableLocation } from "react-beautiful-dnd";

// MARK: Components
import Button from "../../../../components/Button";

// MARK: Resources
import strings from "../../../../resources/strings";

interface IProps {
	selectedColumns: string[];
	allColumns: string[];
	saveSelectedColumns: (selectedColumns: string[]) => void;
}

interface IState {
	availableColumns: string[];
	selectedColumns: string[];
}

export default class EditTable extends React.Component<IProps, IState> {
	public componentWillMount() {
		this.setState({
			availableColumns: this.props.allColumns.filter((columnType) => !this.props.selectedColumns.includes(columnType)),
			selectedColumns: this.props.selectedColumns,
		});
	}

	public getRows = (draggableLocationId: string) => {
		return draggableLocationId === "availableColumns" ?
			this.state.availableColumns :
			this.state.selectedColumns;
	}

	public move = (sourceList: string[], destinationList: string[], source: DraggableLocation, destination: DraggableLocation) => {
		const newSourceList: string[] = Array.from(sourceList);
		const newDestinationList: string[] = Array.from(destinationList);
		const [removed] = newSourceList.splice(source.index, 1);

		newDestinationList.splice(destination.index, 0, removed);

		return {
			newSourceList,
			newDestinationList,
		};
	};

	public reorder = (list: string[], startIndex: number, endIndex: number) => {
		const result: string[] = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	public onDragEnd = (result: DropResult) => {
		const { source, destination } = result;

		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const newColumns = this.reorder(
				this.getRows(source.droppableId),
				source.index,
				destination.index,
			);

			if (source.droppableId === "availableColumns") {
				this.setState({
					availableColumns: newColumns,
				});
			} else {
				this.setState({
					selectedColumns: newColumns,
				});
			}
		} else {
			const newLists = this.move(
				this.getRows(source.droppableId),
				this.getRows(destination.droppableId),
				source,
				destination,
			);

			if (source.droppableId === "availableColumns") {
				this.setState({
					availableColumns: newLists.newSourceList,
					selectedColumns: newLists.newDestinationList,
				});
			} else {
				this.setState({
					availableColumns: newLists.newDestinationList,
					selectedColumns: newLists.newSourceList,
				});
			}
		}
	}

	public render() {
		const { saveSelectedColumns } = this.props;
		const { selectedColumns, availableColumns } = this.state;

		return (
			<div className="editTableContainer">
				<div className="editTableContainerDragDrop">
					<DragDropContext
						onDragEnd={this.onDragEnd}
					>
						<Droppable droppableId="customRows">
							{(provided, snapshot) => (
								<div
									className={!snapshot.isDraggingOver ? "columnView" : "columnDraggingView"}

									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									<h3>{strings.components.table.title.selectedColumns}</h3>
									{selectedColumns.map((row, index) => (
										<Draggable
											key={row}
											draggableId={row}
											index={index}
										>
											{(providedDraggable, snapshotDraggable) => (
												<div
													className={!snapshotDraggable.isDragging ? "columnRowContainer" : "columnRowDraggingContainer"}

													ref={providedDraggable.innerRef}
													{...providedDraggable.draggableProps}
													{...providedDraggable.dragHandleProps}
												>
													{row}
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
						<Droppable
							droppableId="availableColumns"
						>
							{(provided, snapshot) => (
								<div
									className={!snapshot.isDraggingOver ? "columnView" : "columnDraggingView"}

									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									<h3>{strings.components.table.title.availableColumns}</h3>
									{availableColumns.map((row, index) => {
										return (
											<Draggable
												key={row}
												draggableId={row}
												index={index}
											>
												{(providedDraggable, snapshotDraggable) => (
													<div
														className={!snapshotDraggable.isDragging ? "columnRowContainer" : "columnRowDraggingContainer"}

														ref={providedDraggable.innerRef}
														{...providedDraggable.draggableProps}
														{...providedDraggable.dragHandleProps}
													>
														{row}
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
				<div className="buttonField">
					<Button
						onClick={() => saveSelectedColumns(this.state.selectedColumns)}
					>
						{strings.buttons.save}
					</Button>
				</div>
			</div>
		);
	}
}

import * as React from "react";
import "./style.scss";

// Stores
import { observer, inject } from "mobx-react";
import AuthStore from "../../stores/AuthStore";

// MARK: Layout
import BaseLayout from "../../layouts/BaseLayout";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Auth extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.loginOrOpenDashboard();
	}

	public render() {
		return (
			<BaseLayout />
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// 	MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Stores
import RequestsStore from "../../stores/RequestsStore";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Components
import Dialog from "../Dialog";
import TextField from "../TextField";
import SelectEnum from "../SelectEnum";

interface IProps {
	requestsStore: RequestsStore;
	onDialogConfirm: (() => void) | null;
	clearDialog: () => void;
	isOpen: boolean;
	dialogTitle: string;
}

@inject("requestsStore")
@observer
export default class FineDialog extends React.Component<IProps> {
	public render() {
		const { requestsStore, onDialogConfirm, clearDialog, isOpen, dialogTitle} = this.props;
		return (
			<Dialog
				onDialogConfirm={onDialogConfirm}
				clearDialog={clearDialog}
				isOpen={isOpen}
				dialogTitle={dialogTitle}
			>
				<div className="inputsContainer">
					<SelectEnum
						label={strings.requests.table.fineDialog.labels.payedBy}
						enumTranslator={api.translateOwnerOrRequester}
						selectedEnum={requestsStore.payedBy}
						onChange={requestsStore.togglePayedBy}
						enumValues={requestsStore.requestFinePayeds}
					/>
					<SelectEnum
						label={strings.requests.table.fineDialog.labels.payedTo}
						enumTranslator={api.translateOwnerOrRequester}
						selectedEnum={requestsStore.payedTo}
						onChange={requestsStore.togglePayedTo}
						enumValues={requestsStore.requestFinePayeds}
					/>
					<TextField
						label={strings.requests.table.fineDialog.labels.reason}
						name="reason"
						value={requestsStore.reason}
						onChange={requestsStore.handleValueChange}
						required={true}
						disabled={requestsStore.loading}
						rows="3"
						multiline={true}
					/>
					<TextField
						label={strings.requests.table.fineDialog.labels.amount}
						name="amount"
						value={requestsStore.amount}
						onChange={requestsStore.handleValueChange}
						required={true}
						disabled={requestsStore.loading}
					/>
				</div>
			</Dialog>
		);
	}
}

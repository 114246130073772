// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Components
import DashboardLayout from "../../../../layouts/DashboardLayout";
import PageTable from "../../../../components/PageTable";

// MARK: Stores
import BlogStore from "../../../../stores/BlogStore";

// MARK: Resources
import strings from "../../../../resources/strings";
import Button from "../../../../components/Button";

interface IProps {
	blogStore: BlogStore;
}

@inject("blogStore")
@observer
export default class BlogTable extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.blogStore.getPosts(0);
	};

	public render() {
		const {blogStore} = this.props;
		return (
			<DashboardLayout>
				<PageTable
					tableConfig={{
						title: strings.blog.title,
						rows: blogStore.blogTableRows,
						header: blogStore.header,
						pageOffset: blogStore.blogPageOffset,
						loading: blogStore.loading,
						prevPage: blogStore.previousPage,
						nextPage: blogStore.nextPage,
						onAddClick: blogStore.startPostCreatOrEdit,
						onEditClick: blogStore.startPostCreatOrEdit,
						onDeleteClick: blogStore.openDeletePostDialog,
						onActiveClick: blogStore.openActivePostDialog,
					}}
				/>
			</DashboardLayout>
		);
	}
}

// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Components
import PageTable from "../../../../components/PageTable";
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Stores
import AuthStore from "../../../../stores/AuthStore";
import PromotionsStore from "../../../../stores/PromotionsStore";

// MARK: Resources
import strings from "../../../../resources/strings";

interface IProps {
	authStore: AuthStore;
	promotionsStore: PromotionsStore;
}

@inject("authStore", "promotionsStore")
@observer
export default class PromotionsTable extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.promotionsStore.getPromotions(0);
	};

	public render() {
		const { promotionsStore } = this.props;
		const onAddClick = promotionsStore.startPromotionCreatOrEdit;
		const onEditClick = promotionsStore.startPromotionCreatOrEdit;

		return (
			<DashboardLayout>
				<PageTable
					filter={{
						filters: [
							{
								filterTextService: promotionsStore.filterTextService,
								title: strings.filter.nameOrExpiration,
								type: "text",
							},
							{
								type: "period",
								title: strings.filter.period.createdAt,
								filterPeriodService: promotionsStore.filterPeriodCreatedService,
							},
							{
								type: "period",
								title: strings.filter.expirationDate,
								filterPeriodService: promotionsStore.filterPeriodExpiredService,
							},
						],
						onFilter: promotionsStore.onFilter,
						badgesFilter: promotionsStore.badgesFilter,
						clearFilter: promotionsStore.clearFilter,
					}}
					editColumnsTable={{
						allColumns: promotionsStore.allColumns,
						saveSelectedColumns: promotionsStore.saveSelectedColumns,
						selectedColumns: promotionsStore.selectedColumns,
					}}
					tableConfig={{
						sortService: promotionsStore.sortTableService,
						title: strings.promotions.title,
						rows: promotionsStore.rowsTable,
						header: promotionsStore.headerTable,
						pageOffset: promotionsStore.promotionsPageOffset,
						loading: promotionsStore.loading,
						prevPage: promotionsStore.previousPage,
						nextPage: promotionsStore.nextPage,
						onAddClick,
						onEditClick,
					}}
				/>
			</DashboardLayout>
		);
	}
}

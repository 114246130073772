// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../../../resources/strings";
import {currencyForBR} from "../../../../resources/format";

// MARK: Stores
import AuthStore from "../../../../stores/AuthStore";
import ItemsStore from "../../../../stores/ItemsStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Components
import PageTable from "../../../../components/PageTable";

interface IProps extends IStores { }

interface IStores {
	itemsStore: ItemsStore;
	authStore: AuthStore;
}
@inject("itemsStore", "routerStore")
@observer
export default class ItemsTable extends React.Component<IProps> {
	public static defaultProps: IStores;

	public async componentDidMount() {
		this.props.itemsStore.getItemStats();
		this.props.itemsStore.getItems();
	}

	public render() {
		const { itemsStore } = this.props;

		return (
			<DashboardLayout
			>
				<PageTable
					onExportTable={itemsStore.getUsersReport}
					filter={{
						badgesFilter: itemsStore.badgesFilter,
						clearFilter: itemsStore.onClearFilter,
						onFilter: itemsStore.onFilter,
						filters: [
							{
								title: strings.filter.createdAt,
								type: "period",
								filterPeriodService: itemsStore.filterCreatedAtService,
							},
						],
					}}
					statsList={
						[
							{
								label: strings.item.stats.totalAmount,
								value: itemsStore.itemStats ?
									itemsStore.itemStats.totalAmount.toString() : "",
							}, {
								label: strings.item.stats.averageRentPrice,
								value: itemsStore.itemStats ?
									currencyForBR(itemsStore.itemStats.averageRentPrice) : "",
							}, {
								label: strings.item.stats.averageSalePrice,
								value: itemsStore.itemStats ?
									currencyForBR(itemsStore.itemStats.averageSalePrice) : "",
							},
						]
					}
					statsLoading={itemsStore.itemStatsLoading}
					tableConfig={{
						loading: itemsStore.loading,
						header: itemsStore.headerTable,
						title: strings.item.title,
						nextPage: itemsStore.nextPage,
						prevPage: itemsStore.previousPage,
						rows: itemsStore.rowsTable,
						pageOffset: itemsStore.itemPageOffset,
						onEditClick: itemsStore.startEventCreateOrEdit,
						onDetailClick: itemsStore.redirectToItemDetails,
						onDeleteClick: itemsStore.openDeleteItemDialog,
						sortService: itemsStore.sortTableService,
					}}
					editColumnsTable={{
						selectedColumns: itemsStore.selectedColumns,
						saveSelectedColumns: itemsStore.saveSelectedColumns,
						allColumns: itemsStore.allColumns,
					}}
				/>
			</DashboardLayout>
		);
	}
}

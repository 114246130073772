// MARK: React
import { translateCategory, translateColor, translateDepartment, translateGender, translateSize } from "@startapp/loc-admin-api";
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import { match as Match } from "react-router";
import strings from "../../../../resources/strings";
import {currencyForBR} from "../../../../resources/format";

// MARK: Components
import UserCard from "../../../../components/UserCard";
import ItemDetail from "../../../../components/ItemDetail";

// MARK: Stores
import ItemsStore from "../../../../stores/ItemsStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

interface IProps extends IStores {}

interface IStores {
	itemsStore: ItemsStore;
	match: Match<{ itemId: string }>;
}

@inject("itemsStore")
@observer
export default class ItemDetails extends React.Component<IProps> {
	public static defaultProps: IStores;

	public componentDidMount = async () => {
		const itemId = this.props.match.params.itemId;
		await this.props.itemsStore.getItem(itemId);
	};

	public render() {
		const {itemsStore} = this.props;
		return (
			<>
				{
					itemsStore.selectedItem && (
						<DashboardLayout backButton={true} loading={itemsStore.loading}>
							<div className="itemDetailsContainer">
								<div className="itemDetailsWrapper">
									<div className="itemDetailsTitle">
										<h2>{strings.item.title}</h2>
									</div>
									<div className="itemRow">
										<div className="itemTreeColumns image">
											<div className="itemContainerImages">
												<div className="itemDetailsImage">
													<img
														className="itemImage"
														src={itemsStore.selectedItem.images[0].url}
														alt={itemsStore.selectedItem.id}
													/>
												</div>
											</div>
										</div>
										<div className="itemTreeColumns">
											<ItemDetail
												title={strings.item.table.header.department}
												info={
													itemsStore.selectedItem.department ? translateDepartment(itemsStore.selectedItem.department) :
														strings.item.table.header.department
												}
											/>
											<ItemDetail
												title={strings.item.table.header.category}
												info={
													itemsStore.selectedItem.category ? translateCategory(itemsStore.selectedItem.category) :
														strings.item.table.header.category
												}
											/>
											<ItemDetail
												title={strings.item.table.header.gender}
												info={
													itemsStore.selectedItem.gender ? translateGender(itemsStore.selectedItem.gender) :
														strings.users.table.header.genderUndefined}
											/>
											<ItemDetail
												title={strings.item.table.header.adjustment}
												info={
													itemsStore.selectedItem.adjustment ? strings.item.table.header.adjustable :
														strings.item.table.header.notAdjustable
												}
											/>
											<ItemDetail
												title={strings.item.table.header.color}
												info={
													itemsStore.selectedItem.color ? translateColor(itemsStore.selectedItem.color) :
														strings.item.table.header.color
												}
											/>
										</div>
										<div className="itemTreeColumns">
											<ItemDetail
												title={strings.item.table.header.brand}
												info={
													itemsStore.selectedItem.brand ? itemsStore.selectedItem.brand :
														strings.item.table.header.brandUndefined
												}
											/>
											<ItemDetail
												title={strings.item.table.header.rentPrice}
												info={currencyForBR(itemsStore.selectedItem.rentPrice || 0)}
											/>
											<ItemDetail
												title={strings.common.originalPrice}
												info={currencyForBR(itemsStore.selectedItem.originalPrice)}
											/>
											<ItemDetail
												title={strings.item.table.header.salePrice}
												info={currencyForBR(itemsStore.selectedItem.salePrice || 0)}
											/>
											<ItemDetail
												title={strings.item.table.header.size}
												info={
													itemsStore.selectedItem.size ? translateSize(itemsStore.selectedItem.size) :
														strings.item.table.header.sizeUndefined}
											/>
										</div>
									</div>
									<div className="itemRow">
										<div className="itemTreeColumns">
											<ItemDetail
												title={strings.item.table.header.occasions}
												info={itemsStore.translateOccasions(itemsStore.selectedItem.ocasions)}
											/>
										</div>
										<div className="itemTreeColumns">
											<ItemDetail
												title={strings.item.table.header.details}
												info={itemsStore.selectedItem.details}
											/>
										</div>
										{itemsStore.selectedItem.calendar.length > 0 && (
											<div className="itemTreeColumns">
												<ItemDetail
													title={strings.item.table.header.calendar}
													info={itemsStore.periodCalendar(itemsStore.selectedItem.calendar)}
												/>
											</div>
										)}
									</div>
									<div className="itemRow">
										<UserCard user={itemsStore.selectedItem.owner} title={strings.item.ownerItem}/>
									</div>
								</div>
							</div>
						</DashboardLayout>
					)
				}
			</>
		);
	}
}

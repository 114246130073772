// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Services
import "./style.scss";

// MARK: Services
import FilterEnumService from "../../../../services/FilterService/FilterEnumService";

interface IProps {
	service: FilterEnumService<any>;
}

@observer
export default class FilterEnumPicker extends React.Component<IProps> {
	public render() {
		const { service } = this.props;

		return (
			<div className="filterEnumContainer">
				<div className="enumField">
					{service.enumValues().map((value) => (
						<div
							className={service.selectedEnums === value ? "selectedEnum" : "enum"}
							key={value}
							role={"button"}
							onClick={() => service.toggleEnum(value)}
						>
							{service.translatorEnum(value)}
						</div>
					))}
				</div>
			</div>
		);
	}
}

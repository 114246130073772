// MARK: React
import * as React from "react";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: Router

// MARK: Components
import TextField from "../../../../../components/TextField";
import SelectOptions from "../../../../../components/SelectOptions";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Stores
import BannerStore from "../../../../../stores/BannerStore";

interface IProps {
	bannerStore: BannerStore;
}

@inject("bannerStore")
@observer
export default class BannerContentUrl extends React.Component<IProps> {
	public render() {
		const { bannerStore } = this.props;
		return (
			<TextField
				label={strings.banners.content.url}
				name="contentUrl"
				value={bannerStore.contentUrl}
				onChange={bannerStore.handleValueChange}
				disabled={bannerStore.loading}
			/>
		);
	}
}

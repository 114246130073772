// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// Style
import "./style.scss";
import strings from "../../../../resources/strings";
import { Button, Popover } from "@material-ui/core";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import FilterPeriodService from "../../../../services/FilterService/FilterPeriodService";

interface IState {
	popoverOpen: boolean;
	date: Date | null;
	period: "start" | "end";
}

interface IProps {
	service: FilterPeriodService;
}

@observer
export default class FilterPeriodPicker extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			popoverOpen: false,
			date: null,
			period: "start",
		};
	}

	private popoverAnchor: HTMLElement | null;

	private openPopover = (event, period: "start" | "end", date: Date | null) => {
		this.setState({
			popoverOpen: true,
			period,
			date,
		});

		this.popoverAnchor = event.currentTarget;
		this.dateHandleChange(null);
	};

	private closePopover = () => {
		this.setState({ popoverOpen: false });
		this.popoverAnchor = null;
	};

	private dateHandleChange = (date: Date | null) => {
		this.setState({ date });
	};

	private confirmDate = async () => {
		const { service } = this.props;
		if (this.state.period === "start") {
			await service.onPeriodStartDateChange(this.state.date);
		} else {
			await service.onPeriodEndDateChange(this.state.date);
		}
		this.closePopover();
	};

	public render() {
		const { service } = this.props;

		return (
			<div className="periodPicker">
				<div className="periodPickerRow">
					<div className="periodPickerLabel">
						{strings.filter.components.periodPicker.from}
					</div>
					<Button
						className="periodPickerSelect"
						onClick={(e) => this.openPopover(e, "start", service.periodStartDate)}
					>

						{service.periodStartDate ?
							strings.formatter.date.date(service.periodStartDate) :
							strings.filter.components.periodPicker.addDate
						}
					</Button>
					{service.periodStartDate && (
						<div
							role="button"
							className="periodPickerClear"
							onClick={() => service.onPeriodStartDateChange(null)}
						>
							x
						</div>
					)}
				</div>
				<div className="periodPickerRow">
					<div className="periodPickerLabel">
						{strings.filter.components.periodPicker.to}
					</div>
					<Button
						className="periodPickerSelect"
						onClick={(e) => this.openPopover(e, "end", service.periodEndDate)}
					>
						{service.periodEndDate ?
							strings.formatter.date.date(service.periodEndDate) :
							strings.filter.components.periodPicker.addDate
						}
					</Button>
					{service.periodEndDate && (
						<div
							role="button"
							className="periodPickerClear"
							onClick={() => service.onPeriodEndDateChange(null)}
						>
							x
						</div>
					)}
				</div>
				<Popover
					open={this.state.popoverOpen}
					onClose={this.closePopover}
					className="popoverPicker"
					anchorEl={this.popoverAnchor}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<div className="popoverPickerContainer">
						<Calendar
							value={this.state.date}
							onChange={this.dateHandleChange}
							defaultValue={new Date()}
						/>
						<div className="popoverPickerButtons">
							<Button
								className="periodPickerConfirm"
								onClick={this.confirmDate}
							>
								{strings.common.confirm}
							</Button>
						</div>
					</div>
				</Popover>
			</div>
		);
	}
}

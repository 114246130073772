// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Components
import ComponentButton from "../../../ComponentButton";

// Style
import "./style.scss";

export interface IStatsTable {
	label: string;
	value?: string;
}

interface IProps {
	statsList: IStatsTable[];
	statsLoading?: boolean;
}

@observer
export default class StatsTable extends React.Component<IProps> {
	public render() {

		return (
			<div className="salesContent">
				<div className="salesBlock salesDetailLine">

					{this.props.statsList.map((status) => (
						<ComponentButton
							key={status.label}
							className="card"
							descriptionDate={status.label}
							descriptionLabel={status.value}
							loadingLabel={this.props.statsLoading}
						/>
					))}
				</div>
			</div>
		);
	}
}

import { observer, inject } from "mobx-react";
import * as React from "react";
import "./style.scss";

// Components
import { default as MDialog } from "@material-ui/core/Dialog";
import { default as MDialogContent } from "@material-ui/core/DialogContent";
import { default as MDialogTitle } from "@material-ui/core/DialogTitle";
import { default as MDialogActions } from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { default as InputMask } from "react-input-mask";

// Stores
import RequestsStore from "../../stores/RequestsStore";

// Resources
import strings from "../../resources/strings";
import DatePicker from "../DatePicker";

interface IProps {
	requestsStore: RequestsStore;
}

@inject("requestsStore")
@observer
export default class ChangeDateDialog extends React.Component<IProps> {

	public render() {
		const { requestsStore } = this.props;

		return (
			<MDialog
				open={requestsStore.changeDateDialogIsOpen}
			>
				<div className="changeDateDialog">
					<MDialogTitle className="itemAvaliabilityDialogTitle">{requestsStore.changeDateDialogTitle}</MDialogTitle>
					<MDialogContent className="itemAvaliabilityDialogContent" >
						<DatePicker
							label={strings.item.table.period.start}
							type="name"
							name="inputEnd"
							value={requestsStore.inputStart}
							disabled={requestsStore.loading}
							onChange={requestsStore.onInputStartChange}
						/>
						<DatePicker
							label={strings.item.table.period.end}
							type="name"
							name="inputEnd"
							value={requestsStore.inputEnd}
							disabled={requestsStore.loading}
							onChange={requestsStore.onInputEndChange}
						/>
					</MDialogContent>
					<MDialogActions>
						<Button onClick={requestsStore.closeChangeRequestDateDialog}>
							{strings.buttons.cancel}
						</Button>
						<Button
							onClick={() => requestsStore.changeRequestDate()}
							variant="contained"
						>
							{strings.buttons.save}
						</Button>
					</MDialogActions>
				</div>
			</MDialog>
		);
	}
}

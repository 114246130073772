// MARK: React
import { inject, observer } from "mobx-react";
import * as React from "react";

// MARK: Style
import "./style.scss";

// Mark: API
import { allValuesBankAccountType, translateBankAccountType } from "@startapp/loc-admin-api";

// MARK: Components
import SelectEnum from "../../components/SelectEnum";
import SelectList from "../../components/SelectList";
import InputText from "../../components/InputText";

// MARK: Service
import BankAccountService from "../../services/BankAccountService";

// MARK: Resource
import strings from "../../resources/strings";
import { getBankNamesAndCode, IBankNameAndCode } from "../../resources/banksNamesAndCodes";

interface IProps {
	bankAccountService: BankAccountService;
	loading: boolean;
}

@observer
export default class FormBankAccount extends React.Component<IProps> {
	public render() {
		const { bankAccountService, loading } = this.props;
		return (
			<>
				<div className="containerEditBank">
					<SelectList
						value={bankAccountService.selectedBankAndCode}
						label={strings.account.bank}
						items={getBankNamesAndCode()}
						handleChange={(bankCode) => bankAccountService.toggleBank(bankCode)}
						itemIdentification={(bank: IBankNameAndCode) => bank.code}
						displayableValue={(bank: IBankNameAndCode) => `${bank.name} - ${bank.code}`}
					/>
					{bankAccountService.customBankNameAndCode && (
						<>
							<InputText
								name="bankName"
								label={strings.account.bankName}
								value={bankAccountService.bankName}
								type="text"
								onChange={bankAccountService.handleValueChange}
								disabled={loading}
							/>
							<InputText
								name="bankCode"
								label={strings.account.bankCode}
								value={bankAccountService.bankCode}
								type="text"
								onChange={bankAccountService.handleValueChange}
								disabled={loading}
							/>
						</>
					)}
					<SelectEnum
						label={strings.account.title}
						enumValues={allValuesBankAccountType()}
						enumTranslator={translateBankAccountType}
						onChange={bankAccountService.toggleBankAccountType}
						selectedEnum={bankAccountService.bankAccountType}
					/>
					<div className="fieldRow">
						<InputText
							name="agency"
							label={strings.account.agency}
							value={bankAccountService.agency}
							type="text"
							onChange={bankAccountService.handleValueChange}
							disabled={loading}
						/>
						<InputText
							name="agencyDv"
							label={strings.account.agencyDv}
							value={bankAccountService.agencyDv}
							type="text"
							onChange={bankAccountService.handleValueChange}
							disabled={loading}
						/>
					</div>
					<div className="fieldRow">
						<InputText
							name="account"
							label={strings.account.account}
							value={bankAccountService.account}
							type="text"
							onChange={bankAccountService.handleValueChange}
							disabled={loading}
						/>
						<InputText
							name="accountDv"
							label={strings.account.accountDv}
							value={bankAccountService.accountDv}
							type="text"
							onChange={bankAccountService.handleValueChange}
							disabled={loading}
						/>
					</div>
					<InputText
						name="documentNumber"
						label={strings.account.documentNumber}
						value={bankAccountService.documentNumber}
						type="text"
						onChange={bankAccountService.handleValueChange}
						disabled={loading}

					/>
					<InputText
						name="legalName"
						label={strings.account.legalName}
						value={bankAccountService.legalName}
						type="text"
						onChange={bankAccountService.handleValueChange}
						disabled={loading}
					/>
				</div>
			</>
		);
	}
}

// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Stores
import RegisterStore from "../../stores/RegisterStore";

// MARK: Components
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import EnumPicker from "../EnumPicker";
import { allTranslatedValuesStateUF, allValuesStateUF, translateStateUF } from "@startapp/loc-admin-api";
import SelectEnum from "../SelectEnum";

interface IProps {
	registerStore: RegisterStore;
}

@observer
export default class SelectCity extends  React.Component<IProps> {

	public render () {
		const  { registerStore } = this.props;
		return (
			<>
				<div className="locationCity">
					<div className="searchCity">
						<SelectEnum
							label={strings.register.labels.selectUf}
							selectedEnum={registerStore.selectedState}
							onChange={registerStore.selectState}
							enumValues={allValuesStateUF()}
							enumTranslator={translateStateUF}
						/>
						<InputText
							className="textField"
							label={strings.register.labels.selectCity}
							type="text"
							name="cityName"
							value={registerStore.cityName}
							onChange={registerStore.handleValueChange}
							disabled={registerStore.loading || !!registerStore.selectedCity}
						/>
					</div>
					<div className="selectCities">
						{
							registerStore.filteredCities.map((city) => {
								return (
									<Button
										className="button"
										onClick={() => registerStore.selectCity(city)}
										key={city.id}
									>
										{city.name}
									</Button>
								);
							})
						}
					</div>
				</div>
			</>
		);
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, observable, computed } from "mobx";

// MARK: Libraries
import moment from "moment";

// MARK: Resources
import strings from "../../resources/strings";
import { currencyForBR } from "../../resources/format";
import { downloadFile } from "../../resources/FileManager";

// MARK: Services
import SortTableService from "../../services/SortTableService";
import FilterPeriodService from "../../services/FilterService/FilterPeriodService";
import FilterEnumListService from "../../services/FilterService/FilterEnumListService";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, routerStore } from "../_rootStore";

// MARK: Components
import { IBadgesFilter, IColumnDynamicItem } from "../../components/PageTable";
import { IColumnItem } from "../../components/Table/TableRow";

export default class SalesStore extends VariableChangeHandler {
	public sortTableService: SortTableService<api.SaleFieldsOrderBy>;
	public filterPeriodCreatedAtService: FilterPeriodService;
	public filterCurrentStatusService: FilterEnumListService<api.SaleStatusType>;

	// Controls
	@observable public sales: api.Sale[] = [];
	@observable public salesLoading: boolean = false;
	@observable public salesPageOffset: number = 0;
	@observable public enumsValuesLoading: boolean = false;

	@observable public selectedSale: api.Sale | null = null;

	// Variables

	@observable public statusList: api.Status[] = [];
	@observable public payment: api.Payment | null = null;
	@observable public promotion: api.SalePromotion | null = null;
	@observable public inputStart: string = "";
	@observable public inputEnd: string = "";

	// RequestFine
	@observable public reason: string = "";
	@observable public amount: number = 0;

	constructor() {
		super();

		this.selectedColumns = this.allColumns;

		this.filterPeriodCreatedAtService = new FilterPeriodService(this.onFilter);
		this.filterCurrentStatusService = new FilterEnumListService<api.SaleStatusType>(
			this.onFilter,
			api.allValuesSaleStatusType,
			api.translateSaleStatusType,
		);

		this.sortTableService = new SortTableService<api.SaleFieldsOrderBy>(
			this.onFilter,
			api.valueFromTranslationSaleFieldsOrderBy,
			api.allDisplayableValuesSaleFieldsOrderBy,
		);
	}

	@computed
	public get loading() {
		return [
			this.salesStatsLoading,
			this.salesLoading,
		].reduce((l, r) => l || r);
	}

	@computed
	public get filter(): api.SalesFilter {
		return {
			createdAtSaleDate: this.filterPeriodCreatedAtService.period,
			status: this.filterCurrentStatusService.enumsList,
			orderBy: this.sortTableService.sortOrder,
		};
	}

	@action
	public clearFilter = () => {
		this.filterPeriodCreatedAtService.clear();
	};

	@action
	public getSale = async (saleId: string) => {
		if (this.salesLoading) {
			return;
		}
		this.salesLoading = true;
		try {
			this.selectedSale = await api.getSale(saleId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.salesLoading = false;
		}
	};

	@action
	public getSales = async (salesPageOffset?: number) => {
		if (this.salesLoading) {
			return;
		}

		this.salesLoading = true;

		if (!salesPageOffset) {
			salesPageOffset = 0;
		}

		if (salesPageOffset < 0) {
			this.salesLoading = false;
			return;
		}

		try {
			this.sales = await api.getSales(salesPageOffset, this.filter);
			this.salesPageOffset = salesPageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.salesLoading = false;
		}
	};

	@action
	public nextPage = async () => {
		await this.getSales(this.salesPageOffset + 1);
	};

	@action
	public previousPage = async () => {
		await this.getSales(this.salesPageOffset - 1);
	};

	@action
	public redirectToSaleDetails = async (saleId: string) => {
		routerStore.push(`/dashboard/sales/${saleId}`);
	};

	// Export CSV
	@action
	public getSalesReport = async () => {
		if (this.salesLoading) {
			return;
		}
		this.salesLoading = true;
		try {
			const url =  await api.getSalesReportForAdmin(this.filter);
			downloadFile(url);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.salesLoading = false;
		}
	};

	// On Filter
	@action
	public onFilter = async () => {
		await this.getSalesStats();
		await this.getSales(0);
	};

	// SalesStats
	@observable public salesStats: api.TableStats | null;
	@observable public salesStatsLoading: boolean = false;

	@action
	public getSalesStats = async () => {
		if (this.salesStatsLoading) {
			return;
		}

		this.salesStatsLoading = true;

		try {
			this.salesStats = await api.getSalesStats(this.filter);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.salesStatsLoading = false;
		}
	};

	// Columns
	@observable public allColumns: string[] = [
		strings.sales.table.header.id,
		strings.sales.table.header.numId,
		strings.sales.table.header.seller,
		strings.sales.table.header.buyer,
		strings.common.originalPrice,
		strings.enum.SaleFieldsOrderBy.price,
		strings.enum.SaleFieldsOrderBy.createdAt,
		strings.sales.table.header.currentStatus,
		strings.sales.table.header.cupom,
	];
	@observable public selectedColumns: string[] = [];

	@action
	public saveSelectedColumns = (selectedColumns: string[]) => {
		this.selectedColumns = selectedColumns;
	};

	@computed
	public get headerTable() {
		return this.selectedColumns;
	}

	@action
	public cancelRequest = async (id: string) => {
		this.salesLoading = true;
		try {
			await api.cancelRequest(id);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.salesLoading = false;
		}
	};

	@computed
	public get rowsTable() {
		return this.sales.map((sale) => {
			const allColumns: IColumnDynamicItem[] = [
				{
					header: strings.sales.table.header.id,
					value: sale.id,
				},
				{
					header: strings.sales.table.header.numId,
					value: sale.simpleId,
				},
				{
					header: strings.sales.table.header.seller,
					value: `${sale.seller.name} (${sale.seller.nick})`,
				},
				{
					header: strings.sales.table.header.buyer,
					value: `${sale.buyer.name} (${sale.buyer.nick})`,
				},
				{
					header: strings.common.originalPrice,
					value: currencyForBR(sale.item.originalPrice),
				},
				{
					header: strings.enum.SaleFieldsOrderBy.price,
					value: currencyForBR(sale.price),
				},
				{
					header: strings.enum.SaleFieldsOrderBy.createdAt,
					value: `${moment(sale.createdAt).format(strings.moment.date)}`,
				},
				{
					header: strings.sales.table.header.currentStatus,
					value: api.translateSaleStatusType(sale.currentStatus.status),
				},
				{
					header: strings.sales.table.header.cupom,
					value: sale.promotion ? sale.promotion.promotionId : strings.common.noCupom,
				},
			];

			return {
				id: sale.id,
				name: sale.id,

				columns: this.selectedColumns
					.map((selectedColumn) => {
						return allColumns.find((column) => column.header === selectedColumn);
					})
					.filter((x) => !!x) as IColumnItem[],
			};
		});
	}

	@computed
	public get badgesFilter() : IBadgesFilter[] {
		let listBadges: IBadgesFilter[] = [];
			if (this.filterPeriodCreatedAtService.period) {
				if (this.filterPeriodCreatedAtService.periodStartDate && this.filterPeriodCreatedAtService.periodEndDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.startAndEnd(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedAtService.periodStartDate),
							strings.formatter.date.date(this.filterPeriodCreatedAtService.periodEndDate),
						),
						onClear: () => {
							this.filterPeriodCreatedAtService.onPeriodStartDateChange(null);
							this.filterPeriodCreatedAtService.onPeriodEndDateChange(null);
						},
					});
				} else if (this.filterPeriodCreatedAtService.periodStartDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.onlyStart(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedAtService.periodStartDate),
						),
						onClear: () => this.filterPeriodCreatedAtService.onPeriodStartDateChange(null),
					});
				} else if (this.filterPeriodCreatedAtService.periodEndDate) {
					listBadges = listBadges.concat({
						label: strings.filter.badges.onlyEnd(
							strings.filter.createdAt,
							strings.formatter.date.date(this.filterPeriodCreatedAtService.periodEndDate),
						),
						onClear: () => this.filterPeriodCreatedAtService.onPeriodEndDateChange(null),
					});
				}
			}
			listBadges = listBadges.concat(
				this.filterCurrentStatusService.enumsList.map((status) => ({
					label: strings.filter.badges.currentStatus(api.translateSaleStatusType(status)),
					onClear: () => this.filterCurrentStatusService.toggleEnum(status),
				})),
			);

		return listBadges;
	}

}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../../../resources/strings";
import { match as Match } from "react-router";

// MARK: Stores
import RequestsStore from "../../../../stores/RequestsStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Components
import UserCard from "../../../../components/UserCard";
import RequestCard from "../../../../components/RequestCard";
import AuthStore from "../../../../stores/AuthStore";
// MARK: API
import * as api from "@startapp/loc-admin-api";

interface IProps extends IStores {}

interface IStores {
	requestsStore: RequestsStore;
	authStore: AuthStore;
	match: Match<{ requestId: string }>;
}

@inject("requestsStore", "authStore")
@observer
export default class RequestDetails extends React.Component<IProps> {
	public static defaultProps: IStores;

	public componentDidMount = async () => {
		const requestId = this.props.match.params.requestId;
		await this.props.requestsStore.getRequest(requestId);
	};

	public render() {
		const text = strings.requests.details;
		const request = this.props.requestsStore.selectedRequest;

		return (
			<>
				{
					request && (
						<DashboardLayout backButton={true} loading={this.props.requestsStore.loading}>
							<div className="requestDetailsContainer">
								<div className="requestDetailsCard">
									<h2 className="cardTitle">{text.title} </h2>
									<RequestCard
										isAdmin={this.props.authStore.showIfUserIsAdmin()}
										onCancelRequest={this.props.requestsStore.cancelRequest}
										request={request}
									/>
									{
										request.requester ? (
											<UserCard
												user={request.requester}
												title={strings.requests.details.labels.requester}
											/>
										) : null
									}
									{
										request.owner ? (
											<UserCard
												user={request.owner}
												title={strings.common.owner}
											/>
										) : null}
								</div>
							</div>
						</DashboardLayout>
					)}
			</>
		);
	}
}

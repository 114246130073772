// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, computed, observable } from "mobx";

// MARK: Stores
import VariableChangeHandler from "../../stores/_helpers/VariableChangeHandler";
import ImageService from "../ImageService";

interface ISection extends api.BannerSection {
	inputItemId: string;
}
export default class BannerGroupService extends VariableChangeHandler {

	// Users
	@observable public userId: string = "";
	@observable public userIds: string[] = [];

	// Sections
	@observable public sections: ISection[] = [];
	@observable public sectionTitle: string = "";

	// Items
	@observable public itemId: string = "";
	@observable public itemIds: string[] = [];

	// InnerBanner
	public imageService: ImageService = new ImageService();
	@observable public url: string = "";

	@computed
	public get group(): api.BannerGroup {
		return {
			userIds: this.userIds,
			sections: this.sections.map((section) => ({
				title: section.title,
				itemIds: section.itemIds,
			})),
			innerBanner: null,
		};
	}

	@computed
	public get innerBanner(): api.UncertainInnerBanner | null {
		return (this.imageService.singleImageUploader && this.imageService.singleImageUploader.uploadedUncertainImage) ?
			{
				innerImage: this.imageService.singleImageUploader.uploadedUncertainImage,
				innerUrl: this.url,
			} : null;
	}

	@action
	public clear = () => {
	};

	@action
	public setFields = (banner: api.Banner) => {
		if (banner.content.group) {
			this.userIds = banner.content.group.userIds;
			this.sections = banner.content.group.sections.map((section) => ({
				title: section.title,
				itemIds: section.itemIds,
				inputItemId: "",
			}));
			if (banner.content.group.innerBanner) {
				this.url = banner.content.group.innerBanner.url;
				this.imageService.setSingleImage(banner.content.group.innerBanner.image);
			}
		}
	};

	@action
	public addUserId = () => {
		if (this.userIds.indexOf(this.userId) === -1 && this.userId.length > 0) {
			this.userIds.push(this.userId);
			this.userId = "";
		}
	};

	@action
	public removeUserId = (userId: string) => {
		const index = this.userIds.indexOf(userId);
		if (index >= 0) {
			this.userIds.splice(index, 1);
		}
	};

	@action
	public addSection = () => {
		if (!this.sections.find((section) => section.title === this.sectionTitle)) {
			this.sections.push({
				title: this.sectionTitle,
				itemIds: [],
				inputItemId: "",
			});
			this.sectionTitle = "";
		}
	};

	@action
	public removeSection = (sectionTitle: string) => {
		const index = this.sections.findIndex((section) => section.title === sectionTitle);

		if (index > -1) {
			this.sections.splice(index, 1);
		}
	};

	@action
	public handleItemId = (itemId: string, sectionTitle: string) => {
		const index = this.sections.findIndex((section) => section.title === sectionTitle);

		if (index > -1) {
			this.sections[index].inputItemId = itemId;
		}
	};

	@action
	public addItemId = (sectionTitle: string) => {

		const section = this.sections.find((sectionFind) => sectionFind.title === sectionTitle);

		if (section && section.inputItemId.length > 0) {

			if (!section.itemIds.find((id) => id === section.inputItemId)) {

				section.itemIds.push(section.inputItemId);
				section.inputItemId = "";
			}

			const index = this.sections.findIndex((sectionFind) => section.title === sectionFind.title);

			if (index > -1) {
				this.sections.splice(index, 1, section);
			}
		}
	};

	@action
	public removeItemId = (sectionTitle: string, itemId: string) => {
		const index = this.sections.findIndex((section) => section.title === sectionTitle);

		if (index > -1) {
			const section = this.sections[index];
			section.itemIds = section.itemIds.filter((id) => id !== itemId);

			this.sections.splice(index, 1, section);
		}
	};
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../../../resources/strings";
import { match as Match } from "react-router";

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Stores
import SalesStore from "../../../../stores/SalesStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Components
import UserCard from "../../../../components/UserCard";
import SaleCard from "../../../../components/SaleCard";
import AuthStore from "../../../../stores/AuthStore";

interface IProps extends IStores {}

interface IStores {
	salesStore: SalesStore;
	authStore: AuthStore;
	match: Match<{ saleId: string }>;
}

@inject("salesStore", "authStore")
@observer
export default class SaleDetails extends React.Component<IProps> {
	public static defaultProps: IStores;

	public componentDidMount = async () => {
		const saleId = this.props.match.params.saleId;
		if (saleId) {
			await this.props.salesStore.getSale(saleId);
		}
	};

	public render() {
		const text = strings.sales.details;
		const sale = this.props.salesStore.selectedSale;

		return (
			<>
				{
					sale && (
					<DashboardLayout backButton={true} loading={this.props.salesStore.loading}>
						<div className="saleDetailsContainer">
							<div className="saleDetailsCard">
								<h2 className="cardTitle">{text.titleSale} </h2>
								<SaleCard
									isAdmin={this.props.authStore.showIfUserIsAdmin()}
									onCancelRequest={() => this.props.salesStore.cancelRequest(sale.id)}
									sale={sale}
								/>
								{
									sale.buyer ? <UserCard user={sale.buyer} title={strings.sales.details.labels.buyer}/> :
										null
								}
								{
									sale.seller ? <UserCard user={sale.seller} title={strings.sales.details.labels.seller}/> :
										null
								}
							</div>
						</div>
					</DashboardLayout>
					)}
			</>
		);
	}
}

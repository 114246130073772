// MARK: Api
import { valueFromTranslationSaleFieldsOrderBy } from "@startapp/loc-admin-api";
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { observable, action, computed } from "mobx";

// MARK: Resources

export default class SortTableService<TableFieldOrderBy> {
	public onSuccessSortClick: () => Promise<void>;
	public valueFromTranslated: (value: string) => TableFieldOrderBy;
	public allDisplayableValues: () => string[];

	@observable public selectedFieldOrderBy: TableFieldOrderBy | null;
	@observable public currentSortOrder: api.TableOrderBy | null;

	constructor(
		onSuccessSortClick: () => Promise<void>,
		valueFromTranslated: (value: string) => TableFieldOrderBy,
		displayableValues: () => string[],
	) {
		this.onSuccessSortClick = onSuccessSortClick;
		this.valueFromTranslated = valueFromTranslated;
		this.allDisplayableValues = displayableValues;
	}

	@action
	public onSortClick = async (sortHeader: string) => {
		if (this.allDisplayableValues().includes(sortHeader)) {
			const sort = this.valueFromTranslated(sortHeader);
			if (this.selectedFieldOrderBy !== sort) {
				this.selectedFieldOrderBy = sort;
				this.currentSortOrder = api.TableOrderBy.desc;
			} else {
				this.selectedFieldOrderBy = sort;
				this.currentSortOrder = this.currentSortOrder === api.TableOrderBy.asc ?
					api.TableOrderBy.desc :
					api.TableOrderBy.asc
				;
			}
			this.onSuccessSortClick();
		}
	};

	@action
	public clear = () => {
		this.selectedFieldOrderBy = null;
		this.currentSortOrder = null;
	};

	@computed
	public get sortOrder() {
		if (this.selectedFieldOrderBy && this.currentSortOrder) {
			return {
				field: this.selectedFieldOrderBy,
				order: this.currentSortOrder,
			};
		}

		return null;
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";
import strings from "../../resources/strings";

// MARK: Components
import { CircularProgress as MCircularProgress } from "@material-ui/core";

// MARK: Component
import CircularProgress from "../../components/CircularProgress";

interface IProps {
	className?: string;
	disabled?: boolean;
	onClick?: () => void;
	descriptionDate: string;
	arrowUP?: boolean;

	loadingLabel?: boolean;
	descriptionLabel?: string | number;

	loadingPercentage?: boolean;
	percentage?: string;
	footerData?: string;
}
@observer
export default class ComponentButton extends React.Component<IProps> {
	public render() {
		const { percentage } = this.props;

		return (
			<>
				<div
					className={`componentButton ${this.props.className ? this.props.className : ""}`}
					onClick={!this.props.disabled ? this.props.onClick : undefined}
					role="button"
				>
					<div className="descriptionDate">
						{this.props.descriptionDate}
					</div>
					<div className="descriptionLabel">
						{this.props.loadingLabel ?
							<MCircularProgress size={20} color={"primary"} /> :
							this.props.descriptionLabel
						}
					</div>
					<div className="componentButtonFooter">
						<div className="componentButtonFooterData">
							{!this.props.loadingLabel ?
								this.props.footerData :
								null
							}
						</div>
						<div className="percentage">
							{this.props.loadingPercentage ?
								(
									<div className="loading">
										<MCircularProgress size={13} color={"secondary"} />
									</div>
								) :
								(percentage && (
									<>
										{this.props.arrowUP ? (
											<img className="arrowIcon" src="/arrowUp.svg" alt="icon" />
										) : (
												<img className="arrowIcon" src="/arrowDown.svg" alt="icon" />
											)}
										{percentage}
									</>
								))}
						</div>
					</div>
				</div>

			</>
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import { match as Match } from "react-router";
import strings from "../../../../resources/strings";

// MARK: Components
import ItemDetail from "../../../../components/ItemDetail";
import ItemsStore from "../../../../stores/ItemsStore";
import UserCard from "../../../../components/UserCard";
import Table from "../../../../components/Table";

// MARK: Stores
import UsersStore from "../../../../stores/UsersStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

interface IProps extends IStores {
	match: Match<{userId: string}>;
}

interface IStores {
	usersStore: UsersStore;
	itemsStore: ItemsStore;
}

@inject("usersStore", "itemsStore")
@observer
export default class UserDetails extends React.Component<IProps> {
	public static defaultProps: IStores;

	public componentDidMount = async () => {
		const userId = this.props.match.params.userId;
		await this.props.usersStore.getUser(userId);
		await this.props.itemsStore.getItems(0, userId);
	};

	public render() {
		const { usersStore, itemsStore } = this.props;
		return (
			<>
				{
					usersStore.selectedUser && (
						<DashboardLayout backButton={true}>
							<div className="userDetailsContainer">
								<div className="userDetailsWrapper">
									<UserCard title={strings.users.table.title} user={usersStore.selectedUser}/>
									<div className="userDetailsTitle"><h2>{strings.users.table.header.address}</h2></div>
									{usersStore.selectedUser.address && (
										<div className="userRow">
											<ItemDetail
												title={strings.address.street}
												info={usersStore.selectedUser.address.street}
												className="userTreeColumns"
											/>
											<ItemDetail
												title={strings.address.number}
												info={usersStore.selectedUser.address.number}
												className="userTreeColumns"
											/>
											<ItemDetail
												title={strings.address.neighborhood}
												info={usersStore.selectedUser.address.neighborhood}
												className="userTreeColumns"
											/>
											<ItemDetail
												title={strings.address.city}
												info={usersStore.selectedUser.address.city}
												className="userTreeColumns"
											/>
											<ItemDetail
												title={strings.address.zip}
												info={usersStore.selectedUser.address.zip}
												className="userTreeColumns"
											/>
											<ItemDetail
												title={strings.address.additionalInfo}
												info={usersStore.selectedUser.address.adicionalInfo}
												className="userTreeColumns"
											/>
											<ItemDetail
												title={strings.address.uf}
												info={usersStore.selectedUser.address.uf}
												className="userTreeColumns"
											/>
											<ItemDetail
												title={strings.address.country}
												info={usersStore.selectedUser.address.country}
												className="userTreeColumns"
											/>
											{usersStore.selectedUser.address.reference !== "" && (
												<ItemDetail
													title={strings.address.reference}
													info={usersStore.selectedUser.address.reference}
													className="userTreeColumns"
												/>
											)}
										</div>
									)}
									<div className="userItemsTable">
										<Table
											header={itemsStore.headerTable}
											title={strings.item.title}
											tableIsEmptyMessage={strings.common.noMoreResults}
											loading={itemsStore.itemsLoading}
											nextPage={() => itemsStore.nextPage(this.props.match.params.userId)}
											prevPage={() => itemsStore.previousPage(this.props.match.params.userId)}
											rows={itemsStore.rowsTable}
											pageOffset={itemsStore.itemPageOffset}
											onEditClick={(itemId) => itemsStore.startEventCreateOrEdit(itemId)}
											onDetailsClick={(itemId) => itemsStore.redirectToItemDetails(itemId)}
											onDeleteClick={(itemId, itemName) => itemsStore.openDeleteItemDialog(itemId, itemName)}
										/>
									</div>
								</div>
							</div>
						</DashboardLayout>
					)
				}
			</>
		);
	}
}

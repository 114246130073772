// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { observable, action, computed, reaction } from "mobx";
import VariableChangeHandler from "../_helpers/VariableChangeHandler";

// MARK: Stores
import { uiStore, routerStore } from "../_rootStore";

// MARK: Resources
import strings from "../../resources/strings";

const dashboardRoute = "/dashboard";
export default class AuthStore extends VariableChangeHandler {
	// Control
	@observable public adminUser: api.AdminUser | null = null;

	// Login
	@observable public loading: boolean = false;
	@observable public email: string = "";
	@observable public password: string = "";

	// Recovery
	@observable public newPassword: string = "";
	@observable public confirmNewPassword: string = "";

	@observable public receivedToken: string = "";

	constructor() {
		super();

		const adminUser = localStorage.getItem("adminUser");
		this.adminUser = adminUser ? JSON.parse(adminUser) : null;

		reaction(
			() => this.loading,
			(loading) => {
				uiStore.setCircularLoading(loading);
			},
		);
	}

	@computed
	public get isLoginFormReady() {
		return this.email.length > 5 && this.password.length >= 6;
	}

	@action
	public setToken = async (token: string) => {
		this.receivedToken = token;
	}
	@action
	public login = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			this.adminUser = await api.login(
				this.email.trim(),
				this.password.trim(),
			);

			localStorage.setItem("adminUser", JSON.stringify(this.adminUser));
			routerStore.push(dashboardRoute);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	public setAdminUser = (adminUser: api.AdminUser) => {
		localStorage.setItem("adminUser", JSON.stringify(adminUser));
		this.adminUser = adminUser;
	};

	@action
	public logout = async () => {
		const onConfirm = async () => {
			try {
				await api.logout();

				this.adminUser = null;
				localStorage.clear();

				routerStore.push("/login");
			} catch (e) {
				uiStore.showErrorSnackbar(e);
			}
		};

		uiStore.showDialog(
			strings.dialogs.logout,
			onConfirm,
		);
	};

	@action
	public isLogged = async () => {
		this.loading = true;
		let adminUser: api.AdminUser | null = null;

		try {
			adminUser = await api.getCurrentAdminUser();
		} catch (e) {
			// tslint:disable-next-line: no-console
			console.error(e);
			localStorage.clear();
		}

		this.loading = false;
		this.adminUser = adminUser;

		return !!this.adminUser;
	};

	@action
	public authenticate = async () => {
		if (!(await this.isLogged())) {
			routerStore.replace("/login");
		}
	};

	@action
	public openPageIfIsAdmin = () => {
		if (!this.showIfUserIsAdmin()) {
			routerStore.replace(dashboardRoute);
		}
	};

	@action
	public openDashboardIfAuthenticated = async () => {
		if (await this.isLogged()) {
			routerStore.replace(dashboardRoute);
		}
	};

	@action
	public showIfUserIsAdmin = () => {
		if (this.adminUser) {
			return this.adminUser.type === "admin";
		}
		return false;
	};

	@action
	public showIFUserIsLicensed = () => {
		if (process.env.NODE_ENV !== "production") {
			return true;
		}

		if (this.adminUser) {
			return this.adminUser.type === "licensed";
		}
		return false;
	};
	@action
	public loginOrOpenDashboard = async () => {
		if (await this.isLogged()) {
			routerStore.replace(dashboardRoute);
		} else {
			routerStore.replace("/login");
		}
	};

	@action
	public resetPassword = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			await api.sendResetPasswordEmailAdminUser(this.email);
			uiStore.showAlert({
				title: strings.recoverPassword.confimationDialog.title,
				message: strings.recoverPassword.confimationDialog.message,
			}, () => {
				routerStore.push("/login");
			});
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	@action
	public sendNewPassword = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			await api.resetPasswordAdminUser(this.receivedToken, this.newPassword);
			routerStore.push("/login");
			uiStore.showSnackbar(strings.recoverPassword.recoverySuccess);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";

// 	MARK: Libraries
import moment from "moment";

// MARK: Mobx
import { action, observable } from "mobx";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, routerStore } from "../_rootStore";

export default class ReportStore extends VariableChangeHandler {
	// Sub Store

	// Controls
	@observable public reportLoading: boolean = false;
	@observable public reportPerItemFieldsLoading: boolean = false;
	@observable public enumValuesLoading: boolean = false;

	// Variables
	@observable public report: api.Report | null = null;
	@observable public reportType: api.ReportType = api.ReportType.all;
	@observable public reportPerItemFields: api.ReportPerItemField[] = [];

	@observable public reportTypes: api.ReportType[] = [];

	@action
	public getReport = async () => {
		if (this.reportLoading) {
			return;
		}

		this.reportLoading = true;

		try {
			const newReport = await api.getReport(this.startDate, this.endDate);
			this.report = newReport;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.reportLoading = false;
		}
	}

	@action
	public sendReportEmail = async () => {
		if (this.reportLoading) {
			return;
		}

		this.reportLoading = true;

		try {
			await api.sendReportEmail(this.reportType);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.reportLoading = false;
		}
	}

	@action
	public getReportEnumValues = async () => {
		if (this.enumValuesLoading) {
			return;
		}
		this.enumValuesLoading = true;
		try {
			this.reportTypes = api.allValuesReportType();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		}	finally {
			this.enumValuesLoading = false;
		}
	};

	@action
	public toggleReportType = (reportType: api.ReportType) => {
		this.reportType = reportType;
	};

	@action
	public translateField = (field: api.ReportPerItemField) => {
		// TODO: Verify to do tslinty accept cast if not unecessary

		const fieldTranslated = (fieldValue: string, enumValues: string[], enumTranslator: (enumValue: string) => string ): string => {
			const findValue = enumValues.find((enumValue) => enumValue === fieldValue);
			if (findValue) {
				return enumTranslator(findValue);
			}
			return fieldValue;
		};

		switch (field.fieldname) {
			case ("department") : {
				return fieldTranslated(field.value, api.allValuesDepartment(), api.translateDepartment);
			}
			case ("category") : {
				return fieldTranslated(field.value, api.allValuesCategory(), api.translateCategory);
			}
			case ("color") : {
				return fieldTranslated(field.value, api.allValuesCategory(), api.translateCategory);
			}
			case ("ocasions") : {
				return fieldTranslated(field.value, api.allValuesOcasion(), api.translateOcasion);
			}
		}

		return field.value;
	}

	// Reports
	@observable public startDate: Date | null = moment().subtract(7, "days").startOf("day").toDate();
	@observable public endDate: Date | null = moment().startOf("day").toDate();
	@observable public reportGroupType: api.ReportGroupType = api.ReportGroupType.date;

	// Users
	@observable public usersGroupReportLoading = false;
	@observable public usersGroupReportNumberLabel: api.NumberLabelReport[] = [];

	// Items
	@observable public itemsGroupReportLoading = false;
	@observable public itemsGroupReportNumberLabel: api.NumberLabelReport[] = [];

	// Rents
	@observable public rentsGroupReportLoading = false;
	@observable public rentsGroupReportNumberLabel: api.NumberLabelReport[] = [];

	// Sales
	@observable public salesGroupReportLoading = false;
	@observable public salesGroupReportNumberLabel: api.NumberLabelReport[] = [];

	@action
	public getUsersGroupReport = async () => {
		this.usersGroupReportLoading = true;

		this.usersGroupReportNumberLabel = [];

		const startDate = this.startDate;
		const endDate = this.endDate;
		const reportGroupType = this.reportGroupType;

		try {
			switch (this.reportGroupType) {
				case api.ReportGroupType.date: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.usersGroupReportNumberLabel = (await api.getUsersPerDate(this.startDate, this.endDate))
							.map((usersNumberDate) => ({
								label: moment(usersNumberDate.date).format(strings.moment.date),
								number: usersNumberDate.number,
							}));
					}

					break;
				}
				case api.ReportGroupType.dateHour: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.usersGroupReportNumberLabel = (await api.getUsersPerDateHour(this.startDate, this.endDate))
							.map((usersNumberDate) => ({
								label: moment(usersNumberDate.dateHour).format(strings.moment.dateHour),
								number: usersNumberDate.number,
							}));
					}

					break;
				}
				case api.ReportGroupType.hour: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.usersGroupReportNumberLabel = (await api.getUsersPerHour(this.startDate, this.endDate))
							.map((usersNumberDate) => ({
								label: strings.common.hour(usersNumberDate.hour),
								number: usersNumberDate.number,
							}));
					}

					break;
				}
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersGroupReportLoading = false;
		}
	}

	@action
	public getItemsGroupReport = async () => {
		this.itemsGroupReportLoading = true;

		this.itemsGroupReportNumberLabel = [];

		const startDate = this.startDate;
		const endDate = this.endDate;
		const reportGroupType = this.reportGroupType;

		try {
			switch (this.reportGroupType) {
				case api.ReportGroupType.date: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.itemsGroupReportNumberLabel = (await api.getItemsPerDate(this.startDate, this.endDate))
							.map((itemsNumberDate) => ({
								label: moment(itemsNumberDate.date).format(strings.moment.date),
								number: itemsNumberDate.number,
							}));
					}

					break;
				}
				case api.ReportGroupType.dateHour: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.itemsGroupReportNumberLabel = (await api.getItemsPerDateHour(this.startDate, this.endDate))
							.map((itemsNumberDate) => ({
								label: moment(itemsNumberDate.dateHour).format(strings.moment.dateHour),
								number: itemsNumberDate.number,
							}));
					}

					break;
				}
				case api.ReportGroupType.hour: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.itemsGroupReportNumberLabel = (await api.getItemsPerHour(this.startDate, this.endDate))
							.map((itemsNumberDate) => ({
								label: strings.common.hour(itemsNumberDate.hour),
								number: itemsNumberDate.number,
							}));
					}

					break;
				}
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersGroupReportLoading = false;
		}
	}

	@action
	public getRentsGroupReport = async () => {
		this.rentsGroupReportLoading = true;

		this.rentsGroupReportNumberLabel = [];

		const startDate = this.startDate;
		const endDate = this.endDate;
		const reportGroupType = this.reportGroupType;

		try {
			switch (this.reportGroupType) {
				case api.ReportGroupType.date: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.rentsGroupReportNumberLabel = (await api.getRentsPerDate(this.startDate, this.endDate))
							.map((rentsNumberDate) => ({
								label: moment(rentsNumberDate.date).format(strings.moment.date),
								number: rentsNumberDate.number,
							}));
					}

					break;
				}
				case api.ReportGroupType.dateHour: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.rentsGroupReportNumberLabel = (await api.getRentsPerDateHour(this.startDate, this.endDate))
							.map((rentsNumberDate) => ({
								label: moment(rentsNumberDate.dateHour).format(strings.moment.dateHour),
								number: rentsNumberDate.number,
							}));
					}

					break;
				}
				case api.ReportGroupType.hour: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.rentsGroupReportNumberLabel = (await api.getRentsPerHour(this.startDate, this.endDate))
							.map((rentsNumberDate) => ({
								label: strings.common.hour(rentsNumberDate.hour),
								number: rentsNumberDate.number,
							}));
					}

					break;
				}
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersGroupReportLoading = false;
		}
	}

	@action
	public getSalesGroupReport = async () => {
		this.salesGroupReportLoading = true;

		this.salesGroupReportNumberLabel = [];

		const startDate = this.startDate;
		const endDate = this.endDate;
		const reportGroupType = this.reportGroupType;

		try {
			switch (this.reportGroupType) {
				case api.ReportGroupType.date: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.salesGroupReportNumberLabel = (await api.getSalesPerDate(this.startDate, this.endDate))
							.map((salesNumberDate) => ({
								label: moment(salesNumberDate.date).format(strings.moment.date),
								number: salesNumberDate.number,
							}));
					}

					break;
				}
				case api.ReportGroupType.dateHour: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.salesGroupReportNumberLabel = (await api.getSalesPerDateHour(this.startDate, this.endDate))
							.map((salesNumberDate) => ({
								label: moment(salesNumberDate.dateHour).format(strings.moment.dateHour),
								number: salesNumberDate.number,
							}));
					}

					break;
				}
				case api.ReportGroupType.hour: {
					if (startDate === this.startDate && endDate === this.endDate && reportGroupType === this.reportGroupType) {
						this.salesGroupReportNumberLabel = (await api.getSalesPerHour(this.startDate, this.endDate))
							.map((salesNumberDate) => ({
								label: strings.common.hour(salesNumberDate.hour),
								number: salesNumberDate.number,
							}));
					}

					break;
				}
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.usersGroupReportLoading = false;
		}
	}

	@action
	public callFunctions = async () => {
		this.getReport();
		this.getUsersGroupReport();
		this.getItemsGroupReport();
		this.getRentsGroupReport();
		this.getSalesGroupReport();
	};

	@action
	public onEndDateChange = async (date: Date) => {
		this.endDate = date;
		this.callFunctions();
	};

	@action
	public onReportGroupType = async (groupType: api.ReportGroupType.date) => {
		this.reportGroupType = groupType;
		this.callFunctions();

	};

	@action
	public onStartDateChange = async (date: Date) => {
		this.startDate = date;
		this.callFunctions();
	};
}

// MARK: Components
import PageTable from "../../../../components/PageTable";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Resources
import strings from "../../../../resources/strings";
import { currencyForBR } from "../../../../resources/format";

// MARK: Stores
import AuthStore from "../../../../stores/AuthStore";
import SalesStore from "../../../../stores/SalesStore";

// Style
import "./style.scss";

interface IProps extends IStores { }

interface IStores {
	authStore: AuthStore;
	salesStore: SalesStore;
}

@inject("authStore", "salesStore")
@observer
export default class SalesTable extends React.Component<IProps> {
	public static defaultProps: IStores;

	constructor(props: IProps) {
		super(props);

		this.state = {
			filterOpen: true,
		};
	}

	public async componentDidMount() {
		this.props.salesStore.getSales();
		this.props.salesStore.getSalesStats();
	}

	public render() {
		const { salesStore } = this.props;

		return (
			<DashboardLayout>
				<PageTable
					filter={{
						filters: [
							{
								type: "period",
								title: strings.filter.period.sale,
								filterPeriodService: salesStore.filterPeriodCreatedAtService,
							},
							{
								type: "enumList",
								title: strings.filter.currentStatus,
								filterEnumListService: salesStore.filterCurrentStatusService,
							},
						],
						badgesFilter: salesStore.badgesFilter,
						clearFilter: salesStore.clearFilter,
						onFilter: salesStore.onFilter,
					}}
					statsList={[
						{
							label: strings.sales.salesStats.totalAmount,
							value: salesStore.salesStats ? salesStore.salesStats.totalAmount.toString() : "",
						},
						{
							label: strings.sales.salesStats.totalPrice,
							value: currencyForBR(salesStore.salesStats ? salesStore.salesStats.totalPrice : 0),
						},
						{
							label: strings.sales.salesStats.averageTicked,
							value: currencyForBR(salesStore.salesStats ? salesStore.salesStats .averageTicked : 0),
						},
					]}
					statsLoading={salesStore.salesStatsLoading}
					onExportTable={salesStore.getSalesReport}
					tableConfig={{
						sortService: salesStore.sortTableService,
						onDetailClick: salesStore.redirectToSaleDetails,
						rows: salesStore.rowsTable,
						header: salesStore.headerTable,
						title: strings.sales.title,
						loading: salesStore.salesLoading,
						nextPage: salesStore.nextPage,
						prevPage: salesStore.previousPage,
						pageOffset: salesStore.salesPageOffset,
					}}
					editColumnsTable={{
						selectedColumns: salesStore.selectedColumns,
						saveSelectedColumns: salesStore.saveSelectedColumns,
						allColumns: salesStore.allColumns,
					}}
				/>
			</DashboardLayout>
		);
	}
}

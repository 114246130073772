
// MARK: Mobx
import { action, observable } from "mobx";

export default class FilterEnumListService<EnumType> {
	private readonly onFilter: () => Promise<void>;

	public enumValues: () => string[];
	public translatorEnum: (enumValue: EnumType) => string;

	@observable public enumsList: EnumType[] = [];

	constructor(
		onFilter: () => Promise<void>,
		enumValues: () => string[],
		translatorEnum: (enumValue: EnumType) => string,
	) {
		this.onFilter = onFilter;
		this.enumValues = enumValues;
		this.translatorEnum = translatorEnum;
	}

	@action
	public clear = () => {
		this.enumsList = [];
	};

	@action
	public toggleEnum = async (selectedEnum: EnumType) => {
		const index = this.enumsList.indexOf(selectedEnum);
		if (index > -1) {
			this.enumsList.splice(index, 1);
		} else {
			this.enumsList.splice(0, 0, selectedEnum);
		}

		await this.onFilter();
	}
}

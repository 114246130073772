// MARK: React
import * as React from "react";
import { match } from "react-router";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import AuthStore from "../../stores/AuthStore";
import { routerStore } from "../../stores/_rootStore";

// MARK: Layout
import LoginLayout from "../../layouts/LoginLayout";

// MARK: Components
import Typography from "@material-ui/core/Typography";
import CircularProgress from "../../components/CircularProgress";
import TextField from "../../components/TextField";
import Button from "../../components/Button";

// Resources
import strings from "../../resources/strings";

interface IProps {
	match: match<{ token?: string }>;
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Recovery extends React.Component<IProps> {
	public componentWillMount = () => {
		const token = this.props.match.params.token;
		if (token) {
			this.props.authStore.setToken(token);
			console.log(this.props.authStore.receivedToken);
		}
	};

	public render() {
		const { authStore } = this.props;

		return (
			<LoginLayout>
				{
					authStore.loading ?
						<CircularProgress /> : (
							<div className="emailRecovery">
								<div className="emailRecoveryCard">
									<Typography className="loginTitle" variant="h5">
										{strings.projectName}
									</Typography>
									{
										(authStore.receivedToken.length > 0) ? (
											<>
												<TextField
													label={strings.recoverPassword.newPassword}
													type="password"
													name="newPassword"
													value={authStore.newPassword}
													onChange={authStore.handleValueChange}
													disabled={authStore.loading}
												/>
												<TextField
													label={strings.recoverPassword.confirmNewPassword}
													type="password"
													name="confirmNewPassword"
													value={authStore.confirmNewPassword}
													onChange={authStore.handleValueChange}
													disabled={authStore.loading}
												/>
												<Button
													className="emailRecoveryButton"
													onClick={() => authStore.sendNewPassword()}
												>
													{strings.recoverPassword.newPasswordButton}
												</Button>
											</>
										) : (
												<>
													<TextField
														label={strings.recoverPassword.email}
														type="email"
														name="email"
														value={authStore.email}
														onChange={authStore.handleValueChange}
														disabled={authStore.loading}
													/>
													<Button
														className="emailRecoveryButton"
														onClick={() => authStore.resetPassword()}
													>
														{strings.recoverPassword.recoverPasswordButton}
													</Button>
												</>
											)}
									<Button
										onClick={() => routerStore.push("/login")}
									>
										{strings.recoverPassword.backButton}
									</Button>
								</div>
							</div>
						)}
			</LoginLayout>
		);
	}
}

// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Pages
import SalesTable from "./SalesTable";
import SaleDetails from "./SaleDetails";

interface IProps extends IStores {}
interface IStores {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Sales extends React.Component<IProps> {
	public static defaultProps: IStores;

	public render() {
		return (
			<Switch>
				<Route exact={true} path="/dashboard/sales/" component={SalesTable} />
				<Route path="/dashboard/sales/:saleId" component={SaleDetails} />
			</Switch>
		);
	}
}

const colors = {
	primary: "rgba(252, 99, 113, 1)",
	primary50: "rgba(252, 99, 113, .5)",
	lightGray: "rgba(193, 193, 193, 1)",
	veryLightGray: "rgba(232, 232, 232, 1)",
	gray: "rgba(129, 129, 129, 1)",
	darkGray: "rgba(60, 60, 60, 1)",
	white: "rgba(255, 255, 255, 1)",
	black: "rgba(0, 0, 0, 1)",
	black50: "rgba(0, 0, 0, 0.5)",
	black35: "rgba(0, 0, 0, 0.35)",
	black20: "rgba(0, 0, 0, 0.2)",
	clear: "rgba(0, 0, 0, 0)",
	error: "rgba(237, 67, 55, 1)",
};

export default colors;

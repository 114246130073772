// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../resources/strings";
import {currencyForBR} from "../../resources/format";

// MARK: Stores
import FinesStore from "../../stores/FinesStore";

// MARK: Components
import Dialog from "..//Dialog";
import CircularProgress from "../CircularProgress";
import ItemDetail from "..//ItemDetail";

// 	MARK: Libraries
import moment from "moment";

interface IProps extends IStores { }

interface IStores {
	finesStore: FinesStore;
	clearDialog: () => void;
	isOpen: boolean;
	dialogTitle: string;
	noActions: boolean;
}

@inject("finesStore")
@observer
export default class FineDetails extends React.Component<IProps> {
	public static defaultProps: IStores;

	public render() {
		const { finesStore, clearDialog, isOpen, dialogTitle, noActions } = this.props;
		return (
			<>
			{finesStore.finesLoading ?
				<CircularProgress/> :
				(
					<Dialog
						onDialogConfirm={null}
						clearDialog={clearDialog}
						isOpen={isOpen}
						dialogTitle={dialogTitle}
						noActions={noActions}
					>
						{
							finesStore.selectedFine && (
								<div className="cardInfo">
									<ItemDetail
										title={strings.fines.details.labels.id}
										info={finesStore.selectedFine.id}
									/>
									<ItemDetail
										title={strings.fines.details.labels.transactionId}
										info={finesStore.selectedFine.transactionId}
									/>
									<ItemDetail
										title={strings.fines.details.labels.payedBy}
										info={finesStore.selectedFine.payedBy ? finesStore.selectedFine.payedBy.name : "LOC"}
									/>
									<ItemDetail
										title={strings.fines.details.labels.payedTo}
										info={finesStore.selectedFine.payedTo ? finesStore.selectedFine.payedTo.name : "LOC"}
									/>
									<ItemDetail
										title={strings.fines.details.labels.createdDate}
										info={moment(finesStore.selectedFine.createdAt).format(strings.moment.date)}
									/>
									<ItemDetail
										title={strings.fines.details.labels.reason}
										info={finesStore.selectedFine.reason}
									/>
									<ItemDetail
										title={strings.fines.details.labels.amount}
										info={currencyForBR(finesStore.selectedFine.amount)}
									/>
								</div>
							)
						}
					</Dialog>
				)
			}
			</>
		);
	}
}

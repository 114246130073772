// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, computed, observable } from "mobx";

// MARK: Libraries
import ReactQuill from "react-quill";

// MARK: Resources
import strings from "../../resources/strings";
import { openInputImageDialog } from "../../resources/dialogs";
import { craftImageBuffer } from "../../resources/FileManager";

// MARK: Resources
import ImageService from "../../services/ImageService";
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { ColumnType, IRowItem } from "../../components/Table/TableRow";

// MARK: Stores
import { routerStore, uiStore } from "../_rootStore";

interface IQuillState {
	value: string;
	generation: number;
}

export default class BlogStore extends VariableChangeHandler {
	// Services
	public imageService = new ImageService();

	// Resources
	public quill: ReactQuill | null;

	// Image resizing
	private readonly IMAGE_MAX_SIZE = 1100;

	// Controls
	@observable public blogLoading: boolean = false;
	@observable public slugLoading: boolean = false;
	@observable public blogPageOffset: number = 0;
	@observable public approvedFilter: boolean = true;

	@observable public blog: api.Post[] = [];
	@observable public selectedPost: api.Post | null = null;

	// Variables
	@observable public id: string = "";
	@observable public slug: string = "";
	@observable public title: string = "";
	@observable public text: string = "";

	@computed
	public get loading(): boolean {
		return [
			this.blogLoading,
			this.slugLoading,
		].reduce((l, r) => l || r);
	}

	@computed
	public get header() {
		const header = strings.blog.table.header;
		return [
			header.image,
			header.title,
			header.author,
			header.slug,
		];
	}

	private static formatBlog(blog: api.Post): IRowItem {
		return {
			id: blog.id,
			name: blog.title,
			isActive: blog.approved,
			columns: [
				{
					value: blog.image.url,
					type: ColumnType.avatar,
				}, {
					value: blog.title,
				}, {
					value: blog.user ? blog.user.nick : "LOC",
				}, {
					value: blog.slug,
				},
			],
		};
	}

	@computed
	public get blogTableRows(): IRowItem[] {
		return this.blog.map(BlogStore.formatBlog);
	}

	@action
	public createOrEditBlog = async () => {
		if (this.blogLoading) {
			return;
		}

		if (!this.imageService.singleImageUploader || !this.imageService.singleImageUploader.uploadedUncertainImage) {
			uiStore.showSnackbar(strings.blog.errors.missingImage);
			return;
		}

		this.blogLoading = true;
		try {
			if (this.quill) {
				const quillState = this.quill.state as IQuillState;
				const innerHtml = quillState.value;
				if (this.selectedPost) {
					const editedPost: api.EditPost = {
						body: this.quill.getEditor().getText(),
						bodyHtml: innerHtml,
						slug: this.slug,
						title: this.title,
						image: this.imageService.singleImageUploader.uploadedUncertainImage,
					};
					this.selectedPost = await api.editPost(this.selectedPost.id, editedPost);
				} else {
					const newPost: api.NewPost = {
						body: this.quill.getEditor().getText(),
						bodyHtml: innerHtml,
						slug: this.slug,
						title: this.title,
						image: this.imageService.singleImageUploader.uploadedUncertainImage,
					};
					await api.createPost(newPost);
				}
			}
			routerStore.replace("/dashboard/blog");
			window.location.reload();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.blogLoading = false;
		}
	};

	@action
	public getPost = async (postId: string) => {
		if (this.blogLoading) {
			return;
		}
		this.blogLoading = true;
		try {
			this.selectedPost = await api.getPost(postId);
			this.setEditorFields(this.selectedPost);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.blogLoading = false;
		}
	};

	@action
	public getPosts = async (pageOffset: number) => {
		if (this.blogLoading) {
			return;
		}

		this.blogLoading = true;

		try {
			this.blog = await api.getPosts(pageOffset, this.approvedFilter);
			this.blogPageOffset = pageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.blogLoading = false;
		}
	};

	@action
	public getSlugTip = async () => {
		if (this.slugLoading) {
			return;
		}

		this.slugLoading = true;

		try {
			this.slug = await api.getSlugOption(this.title);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.slugLoading = false;
		}
	};

	@action
	public nextPage = async () => {
		await this.getPosts(this.blogPageOffset + 1);
	};

	@action
	public previousPage = async () => {
		if (this.blogPageOffset > 0) {
			await this.getPosts(this.blogPageOffset - 1);
		}
	};

	@action
	public clear = () => {
		this.slug = "";
		this.title = "";
		this.text = "";
		this.imageService.clear();
	};

	@action
	public toggleApprovedPost = async (postId: string) => {
		if (this.blogLoading) {
			return;
		}

		this.blogLoading = true;

		try {
			const findPost = this.blog.find((post) => post.id === postId);
			if (findPost) {
				await api.setPostApproved(postId, !findPost.approved);
				const index = this.blog.indexOf(findPost);

				if (index > -1 && findPost) {
					findPost.approved = !findPost.approved;
					this.blog.splice(index, 1, findPost);
					routerStore.replace("/dashboard/blog");
					window.location.reload();
				}
			}

		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.blogLoading = false;
		}
	};

	@action
	public openActivePostDialog = async (postId: string, postName: string) => {
		const findPost = this.blog.find((post) => post.id === postId);
		if (findPost) {
			uiStore.showDialog({
				title: strings.common.createOrEdit.changeStatusDialog.title,
				message: `${
					findPost.approved ?
						strings.common.createOrEdit.changeStatusDialog.disable :
						strings.common.createOrEdit.changeStatusDialog.active
					}: ${postName}?`,
			}, () => this.toggleApprovedPost(postId));
		}
	};

	private timeout;

	@action
	public setTitle = (title: string) => {
		clearTimeout(this.timeout);
		this.title = title;
		this.timeout = setTimeout(() => this.getSlugTip(), 1000);
	};

	@action
	public setEditorFields = (post: api.Post) => {
		this.text = post.bodyHtml;
		this.title = post.title;
		this.slug = post.slug;
		this.imageService.setSingleImage(post.image);
	};

	@action
	public startPostCreatOrEdit = (postId?: string) => {
		if (postId) {
			this.getPost(postId);
			routerStore.push(`/dashboard/blog/editor/${postId}`);
		} else {
			this.selectedPost = null;
			this.clear();
			routerStore.push("/dashboard/blog/editor");
		}
	};

	@action
	public openDeletePostDialog = async (postId: string, postTitle: string) => {
		uiStore.showDialog(
			strings.blog.deleteBlogDialog,
			() => this.deletePost(postId),
		);
	};

	@action
	public deletePost = async (postId: string) => {
		if (this.blogLoading) {
			return;
		}
		this.blogLoading = true;
		try {
			await api.deletePost(postId);
			this.blog = this.blog.filter((item) => item.id !== postId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.blogLoading = false;
		}
	};

	@action
	public editorImageHandler = () => {
		openInputImageDialog(async (imageFile) => {
			if (!this.quill) {
				uiStore.showAlert({
					title: strings.quill.error.errorTitle,
					message: strings.quill.error.editorNotFefined,
				});
				return;
			}
			const editor = this.quill.getEditor();
			const range = editor.getSelection(true);
			editor.disable();

			craftImageBuffer(imageFile, this.IMAGE_MAX_SIZE, async (buffer) => {
				try {
					const res = await api.uploadImage(buffer);
					editor.insertEmbed(range.index, "image", res.url);

					range.index = range.index + 1;
					editor.setSelection(range);
				} catch (err) {
					uiStore.showAlert({
						title: strings.quill.error.imageUploadFail,
						message: err.message,
					});
				} finally {
					editor.enable();
				}
			});

		}, (errMsg) => uiStore.showAlert({
			title: strings.quill.error.errorTitle,
			message: errMsg,
		}));
	};

	@computed
	public get editorModules() {
		return {
			toolbar: {
				// Buttons we want at the Quill's editor toolbar.
				container: [
					[{ header: [1, 2, false] }],
					["bold", "italic", "underline", "strike", "blockquote"],
					[
						{ list: "ordered" },
						{ list: "bullet" },
						{ indent: "-" },
						{ indent: "+1" },
					],
					["link", "image"],
				],
				// Overriding the image button click
				handlers: {
					image: this.editorImageHandler,
				},
			},
		};
	}

	public setQuillRef = (el: ReactQuill | null) => {
		this.quill = el;
	};

	@action
	public handleEditorChange = (content: string) => {
		this.text = content;
	};

	@action
	public toggleApprovedFilter = async () => {
		this.approvedFilter = !this.approvedFilter;
		await this.getPosts(0);
	};
}

import React, { Component } from "react";
import { Button, Modal, Typography } from "@material-ui/core";
import strings from "../../resources/strings";

interface IProps {
	children: React.ReactNode;
	onCancel: () => void;
}

interface IState {
	open: boolean;
}

export default class CancelConfirmationModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			open: false,
		};
	}

	public handleOpen = (): void => {
		this.setState({ open: true });
	};

	public handleClose = (): void => {
		this.setState({ open: false });
	};

	public handleConfirmCancel = (): void => {
		this.props.onCancel();
		this.handleClose();
	};

	public render() {
		return (
			<div>
				<div role="button" onClick={this.handleOpen}>
					{this.props.children}
				</div>
				<Modal
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="cancel-modal-title"
					aria-describedby="cancel-modal-description"
				>
					<div
						style={{
							position: "absolute",
							width: 400,
							backgroundColor: "white",
							padding: 16,
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					>
						<Typography id="cancel-modal-title" variant="h6" component="h2" gutterBottom={true}>
							{strings.components.modalCancelation.title}
						</Typography>
						<Typography id="cancel-modal-description" gutterBottom={true}>
							{strings.components.modalCancelation.description}
						</Typography>
						<div style={{ marginTop: 30 }}>
							<Button
								variant="contained"
								style={{ marginRight: 10 }}
								onClick={this.handleConfirmCancel}
							>
								{strings.components.modalCancelation.confirm}
							</Button>
							<Button onClick={this.handleClose}>
								{strings.components.modalCancelation.cancel}
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import AuthStore from "../../stores/AuthStore";
import { RouterStore } from "mobx-react-router";
import { routerStore } from "../../stores/_rootStore";

// MARK: Layout
import LoginLayout from "../../layouts/LoginLayout";

// MARK: Components
import TextField from "../../components/TextField";
import Button from "../../components/Button";

// Resources
import strings from "../../resources/strings";

interface IProps {
	authStore: AuthStore;
	routerStore: RouterStore;
	history: any;
}

@inject("authStore", "routerStore")
@observer
export default class Login extends React.Component<IProps> {
	public componentWillMount = async () => {
		await this.props.authStore.openDashboardIfAuthenticated();
	};

	public render() {
		const { authStore } = this.props;

		return (
			<LoginLayout>
				{!authStore.loading && (
					<div className="login">
						<div className="loginCard">
							<img className="loginCardImage" src={strings.images.logo.src} alt={strings.images.logo.alt} />
							<TextField
								label={strings.common.email}
								type="email"
								name="email"
								value={authStore.email}
								onChange={authStore.handleValueChange}
								disabled={authStore.loading}
							/>
							<TextField
								label={strings.common.password}
								type="password"
								name="password"
								value={authStore.password}
								onChange={authStore.handleValueChange}
								disabled={authStore.loading}
							/>
							<Button className="loginCardButton" onClick={() => authStore.login()}>
								{strings.login.login}
							</Button>
							<Button
								onClick={() => routerStore.push("./recovery")}
								className="recoveryLink"
							>
								{strings.recoverPassword.recoverPasswordButton}
							</Button>
						</div>
					</div>
				)}
			</LoginLayout>
		);
	}
}

import { uploadImage, Image, UncertainImage } from "@startapp/loc-admin-api";
import { observable, action, computed } from "mobx";
import { craftImageBuffer } from "../imageManager";

export default class ImageUploader {

	@observable
	// tslint:disable-next-line: max-union-size
	public status: "failed" | "loading" | "success" | "uploading" | "created" = "created";

	@observable
	public imageFile: File;

	@observable
	public id: string;

	@observable
	public onUpload: boolean = false;

	@observable
	public progress: number = 0;

	@observable
	public filePath: string = "";

	@observable
	public uploadedImage: Image | null = null;

	@observable
	public uploadedUncertainImage: UncertainImage | null = null;

	@computed
	public get src() {
		if (this.uploadedImage) {
			return this.uploadedImage.url;
		} else {
			return this.filePath;
		}
	}

	private reasonableUploadPercentage: number = 5;

	// tslint:disable-next-line: function-name
	public static createUploaded(image: Image) {
		const uploaded = new ImageUploader();
		uploaded.uploadedImage = image;
		uploaded.uploadedUncertainImage = {
			bytes: null,
			image: image ? image : null,
		};
		uploaded.status = "success";
		uploaded.id = image.url;

		return  uploaded;
	}

	constructor(imageFile?: File ) {
		if (imageFile) {
			this.imageFile = imageFile;
			this.filePath = URL.createObjectURL(imageFile);
			this.id = this.filePath;
		}
	}

	@action
	public uploadImage = async () => {
		this.status = "loading";
		await craftImageBuffer(this.imageFile, 2000, async (buffer) => {
			try {
				this.uploadedUncertainImage = {
					bytes: buffer,
					image: await uploadImage(buffer),
				},
				this.status = "success";
			} catch (error) {
				this.status = "failed";
				this.progress = 0;
			}
		});
	}

	@action
	private updateProgress = (progress: number) => {
		if ((progress > this.reasonableUploadPercentage) && this.status !== "failed") {
			this.status = "uploading";
		}
		this.progress = progress;
	}

	@action
	public getSendableImage() {
		return this.uploadedUncertainImage as UncertainImage;
	}

}

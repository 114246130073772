// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Stores
import AuthStore from "../../../../stores/AuthStore";
import RegisterStore from "../../../../stores/RegisterStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Components
import PageTable from "../../../../components/PageTable";

interface IProps extends IStores {}

interface IStores {
	registerStore: RegisterStore;
	authStore: AuthStore;
}

@inject("registerStore", "routerStore")
@observer
export default class AdminUsersTable extends React.Component<IProps> {
	public static defaultProps: IStores;

	public async componentDidMount() {
		await this.props.registerStore.getLinceseds();
	}

	public render() {
		const { registerStore } = this.props;

		return (
			<DashboardLayout
				loading={registerStore.loading}
			>
				<PageTable
					tableConfig={{
						header: registerStore.header,
						title: strings.adminUser.title,
						loading: registerStore.registerLoading,
						nextPage: registerStore.nextPage,
						prevPage: registerStore.previousPage,
						rows: registerStore.licensedsTableRows,
						pageOffset: registerStore.pageOffSet,
						onAddClick: registerStore.startCreateOrEdit,
						onEditClick: registerStore.startCreateOrEdit,
					}}
				/>
			</DashboardLayout>
		);
	}
}

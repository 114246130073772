import * as React from "react";
import "./style.scss";

// MARK: Material
import { Divider as MDivider } from "@material-ui/core";
import { DividerProps as MDividerProps } from "@material-ui/core/Divider";

interface IProps extends MDividerProps {
}

export default class Divider extends React.Component<IProps> {
	public render() {
		const { className, ...props } = this.props;

		return (
			<MDivider
				{...props}
				className="divider"
				style={{ margin: "40px 0" }}
			/>
		);
	}
}

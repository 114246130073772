// MARK: Api
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { observable, action, computed } from "mobx";

// MARK: Resources
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import { inspect } from "util";

export default class AddressService extends VariableChangeHandler {
	// Address Variables
	@observable public cep: string = "";
	@observable public cepValidated: boolean = false;
	@observable public cepBeeingValidated: boolean = false;
	@observable public cepValidationErrorMessage: string | null = null;

	@observable public street: string = "";
	@observable public neighborhood: string = "";
	@observable public reference: string = "";
	@observable public additionalInfo: string = "";
	@observable public number: string = "";
	@observable public city: string = "";
	@observable public cityId: string | null = "";
	@observable public state: string = "";
	@observable public country: string = "";
	@observable public uf: string = "";
	@observable public lat: number = 0;
	@observable public lng: number = 0;

	@computed public get address(): api.Address {
		return {
			zip: this.cep,
			number: this.number,
			street: this.street,
			neighborhood: this.neighborhood,
			city: this.city,
			uf: this.uf,
			adicionalInfo: this.additionalInfo,
			reference: this.reference,
			location: {
				lat: this.lat,
				lon: this.lng,
			},
			country: "",
		};
	}

	@action
	public cepValidate = (cepString: string) => {
		if (this.cepBeeingValidated) {
			return;
		}

		this.cep = cepString;

		setTimeout(async () => {
			if (this.cep === cepString) {
				this.cepBeeingValidated = true;

				try {
					const address = await api.getAddressFromCep(this.cep);
					if (address) {
						this.setFields(address);
					}
				} catch (e) {
					let errorMessage: string;

					if (e.message) {
						errorMessage = e.message;
					} else {
						errorMessage = inspect(e);
					}

					this.cepValidationErrorMessage = errorMessage;
				} finally {
					this.cepBeeingValidated = false;
				}
			}
		}, 600);
	};

	@action
	public setFields = (address: api.FillAddress) => {
		this.clear();

		this.cep = address.zip;
		this.cepValidated = true;
		this.cepBeeingValidated = false;
		this.cepValidationErrorMessage = null;

		this.number = "";
		this.country = address.country;
		this.street = address.street;
		this.neighborhood = address.neighborhood;
		this.city = address.city;
		this.state = address.state;
		this.uf = address.uf;
		this.lat = address.location.lat;
		this.lng = address.location.lon;
	};

	@action
	public clear = () => {
		this.cep = "";
		this.cepValidated = false;
		this.cepBeeingValidated = false;
		this.cepValidationErrorMessage = null;

		this.number = "";
		this.street = "";
		this.neighborhood = "";
		this.city = "";
		this.cityId = "";
		this.state = "";
		this.uf = "";
		this.lat = 0;
		this.lng = 0;
	}
}

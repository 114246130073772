// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Components
import TopUserCard from "./TopUserCard";

// Style
import "./style.scss";

interface IProps {
	topTeenUsers: api.TopTeenUsers | null;
	topUsersLoading: boolean;
}

@observer
export default class TopUserList extends React.Component<IProps> {
	public render() {
		const { topTeenUsers } = this.props;

		return (
			<div className="listTopContent">
				<div className="listTopDetailLine">
					<TopUserCard
						titleUserCard={strings.users.listTopUser.topSeller}
						topUser={topTeenUsers ? topTeenUsers.topSeller : []}
						topUserCardLoading={this.props.topUsersLoading}
					/>
					<TopUserCard
						titleUserCard={strings.users.listTopUser.topBuyer}
						topUser={topTeenUsers ? topTeenUsers.topBuyer : []}
						topUserCardLoading={this.props.topUsersLoading}
					/>
				</div>
				<div className="listTopDetailLine">
					<TopUserCard
						titleUserCard={strings.users.listTopUser.topRented}
						topUser={topTeenUsers ? topTeenUsers.topRented : []}
						topUserCardLoading={this.props.topUsersLoading}
					/>
					<TopUserCard
						titleUserCard={strings.users.listTopUser.topRent}
						topUser={topTeenUsers ? topTeenUsers.topRent : []}
						topUserCardLoading={this.props.topUsersLoading}
					/>
				</div>
			</div>
		);
	}
}

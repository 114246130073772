// MARK: Components
import PageTable from "../../../../components/PageTable";
import Dialog from "../../../../components/Dialog";
import TextField from "../../../../components/TextField";
import TopUserList from "./TopUserList";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Icons
import { Send as SendIcon } from "@material-ui/icons";

// MARK: Stores
import AuthStore from "../../../../stores/AuthStore";
import UIStore from "../../../../stores/_UIStore";
import UsersStore from "../../../../stores/UsersStore";

// MARK: Style
import "./style.scss";

interface IProps extends IStores { }

interface IStores {
	authStore: AuthStore;
	usersStore: UsersStore;
	uiStore: UIStore;
}

@inject("authStore", "usersStore", "uiStore")
@observer
export default class UsersTable extends React.Component<IProps> {
	public static defaultProps: IStores;

	public async componentDidMount() {
		this.props.usersStore.getInitialUserData();
	}

	public render() {
		const { usersStore } = this.props;

		return (
			<DashboardLayout>
				<PageTable
					filter={{
						filters: [
							{
								filterTextService: usersStore.filterTextService,
								title: strings.filter.nickOrName,
								type: "text",
							},
							{
								type: "period",
								title: strings.filter.period.createdAt,
								filterPeriodService: usersStore.filterPeriodCreatedService,
							},
						],
						onFilter: usersStore.onFilter,
						badgesFilter: usersStore.badgesFilter,
						clearFilter: usersStore.clearFilter,
					}}
					topListTable={(
							<TopUserList
								topTeenUsers={usersStore.topUsers}
								topUsersLoading={usersStore.topUsersLoading}
							/>
					)}
					statsList={
						[{
							label: strings.users.usersStats.totalAmount,
							value: usersStore.usersStats ?
							usersStore.usersStats.total.toString() : "",
						}]
					}
					statsLoading={usersStore.usersStatsLoading}
					onExportTable={usersStore.getUsersReport}
					tableConfig={{
						sortService: usersStore.sortTableService,
						rows: usersStore.rowsTable,
						header: usersStore.headerTable,
						title: strings.users.title,
						loading: usersStore.usersLoading,
						nextPage: usersStore.nextPage,
						prevPage: usersStore.previousPage,
						pageOffset: usersStore.usersPageOffset,
						onSelectClick: usersStore.toggleUserSelection,
						onEditClick: usersStore.redirectToUserEdit,
						onDetailClick: usersStore.redirectToUserDetails,
						onDeleteClick: usersStore.openDeleteUserDialog,
						activeSelectionsToolbar: (
							<SendIcon
								titleAccess={strings.notifications.title}
								onClick={usersStore.toggleNotificationModal}
								className="toolbarIcon"
							/>
						),
					}}
					editColumnsTable={{
						allColumns: usersStore.allColumns,
						saveSelectedColumns: usersStore.saveSelectedColumns,
						selectedColumns: usersStore.selectedColumns,
					}}
				/>
				<Dialog
					onDialogConfirm={() => usersStore.sendNotification()}
					clearDialog={() => usersStore.clearNotificationFields()}
					isOpen={usersStore.dialogNotification}
					dialogTitle={strings.notifications.title}
				>
					<TextField
						label={strings.notifications.fields.message}
						name="message"
						value={usersStore.message}
						onChange={usersStore.handleValueChange}
						multiline={true}
						rows={3}

					/>
				</Dialog>
			</DashboardLayout>
		);
	}
}

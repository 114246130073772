// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";
import PageTable from "../../../../components/PageTable";

// MARK: Resources
import strings from "../../../../resources/strings";

// MARK: Stores
import AuthStore from "../../../../stores/AuthStore";
import FinesStore from "../../../../stores/FinesStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Components
import FineDetails from "../../../../components/FineDetails";

interface IProps extends IStores {}

interface IStores {
	authStore: AuthStore;
	finesStore: FinesStore;
}

@inject("authStore", "finesStore")
@observer
export default class FinesTable extends React.Component<IProps> {
	public static defaultProps: IStores;

	public async componentDidMount() {
		await this.props.finesStore.getFines();
	}

	public render() {
		const { finesStore } = this.props;

		return (
			<DashboardLayout
				loading={finesStore.loading}
			>
				<PageTable
					tableConfig={{
						header: finesStore.header,
						title: strings.fines.title,
						loading: finesStore.finesLoading,
						nextPage: finesStore.nextPage,
						prevPage: finesStore.previousPage,
						rows: finesStore.finesTableRows,
						pageOffset: finesStore.finesPageOffset,
						onDetailClick: finesStore.openDetailsDialog,
					}}
				/>
				<FineDetails
					finesStore={finesStore}
					clearDialog={() => finesStore.closeDetailsDialog()}
					isOpen={finesStore.detailsDialogIsOpen}
					dialogTitle={strings.fines.details.title}
					noActions={true}
				/>
			</DashboardLayout>
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Components
import { default as MDialog } from "@material-ui/core/Dialog";
import { default as MDialogActions } from "@material-ui/core/DialogActions";
import { default as MDialogContent } from "@material-ui/core/DialogContent";
import { default as MDialogContentText } from "@material-ui/core/DialogContentText";
import { default as MDialogTitle } from "@material-ui/core/DialogTitle";
import { default as MButton } from "@material-ui/core/Button";

interface IProps {
	onDialogConfirm: (() => void) | null;
	clearDialog: () => void;
	dialogMessage?: string | null;
	dialogTitle?: string | null;
	loading?: boolean;
	notCloseOnConfirmFunction?: boolean;
}

export default class DialogModified extends React.Component<IProps> {
	private onConfirmClick = () => {
		const { onDialogConfirm, clearDialog, notCloseOnConfirmFunction } = this.props;

		if (onDialogConfirm) {
			onDialogConfirm();
		}
		if (!notCloseOnConfirmFunction) {
			clearDialog();
		}
	};

	public render() {
		const { clearDialog, dialogMessage, dialogTitle, loading } = this.props;

		return (
			<MDialog
				open={((!!dialogTitle && dialogTitle !== "") || (!!dialogMessage && dialogMessage !== ""))}
				onClose={clearDialog}
			>
				{dialogTitle && <MDialogTitle>{dialogTitle}</MDialogTitle>}
				{dialogMessage ? (
					<MDialogContent>
						<MDialogContentText>
							{dialogMessage}
						</MDialogContentText>
					</MDialogContent>
				) :
					this.props.children
				}
				<MDialogActions>
					{loading ? (
						<MButton variant="outlined">
							{strings.common.loading}
						</MButton>
					) :
					(
						<>
						<MButton
							onClick={clearDialog}
							variant="outlined"
						>
							{strings.common.cancel}
						</MButton>
						<MButton
							style={{marginLeft: 10, backgroundColor: "#FC6371"}}
							onClick={this.onConfirmClick}
							variant="contained"
							autoFocus={true}
						>
							<p style={{margin: 0, color: "#FFFFFF"}}>{strings.common.confirm}</p>
						</MButton>
						</>
					)}
				</MDialogActions>
			</MDialog>
		);
	}
}

// MARK: React
import * as React from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";

// MARK: Components
import PromotionsCreateOrEdit from "./PromotionsCreateOrEdit";
import PromotionsTable from "./PromotionsTable";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Layout
import DashboardLayout from "../../../layouts/DashboardLayout";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Promotions extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render() {
		return (
			<Switch>
				<Route exact={true} path="/dashboard/promotions/editor" component={PromotionsCreateOrEdit} />
				<Route path="/dashboard/promotions/editor/:promotionId" component={PromotionsCreateOrEdit} />
				<Route path="/dashboard/promotions/" component={PromotionsTable} />
			</Switch>
		);
	}
}

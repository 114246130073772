// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Api
import { ReportGroupType, allValuesReportGroupType, translateReportGroupType } from "@startapp/loc-admin-api";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import ReportStore from "../../../stores/ReportStore";

// MARK: Layout
import DashboardLayout from "../../../layouts/DashboardLayout";

// MARK: Resources
import colors from "../../../styles/colors";
import strings from "../../../resources/strings";
import { currencyForBR } from "../../../resources/format";

// MARK: Components
import { Line } from "react-chartjs-2";

import DatePicker from "../../../components/DatePicker";
import SelectEnum from "../../../components/SelectEnum";
import ArrayField from "../../../components/ArrayField";
import Button from "../../../components/Button";
import Filter from "../../../components/Filter";

interface IProps {
	reportStore: ReportStore;
}

@inject("reportStore")
@observer
export default class Report extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.reportStore.getReport();

		await this.props.reportStore.getUsersGroupReport();
		await this.props.reportStore.getItemsGroupReport();
		await this.props.reportStore.getRentsGroupReport();
		await this.props.reportStore.getSalesGroupReport();
	}

	// tslint:disable-next-line: max-func-body-length no-big-function
	public render() {
		const { reportStore } = this.props;

		const graphDataSetLineConfig = {
			backgroundColor: colors.primary50,
			borderColor: colors.primary,
			borderWidth: 1,
			hoverBackgroundColor: colors.primary50,
			hoverBorderColor: colors.primary,
		};

		return (
			<DashboardLayout>
				<div className="reportContainer">
					<div className="reportWrapper">
						<div className="reportTitle">
							<h1>{strings.report.title}</h1>
						</div>
						{reportStore.report && (
							<div className="reportContent">
								<div className="reportBlock">
									<h2>{strings.report.subtitles.general}</h2>
									<div className="reportDetailLine">
										<div className="reportDetail">
											<label>{strings.report.details.numberOfUser}</label>
											<p>{reportStore.report.numberOfUser}</p>
										</div>
										<div className="reportDetail">
											<label>{strings.report.details.numberOfPieces}</label>
											<p>{reportStore.report.numberOfPieces}</p>
										</div>
									</div>
									<div className="reportDetailLine">
										<div className="reportDetail">
											<label>{strings.report.details.totalOfRents}</label>
											<p>{reportStore.report.totalOfRents}</p>
										</div>
										<div className="reportDetail">
											<label>{strings.report.details.numberOfSales}</label>
											<p>{reportStore.report.totalOfSales}</p>
										</div>
									</div>
									<div className="reportDetailLine">
										<div className="reportDetail">
											<label>{strings.report.details.salesTotalSum}</label>
											<p>{currencyForBR(reportStore.report.averageSalesTicket)}</p>
										</div>
										<div className="reportDetail">
											<label>{strings.report.details.averageTicket}</label>
											<p>{currencyForBR(reportStore.report.averageTicket)}</p>
										</div>
									</div>
									<div className="reportDetailLine">
										<div className="reportDetail">
											<label>{strings.report.details.piecesTotalSum}</label>
											<p>{currencyForBR(reportStore.report.piecesTotalSum)}</p>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="containerSelect">
							<div className="select">
								<DatePicker
									label={strings.filter.dateStart}
									name="filterStart"
									value={reportStore.startDate}
									onChange={reportStore.onStartDateChange}
								/>
							</div>
							<div className="select">
								<DatePicker
									label={strings.filter.dateEnd}
									name="filterEnd"
									value={reportStore.endDate}
									onChange={reportStore.onEndDateChange}
								/>
							</div>
							<div className="select">
								<SelectEnum
									label={strings.filter.reportGroupType}
									enumTranslator={translateReportGroupType}
									selectedEnum={reportStore.reportGroupType}
									onChange={reportStore.onReportGroupType}
									enumValues={allValuesReportGroupType()}
								/>
							</div>
						</div>
						<div className="graphsContainer">
							{/* Users */}
							<div>
								<h2>{strings.report.graphs.users.title}</h2>
								<Line
									data={{
										labels: reportStore.usersGroupReportNumberLabel
											.map((usersNumberLabel) => usersNumberLabel.label),
										datasets: [
											{
												...graphDataSetLineConfig,
												label: strings.report.graphs.users.timeGroup.datasets.users,
												data: reportStore.usersGroupReportNumberLabel
													.map((usersNumberLabel) => usersNumberLabel.number),
											},
										],
									}}
								/>
								<p>
									{(() => {
										switch (reportStore.reportGroupType) {
											case ReportGroupType.date: {
												return strings.report.graphs.users.timeGroup.title.date;
											}
											case ReportGroupType.dateHour: {
												return strings.report.graphs.users.timeGroup.title.dateHour;
											}
											case ReportGroupType.hour: {
												return strings.report.graphs.users.timeGroup.title.hour;
											}
										}
									})()}
								</p>
							</div>

							{/* Items */}
							<div>
								<h2>{strings.report.graphs.items.title}</h2>
								<Line
									data={{
										labels: reportStore.itemsGroupReportNumberLabel
											.map((usersNumberLabel) => usersNumberLabel.label),
										datasets: [
											{
												...graphDataSetLineConfig,
												label: strings.report.graphs.items.timeGroup.datasets.items,
												data: reportStore.itemsGroupReportNumberLabel
													.map((usersNumberLabel) => usersNumberLabel.number),
											},
										],
									}}
								/>
								<p>
									{(() => {
										switch (reportStore.reportGroupType) {
											case ReportGroupType.date: {
												return strings.report.graphs.items.timeGroup.title.date;
											}
											case ReportGroupType.dateHour: {
												return strings.report.graphs.items.timeGroup.title.dateHour;
											}
											case ReportGroupType.hour: {
												return strings.report.graphs.items.timeGroup.title.hour;
											}
										}
									})()}
								</p>
							</div>

							{/* Rents */}
							<div>
								<h2>{strings.report.graphs.rents.title}</h2>
								<Line
									data={{
										labels: reportStore.rentsGroupReportNumberLabel
											.map((usersNumberLabel) => usersNumberLabel.label),
										datasets: [
											{
												...graphDataSetLineConfig,
												label: strings.report.graphs.rents.timeGroup.datasets.rents,
												data: reportStore.rentsGroupReportNumberLabel
													.map((usersNumberLabel) => usersNumberLabel.number),
											},
										],
									}}
								/>
								<p>
									{(() => {
										switch (reportStore.reportGroupType) {
											case ReportGroupType.date: {
												return strings.report.graphs.rents.timeGroup.title.date;
											}
											case ReportGroupType.dateHour: {
												return strings.report.graphs.rents.timeGroup.title.dateHour;
											}
											case ReportGroupType.hour: {
												return strings.report.graphs.rents.timeGroup.title.hour;
											}
										}
									})()}
								</p>
							</div>

							{/* Sales */}
							<div>
								<h2>{strings.report.graphs.sales.title}</h2>
								<Line
									data={{
										labels: reportStore.salesGroupReportNumberLabel
											.map((usersNumberLabel) => usersNumberLabel.label),
										datasets: [
											{
												...graphDataSetLineConfig,
												label: strings.report.graphs.sales.timeGroup.datasets.sales,
												data: reportStore.salesGroupReportNumberLabel
													.map((usersNumberLabel) => usersNumberLabel.number),
											},
										],
									}}
								/>
								<p>
									{(() => {
										switch (reportStore.reportGroupType) {
											case ReportGroupType.date: {
												return strings.report.graphs.sales.timeGroup.title.date;
											}
											case ReportGroupType.dateHour: {
												return strings.report.graphs.sales.timeGroup.title.dateHour;
											}
											case ReportGroupType.hour: {
												return strings.report.graphs.sales.timeGroup.title.hour;
											}
										}
									})()}
								</p>
							</div>
						</div>
					</div>
				</div>
			</DashboardLayout>
		);
	}
}

import * as api from "@startapp/loc-admin-api";
import LocalizedStrings from "localized-strings";

// MARK: Libraries
import moment from "moment";

const strings = new LocalizedStrings({
	ptBR: {
		formatter: {
			percent: (percent: number) => `${parseFloat((Math.abs(percent) * 100).toString()).toFixed(1)}%`,
			date: {
				dayAndMonth: (date: Date) => moment(date).format(strings.moment.dateMonth),
				date: (date: Date) => moment(date).format("DD/MM/YYYY"),
			},
			moment: {
				dayAndMonth: (date: moment.Moment) => date.format(strings.moment.dateMonth),
			},
		},
		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder: "Clique em adicionar para escolher algumas imagens",
			addImageBtn: "Adicionar",
		},
		welcomeLoc: "Bem-Vindo ao Dashboard LOC!",
		yes: "Sim",
		no: "Não",
		success: {
			sendNotification: "Notificação enviada com sucesso!",
		},
		filter: {
			badges: {
				onlyStart: (filter: string, startDate: string) => `${filter}: De ${startDate}`,
				onlyEnd: (filter: string, endDate: string) => `${filter}: Até ${endDate}`,
				startAndEnd: (filter: string, startDate: string, endDate: string) => `${filter}: De ${startDate} Até ${endDate}`,
				id: (id: string) => `ID: ${id}`,
				nameOrNick: (text: string) => `Nome/Nick: ${text}`,
				currentStatus: (status: string) => `Status Atual: ${status}`,
			},
			components: {
				periodPicker: {
					addDate: "Adicionar Data",
					from: "De:",
					to: "Até:",
				},
				filterText: {
					addText: "Adicione o nome",
				},
			},
			rentPeriods: {
				start: "Inicio do Aluguel",
				end: "Fim do Aluguel",
			},
			salePeriods: {
				start: "Periodo da Venda",
			},
			title: "Filtro",
			dateRent: "Data do Aluguel",
			dateStart: "Inicio",
			dateEnd: "Fim",
			reportGroupType: "Tipo de Agrupamento",
			nickOrName: "Filtrar por nome/nick",
			createdAt: "Data de Criação",
			addFilter: "Adicionar um filtro",
			nameOrExpiration: "Filtrar por nome/expiração",
			currentStatus: "Filtrar por Status",
			filterText: "Filtrar por nome",
			// tslint:disable-next-line: no-duplicate-string
			expirationDate: "Data de Expiração",
			textField: {
				apply: "Aplicar",
				add: "Adicionar",
				clear: "Limpar",
			},
			autosuggest: {
				apply: "Aplicar",
				add: "Adicionar",
			},
			enumPicker: {
				apply: "Aplicar",
				add: "Adicionar",
			},
			period: {
				startRent: "Inicio do Aluguel",
				endRent: "Fim do Aluguel",
				createdAt: "Criação do Aluguel",
				rent: "Alugueis em Período",
				sale: "Vendas em Período",

				// tslint:disable-next-line: no-duplicate-string
				expirationDate: "Data de Expiração",
			},
			filter: "Filtrar",
		},
		pages: {
			blog: "Blog",
			feed: "Feed",
			home: "Home",
			users: "Usuários",
			requests: "Alugueis",
			promotions: "Promoções",
			notifications: "Notificações",
			items: "Peças",
			fines: "Multas",
			banners: "Banners",
			licensed: "Minha Licença",
			sales: "Vendas",
			usersDetails: {
				route: (userId: string) => `/users/${userId}`,
			},
			report: "Relatório",
			register: "Licenciadas",
		},
		components: {
			modalCancelation: {
				title: "Confirmação de Cancelamento",
				description: "Tem certeza que deseja cancelar essa compra?",
				confirm: "Confirmar",
				cancel: "Cancelar",
			},
			table: {
				create: "Criar",
				delete: "Deletar",
				edit: "Editar",
				editAndDelete: "Editar / Deletar",
				actions: "Ações",
				select: "Selecionar",
				active: "Ativar / Desativar",
				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},
				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
				densePadding: "Tabela Densa",
			},
			componentButton: {
				today: "Hoje",
				yesterday: "Ontem",
				sevenBefore: "7 dias atrás ",
				fifteenBefore: "15 dias atrás ",
				thirtyBefore: "30 dias atrás ",
				customPeriod: "Escolher Período",
				period: (period: {start: string, end: string}) => `${period.start} até ${period.end}`,
				percentage: (percentage: number) => `${percentage}%`,
				totalAmount: "Faturamento Total",
				totalTicket: "Ticket Médio Total",
				newUsers: "Novos Usuários",
				firstRents: "Primeiros Aluguéis",
				firstSales: "Primeiras Vendas",
				registeredItens: "Peças Cadastradas",
				newLockers: "Novos Armários",
			},
		},
		home: {
			components: {
				selectFilterTranslator: (item: string) =>  {
					switch (item) {
						case "date": {
							return "Data";
						}
						case "hour": {
							return "Hora";
						}
						case "dateAndHour": {
							return "Data e Hora";
						}
						default: {
							return "";
						}
					}
				},
			},
			ticket: {
				sales: "Ticket Médio de Vendas",
				rents: "Ticket Médio de Alugueis",
			},
			total: {
				sales: "Total em Vendas",
				rents: "Total em Alugueis",
			},
			customPeriod: "Período Customizado",

		},
		adminUser: {
			title: "Licenciadas",
			table: {
				header: {
					id: "ID",
					type: "Tipo",
					name: "Nome",
					email: "Email",
					document: "CPF / CNPJ",
					cityName: "Cidade",
				},
				tableIsEmpty: "Não foi possível encontrar mais promoções",
			},
		},
		item: {
			title: "Peças",
			ownerItem: "Proprietário da Peça",
			table: {
				header: {
					nick: "Nick",
					id: "ID",
					category: "Categoria",
					images: "Imagem",
					brand: "Marca",
					brandUndefined: "Indefinida",
					rentPrice: "Preço do Aluguel",
					salePrice: "Preço à Venda",
					size: "Tamanho",
					calendar: "Calendário",
					adjustment: "Ajustamento",
					adjustable: "Ajustável",
					notAdjustable: "Não Ajustável",
					department: "Departamento",
					color: "Cor",
					details: "Detalhes",
					gender: "Genêro",
					occasions: "Ocasiões",
					sizeUndefined: "Tamanho não Definido",
				},
				deleteItemDialog: {
					title: "Excluir Peça",
					message: "Deseja excluir",
				},
				period: {
					start: "Data de Início",
					end: "Data Final",
				},
			},
			createOrEdit: {
				title: "Editar peça",
			},
			details: {
				title: "Detalhes da Peça",
			},
			stats: {
				totalAmount: "Total de Peças",
				averageRentPrice: "Preço Médio de Aluguéis",
				averageSalePrice: "Preço Médio de Vendas",
			},
			period: (start: string, end: string) => `De ${start} até ${end}`,
		},
		buttons: {
			edit: "Editar",
			save: "Salvar",
			send: "Enviar",
			search: "Pesquisar",
			sendId: "Enviar ID",
			back: "Voltar",
			cancel: "Cancelar",
			loading: "carregando",
			searching: "procurando...",
			filter: "Filtrar",
			chooseCities: "Cidades",
			close: "Fechar",
		},
		promotions: {
			deletePromotionDialog: {
				title: "Excluir Promoção",
				message: "Deseja excluir a promoção?",
			},
			title: "Promoções",
			table: {
				header: {
					percentage: "Porcentagem",
					id: "ID",
					type: "Tipo",
					value: "Valor",
					usageType: "Tipo de uso",
					maxUsesPerUser: "Máximo de usos por usuário",
					maxUses: "Máximo de usos",
					expirationDate: "Data de expiração",
				},
				tableIsEmpty: "Não foi possível encontrar mais promoções",
			},
			createOrEdit: {
				title: {
					new: "Nova Promoção",
					edit: "Editar Promoção",
				},
				fields: {
					id: "ID",
					value: "Valor",
					percentage: "Porcentagem",
					type: "Tipo",
					UsageType: "Tipo de Uso",
					maxUsesPerUser: "Máximo de Uso por Usuário",
					maxUses: "Uso Máximo",
					expirationDate: "Data de Expiração",
				},
				sections: {
					data: "Dados",
					specifications: "Especificações",
				},
			},
		},
		blog: {
			deleteBlogDialog: {
				title: "Excluir Post",
				message: "Deseja excluir esse post?",
			},
			title: "Posts",
			filterStatus: {
				info: (approved: boolean) => `Exibindo postagens ${approved ? "aprovadas" : "não aprovadas"}.`,
				button: "Alterar",
			},
			table: {
				header: {
					id: "ID",
					title: "Titulo",
					author: "Autor",
					text: "Texto",
					approve: "Aprovado",
					slug: "Slug",
					image: "Imagem",
				},
				tableIsEmpty: "Não foi possível encontrar mais postagens",
			},
			createOrEdit: {
				title: {
					new: "Nova Postagem",
					edit: "Editar Postagem",
				},
			},
			slugLoading: "Carregando sugestão de slug...",
			errors: {
				missingImage: "Por favor, selecione uma imagem para o post",
			},
		},
		feed: {
			deletePostDialog: {
				title: "Apagar twit",
				message: "Você realmente deseja apagar este twit? Esta ação não pode ser desfeita.",
			},
			title: "Twit",
			totalTwit: "Total de Twits",
			titleDetails: "Post",
			ownerPost: "Dono do Post",
			nickname: "Apelido",
			table: {
				header: {
					image: "Imagem",
					author: "Autor",
					text: "Texto",
					nick: "Apelido",
				},
				tableIsEmpty: "Não foi possível encontrar mais postagens",
			},
		},
		banners: {
			title: "Banners",
			fields: {
				image: "Imagem",
				slug: "Slug",
				description: "Descrição",
				nameBanner: "Titulo do Banner",
				typeBanner: "Selecione o Tipo",
				groupBanner: "Selecione o Conteudo",
			},
			component: {
				title: "Seções",
				bannerContentGroup: {
					title: "Ordenar por Grupo",

					addUsers: "Adicionar ID Usuária(s)",
					userId: "ID Usuárias",
					inputItemId: "Peças",
					sections: "Seções",
					addSection: "Adicionar Seção",
					addItem: (sectionName: string) =>  `Adicionar Item em ${sectionName}`,
					sectionTitle: (sectionName: string) => `Seção ${sectionName}`,
					itemInSection: (sectionName: string) => `Item na seção ${sectionName}`,
					image: "Imagem do Banner",
					url: "Url do Banner",
				},
				bannerContentQuery: {
					title: "Selecionar Por Filtro",
				},
			},
			content: {
				url: "URL",
				query: "Filtro",
				group: "Seleção",
				search: "Pesquisa",
			},
			createSuccessful: "Banner criado com sucesso",
			editSuccessful: "Banner editado com sucesso",
			filter: {
				distance: "Distância",
				gender: "Gênero",
				category: "Categoria",
				colors: "Cores",
				occasions: "Ocasiões",
				sizes: "Tamanhos",
				orderBy: "Ordenar Por",
				itemOfferType: "Tipo de Oferta",
				buttons: {
					filter: "Filtrar",
					clean: "Limpar",
				},
			},
		},
		notifications: {
			title: "Enviar Notificação",
			tooltip: "Enviar notificação para usuárias selecionadas",
			fields: {
				id: "ID",
				message: "Message",
				notificationType: "Tipo da Notificação",
			},
		},
		users: {
			title: "Usuários",
			verifiedDocument: "Documento verificado?",
			usersStats: {
				title: "Estatíticas de Usuários",
				totalAmount: "Total de Usuários",
			},
			listTopUser: {
				title: "Usuárias Top",
				topSeller: "Top Vendedoras",
				topBuyer: "Top Compradoras",
				topRented: "Top Locadoras",
				topRent: "Top Locatárias",
				amout: "Total",
			},
			table: {
				title: "Dados do Usuário",
				header: {
					id: "ID",
					gender: "Gênero",
					birthday: "Nascimento",
					name: "Nome",
					nick: "Apelido",
					cpf: "CPF",
					email: "Email",
					phone: "Telefone",
					avatar: "Foto",
					verifiedDocument: "Documento verificado",
					address: "Endereço",
					createdAt: "Criado em",
					deletedAt: "Deletado em",
					genderUndefined: "Indefinido",
					calendar: "calendário",
					height: "altura",
					blockedUntil: "Bloquado até",
					type: "Tipo de Usuário",
					unBlock: "Desbloquear",
					popularity: "Popularidade",
				},
				button: {
					title: "Exportar Usuário(s)",
				},
				deleteUserDialog: {
					title: "Excluir Usuário",
					message: "Deseja excluir ",
				},
			},
		},
		fines: {
			title: "Multas",
			table: {
				title: "Multas",
				header: {
					id: "ID",
					transactionId: "ID da transação",
					payedBy: "Pagante",
					payedTo: "Recebedor",
					createdAt: "Data da criação",
				},
			},
			details: {
				title: "Detalhes da Multa",
				labels: {
					id: "ID",
					transactionId: "ID de transação",
					payedBy: "Pagante",
					payedTo: "Recebedor",
					createdDate: "Data de criação",
					reason: "Motivo",
					amount: "Valor",
				},
			},
		},
		requests: {
			title: "Aluguéis",
			cancelRequest: "Cancelar Pedido",
			rentsStats: {
				title: "Dados dos Aluguéis",
				totalAmount: "Total de Aluguéis",
				averageTicked: "Ticket Médio",
				totalPrice: "Valor Total",
			},
			table: {
				header: {
					details: "Detalhes",
					image: "Imagem",
					id: "ID",
					numId: "Num ID",
					requester: "Locatário",
					buyer: "Comprador",
					period: "Período",
					rentPrice: "Valor do Aluguel",
					originalPrice: "Valor Original",
					currentStatus: "Status",
					nfe: "NFe",
					createdAt: "Criado  em",
					cupom: "Promoção",

				},
				button: {
					title: "Exportar Pedido(s)",
				},
				dropDown: {
					generateFine: "Gerar Multa",
					changeData: "Alterar Data",
				},
				fineDialog: {
					title: "Gerador de Multa",
					labels: {
						payedBy: "Pago por",
						payedTo: "Pago a",
						reason: "Motivo",
						amount: "Valor em centavos",
					},
				},
				changeDateDialog: {
					title: "Alterar data do pedido",
					labels: {
						start: "Data de início",
						end: "Data de Término",
					},
				},
			},
			details: {
				title: "Detalhes do Pedido",
				labels: {
					numId: "Número do ID",
					name: "Nome",
					nick: "Apelido",
					email: "Email",
					phone: "Telfone",
					address: "Endereço",
					city: "Cidade",
					birthday: "Data de nascimento",
					gender: "Gênero",
					cpf: "CPF",
					originalPrice: "Preço original",
					rentPrice: "Preço do aluguel",
					paidAmount: "Montante pago",
					paymentStatus: "Status do pagamento",
					promotion: "Promoção",
					period: "Período",
					requester: "Locatário",
					creatAt: "Criado em",
				},
			},
		},
		sales: {
			title: "Vendas",
			salesStats: {
				title: "Dados das Vendas",
				totalAmount: "Total de Vendas",
				averageTicked: "Ticket Médio",
				totalPrice: "Valor Total",
			},
			table: {
				header: {
					details: "Detalhes",
					image: "Imagem",
					id: "ID",
					numId: "Num ID",
					seller: "Vendedor",
					buyer: "Comprador",
					period: "Data",
					price: "Valor da Compra",
					currentStatus: "Status",
					nfe: "NFe",
					cupom: "Cupom Usado",
				},
				button: {
					title: "Exportar Venda(s)",
				},
				dropDown: {
					generateFine: "Gerar Multa",
					changeData: "Alterar Data",
				},
				fineDialog: {
					title: "Gerador de Multa",
					labels: {
						payedBy: "Pago por",
						payedTo: "Pago a",
						reason: "Motivo",
						amount: "Valor em centavos",
					},
				},
				changeDateDialog: {
					title: "Alterar data do pedido",
					labels: {
						start: "Data de início",
						end: "Data de Término",
					},
				},
			},
			details: {
				title: "Detalhes do Pedido",
				titleSale: "Detalhes da Venda",
				labels: {
					numId: "Número do ID",
					name: "Nome",
					nick: "Apelido",
					email: "Email",
					phone: "Telfone",
					address: "Endereço",
					city: "Cidade",
					birthday: "Data de nascimento",
					gender: "Gênero",
					cpf: "CPF",
					price: "Preço",
					rentPrice: "Preço do aluguel",
					paidAmount: "Montante pago",
					paymentStatus: "Status do pagamento",
					promotion: "Promoção",
					period: "Período",
					buyer: "Comprador",
					seller: "Vendedor",
					creatAt: "Criado em",
				},
			},
		},
		rents: {
			title: "Alugueis",
		},
		report: {
			title: "Relatório",
			subtitles: {
				general: "Dados gerais",
				itemFields: "Dados Por Categoria",
			},
			details: {
				numberOfUser: "Número de Usuários",
				numberOfPieces: "Número de Peças",
				numberOfSales: "Total de Vendas",
				piecesTotalSum: "Montante Total de peças",
				salesTotalSum: "Ticket Médio de Vendas",
				averageTicket: "Ticket médio de Alugueis",
				totalOfRents: "Total de alugueis",
				itemsPerField: "Peças por Característica",
				count: "Quantidade",
				sum: "Montante",
			},
			filter: {
				fieldname: "Característica da Peça",
				value: "Valor da Característica",
			},
			graphs: {
				users: {
					title: "Usuários",
					timeGroup: {
						title: {
							date: "Usuários cadastrados por dia",
							dateHour: "Usuários cadastrados por dia e hora",
							hour: "Usuários cadastrados por hora",
						},
						datasets: {
							users: "Usuários cadastrados",
						},
					},
				},
				items: {
					title: "Peças",
					timeGroup: {
						title: {
							date: "Peças cadastradas por dia",
							dateHour: "Peças cadastradas por dia e hora",
							hour: "Peças cadastradas por hora",
						},
						datasets: {
							items: "Peças cadastradas",
						},
					},
				},
				rents: {
					title: "Aluguéis",
					timeGroup: {
						title: {
							date: "Aluguéis por dia",
							dateHour: "Aluguéis por dia e hora",
							hour: "Aluguéis por hora",
						},
						datasets: {
							rents: "Aluguéis",
						},
					},
				},
				sales: {
					title: "Vendas",
					timeGroup: {
						title: {
							date: "Vendas por dia",
							dateHour: "Vendas por dia e hora",
							hour: "Vendas por hora",
						},
						datasets: {
							sales: "Vendas",
						},
					},
				},
			},
		},
		licensed: {
			title: "Informações da Licença",
			licenses: {
				title: "Licença de ",
				percentage: "Percentual: ",
				expirationDate: "Expira em: ",
			},
			bankAccount: {
				balance: "Saldo",
				agencyAccount: (agency: string, account: string, accountDv: string) => `Ag: ${agency} Conta: ${account}-${accountDv}`,
				title: "Conta Bancária",
				waitingFounds: "A Liberar",
				transferTitle: "Transferência",
				transferDetails: "Você deseja sacar o valor disponível? Será cobrado uma taxa de transferência de R$3,67.",
				transfer: "Sacar",
				amountTransferSuccess: "Transferência Realizada com sucesso.",
				amountTransferError: "Você não pode transferir um valor maior que o saldo disponivél.",
				availableFounds: "Liberado",
				totalFounds: "Total",
				notFound: "Você ainda não tem uma conta cadastrada",
			},
		},
		account: {
			title: "Conta Bancária",
			documentNumber: "Número do Documento",
			bank: "Banco",
			bankName: "Nome do Banco",
			bankCode: "Código do Banco",
			agency: "Agência",
			agencyDv: "Dígito Verificador da Agência",
			account: "Conta",
			accountDv: "Dígito Verificador da Conta",
			type: "Tipo",
			legalName: "Titular da Conta",
			add: "Adicionar Conta",
		},
		register: {
			title: "Cadastro administrativo",
			licenseTitle: "Dados da Licença",
			labels: {
				typeLicense: "Tipo da Licensa",
				name: "Nome",
				email: "E-mail",
				selectUf: "Selecione o Estado",
				selectCity: "Selecione a Cidade",
				cities: "Cidades",
				city: "Cidade",
				// tslint:disable-next-line:no-hardcoded-credentials
				password: "Senha",
				uf: "UF",
				percentage: "Percentual",
				expirationDate: "Data de validade",
				document: "Documento",
				type: "Tipo",
			},
			buttons: {
				addLicense: "Adicionar Licença",
				register: "Cadastrar",
				removeLicense: "Remover Licença",
			},
			successCreated: "Cadastro realizado com sucesso!",
			successEdited: "Mudanças salvas com sucesso!",
			error: {
				cityNull: "Você precisa escolher uma cidade antes",
			},
		},
		table: {
			create: "Criar",
			delete: "Deletar",
			edit: "Editar",
			editAndDelete: "Editar / Deletar",
		},
		projectName: "LOC",
		images: {
			avatar: "Avatar",
			logo: {
				alt: "Logo LOC",
				src: "/logo.svg",
			},
		},
		datePicker: {
			format: "dd/MM/yyyy",
			invalidDate: "Data inválida",
		},
		dialogs: {
			logout: {
				title: "Logout",
				message: "Tem certeza que deseja sair?",
			},
		},
		error: {
			default: "Erro de conexão",
			emptyFields: "Preencha os Campos",
			missingImage: "Imagem está vazia",
		},
		recoverPassword: {
			confimationDialog: {
				title: "Recuperção de Senha",
				message: "O email de recuperação de senha foi enviado com sucesso",
			},
			recoverPasswordButton: "Recuperar Senha",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			newPassword: "Nova Senha",
			confirmNewPassword: "Confirme sua nova Senha",
			recoverySuccess: "Senha alterada com sucesso",
		},
		common: {
			originalPrice: "Preço Original",
			hour: (hours: number) => {
				const hourInTimezone = Math.round(hours + (new Date().getTimezoneOffset() / 60));
				return `${hourInTimezone > 23 ? hourInTimezone - 24 : hourInTimezone}h`;
			},
			loading: "Carregando",
			ok: "Ok",
			cancel: "Cancelar",
			confirm: "Confirmar",
			search: "Pequisar",
			yes: "Sim",
			no: "Não",
			edit: "Editar",
			add: "Adicionar",
			added: "Adicionado com sucesso",
			owner: "Proprietário",
			seller: "Vendedor",
			noMoreResults: "Sem mais resultados",
			email: "Email",
			sum: "Total",
			noCupom: "-",
			// tslint:disable-next-line:no-hardcoded-credentials
			password: "Senha",
			default: "Padrão",
			createOrEdit: {
				fields: {
					id: "ID",
					name: "Nome",
					description: "Descrição",
					type: "Tipo",
					value: "Valor",
					active: "Ativo",
					image: "Imagem",
					period: "Periodo",
					// tslint:disable-next-line:no-hardcoded-credentials
					password: "Senha",
					phone: {
						title: "Contato",
						ddi: "Telefone (DDI)",
						ddd: "Telefone (DDD)",
						number: "Telefone (Numero)",
					},
					cpf: "CPF",
					data: "Dados",
					date: "Data",
					email: "Email",
					status: "Status",
					address: {
						titleUser: "Endereço do Usuario",
						title: "Endereço",
						cep: "Cep",
						street: "Rua",
						neighborhood: "Bairro",
						number: "Numero",
						city: "Cidade",
						state: "Estado",
						reference: "Referencia",
						adicionalInfo: "Informação Adicional",
					},
					url: "Url",
					contentType: "Conteúdo",
				},
				buttons: {
					save: "Salvar",
					back: "Voltar",
					loading: "Carregando...",
				},
				deleteDialog: {
					title: "Confirmação de exclusão",
					message: "Deseja excluir",
				},
				changeStatusDialog: {
					title: "Alteração de Status",
					active: "Deseja ativar ",
					disable: "Deseja desativar ",
				},
			},
			table: {
				tableIsEmpty: "Não foi possivel encontrar mais resultados",
			},
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		login: {
			login: "Login",
			resetPassword: "Recuperar senha",
			logout: "Logout",
		},
		address: {
			street: "Logradouro",
			neighborhood: "Bairro",
			city: "Cidade",
			uf: "UF",
			country: "País",
			number: "Número",
			zip: "CEP",
			additionalInfo: "Informação Adicional",
			reference: "Referência",
			location: "Localização",
		},
		quill: {
			error: {
				errorTitle: "Erro",
				noTitle: "É preciso dar um título ao visit para finalizar",
				noCoverImage: "É necessário adicionar uma imagem de capa para criar um visit",
				noPostBody: "É necessário adicionar um texto ao visit",
				coverUploadFail: "Erro ao realizar upload da imagem da capa, porfavor tente mais tarde",
				imageUploadFail: "Erro ao realizar upload da imagem, porfavor tente mais tarde",
				editorNotFefined: "Editor do blog não está respondendo, tente mais tarde.",
				InvalidAuthor: "Profissional inválido, porfavor, tente mais tarde",
			},
		},
		enum: {
			Gender: {
				female: "Feminino",
				male: "Masculino",
				genderUndefined: "Indefinido",
			},
			LocUserType: {
				LOC: "LOC",
				LOCPRO: "LOCPRO",
				TOPLOC: "TOPLOC",
				LOCLOVER: "LOCLOVER",
				LOCSTORE: "LOCSTORE",
			},
			PromotionFieldOrderBy: {
				expirationDate: "Data de Expiração",
				createdAt: "Data de Criação",
				id: "ID",
			},
			ReportGroupType: {
				date: "Dia",
				dateHour: "Dia e Hora",
				hour:  "Hora",
			},
			NotificationType: {
				chat: "chat",
				locRequest: "Pedido",
				card: "cartão",
				bank: "banco",
				display: "tela",
				item: "item",
				locker: "locker",
			},
			PromotionType: {
				percentage: "Porcentagem",
				value: "Valor",
			},
			Ocasion: {
				formalParty: "Formal",
				party: "Festa",
				casual: "Casual",
				winter: "Inverno",
				costume: "Fantasia",
				beach: "Praia",
				work: "Trabalho",
				pregnant: "Gestante",
			},
			PromotionUsageType: {
				firstRentOnly: "Somente Primeiro Aluguel",
				single: "Único",
				infinite: "Ilimitado",
				multiple: "Múltiplo",
			},
			LocStatus: {
				pendingApproval: "Pendente",
				approved: "Aprovado",
				notApproved: "Não aprovado",
				canceled: "Cancelado",
				deliveredToRequester: "Entregue para usuário",
				receivedByRequester: "Recebido pelo usuário",
				deliveredToOwner: "Entregue para dona",
				receivedByOwner: "Recebido pela dona",
			},
			SalesStatusType: {
				pendingApproval: "Pendente",
				approved: "Aprovado",
				notApproved: "Não aprovado",
				canceled: "Cancelado",
				deliveredToRequester: "Entregue para usuário",
				receivedByRequester: "Recebido pelo usuário",
			},
			UserFieldsOrderBy: {
				name: "Nome",
				nick: "Nick",
				email: "E-mail",
				cpf: "CPF",
				createdAt: "Criação",
			},
			Category: {
				rompers: "Macacão",
				conjunct: "Conjuntinho",
				body: "Body",
				top: "Top",
				blouse: "Blusa",
				pants: "Calça",
				shorts: "Short",
				longShorts: "Bermuda",
				skirt: "Saia",
				dress: "Vestido",
				overall: "Sobreposição",
				overlap: "Macaquinho",
				suit: "Terno",
				purse: "Bolsa",
				shoes: "Sapatos",
				winter: "Inverno",
				accessory: "Acessórios",
				other: "Outros",
			},
			Color: {
				striped: "Listrado",
				grid: "Quadriculado",
				yellow: "Amarelo",
				blue: "Azul",
				beige: "Bege",
				white: "Branco",
				gray: "Cinza",
				gold: "Dourado",
				jeans: "Jeans",
				orange: "Laranja",
				brown: "Marrom",
				nude: "Nude",
				ofWhite: "Off-White",
				silver: "Prata",
				black: "Preto",
				pink: "Rosa",
				purple: "Roxo",
				green: "Verde",
				red: "Vermelho",
				wine: "Vinho",
				stamped: "Estampado",
			},
			Department: {
				clothes: "Roupas",
				accessory: "Acessórios",
			},
			Size: {
				s33: "33",
				s34: "34",
				s35: "35",
				s36: "36",
				s37: "37",
				s38: "38",
				s39: "39",
				s40: "40",
				s41: "41",
				s42: "42",
				s43: "43",
				s44: "44",
				s45: "45",
				s46: "46",
				s48: "48",
				s50: "50",
				s50Plus: "50+",
				sL: "G",
				sM: "M",
				sS: "P",
				sSingle: "Único",
				sXL: "GG",
				sXS: "PP",
				sXXL: "GG+",
			},
			OwnerOrRequester: {
				owner: "Proprietário",
				requester: "Locatário",
			},
			PaymentStatus: {
				processing: "Processando",
				authorized: "Autorizado",
				paid: "Pago",
				refunded: "Reembolsado",
				waitingPayment: "Aguardando Pagamento",
				pendingRefund: "Aguardando Reembolso",
				refused: "Recusado",
			},
			AdminUserType: {
				admin: "Administrador",
				licensed: "Licenciada",
			},
			BankAccountType: {
				contaCorrente: "Conta Corrente",
				contaPoupanca: "Conta Poupanca",
				contaCorrenteConjunta: "Conta Corrente Conjunta",
				contaPoupancaConjunta: "Conta Poupanca Conjunta",
			},
			BannerType: {
				banner: "Banner",
				card: "Card",
			},
			HomeGraphicType: {
				value: "Preço",
				amount: "Quantidade",
			},
			BannerContentType: {
				group: "Grupo",
				url: "URL",
				query: "Filtro",
			},
			Permission: {
				editItem: "Editar Peça",
				deleteItem: "Deleta Peça",
				cancelRequest: "Cancelar Pedido",
				changeDatesRequest: "Mudar Datas de Pedido",
				createPromotion: "Criar Promoção",
				editPromotion: "Editar Promoção",
				sendNotifications: "Enviar Notificações",
				deleteUser: "Deletar Usuário",
				verifyUser: "Acessar Usuário",
				chargeFine: "Cobrar Multa",
			},
			ItemFieldName: {
				department: "Departmento",
				adjustment: "Ajuste",
				category: "Categoria",
				color: "Cor",
				brand: "Marca",
				ocasions: "Ocasiões",
				size: "Tamanho",
			},
			OrderBy: {
				popular: "Popularidade",
				relevant: "Relevância",
				lowestPrice: "Menor Preço",
				highestPrice: "Maior Preço",
			},
			ItemOfferType: {
				rent: "Aluguel",
				sale: "Venda",
				rentAndSale: "Aluguel e Venda",
				all: "Todas",
			},
			SaleStatusType: {
				pendingApproval: "Esperando Aprovação",
				approved: "Aprovado",
				notApproved: "Não Aprovado",
				deliveredToRequester: "Entregue para o comprador",
				receivedByRequester: "Recebido pelo Comprador",
				canceled: "Cancelado",
			},
			SaleFieldsOrderBy: {
				price: "Preço de Venda",
				createdAt: "Criação",
			},
			ItemFieldsOrderBy: {
				category: "Categoria",
				color: "Cor",
				gender: "Gênero",
				brand: "Marca",
				size: "Tamanho",
				rentPrice: "Preço de Aluguel",
				originalPrice: "Preço Original",
				salePrice: "Preço de Venda",
				createdAt: "Criação",
				image: "Imagens",
				departament: "Departamento",
			},
			RequestFieldsOrderBy: {
				rentPrice: "Preço de Aluguel",
				createdAt: "Criação",
			},
			TableOrderBy: {
				asc: "Crescente",
				desc: "Decrescente",
			},
			StateUF: {
				AC: "Acre",
				AL: "Alagoas",
				AP: "Amapá",
				AM: "Amazonas",
				BA: "Bahia",
				CE: "Ceará",
				DF: "Distrito Federal",
				ES: "Espírito Santo",
				GO: "Goiás",
				MA: "Maranhão",
				MT: "Mato Grosso",
				MS: "Mato Grosso do Sul",
				MG: "Minas Gerais",
				PA: "Pará",
				PB: "Paraíba",
				PR: "Paraná",
				PE: "Pernambuco",
				PI: "Piauí",
				RJ: "Rio de Janeiro",
				RN: "Rio Grande do Norte",
				RS: "Rio Grande do Sul",
				RO: "Rondônia",
				RR: "Roraima",
				SC: "Santa Catarina",
				SP: "São Paulo",
				SE: "Sergipe",
				TO: "Tocantins",
			},
		},
	},
});

api.setStrings(strings);

// tslint:disable-next-line:ban-ts-ignore
// @ts-ignore
export default strings;

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";
import DropDownCity from "../../../components/DropDownCity";

// MARK: Resources
import strings from "../../../resources/strings";

// MARK: Stores
import { RouterStore } from "mobx-react-router";
import UIStore from "../../../stores/_UIStore";
import AuthStore from "../../../stores/AuthStore";
import HomeStore from "../../../stores/HomeStore";

// MARK: Components
import { AppBar as MAppBar } from "@material-ui/core";
import { Toolbar as MToolbar } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// MARK: Material Icons
import { Loyalty as LoyaltyIcon } from "@material-ui/icons";
import { Menu as MMenuIcon } from "@material-ui/icons";
import { ChevronLeft as MChevronLeftIcon } from "@material-ui/icons";
import { Dashboard as MDashboardIcon } from "@material-ui/icons";
import { Face as MFaceIcon } from "@material-ui/icons";
import { KeyboardReturn as MKeyboardReturnIcon } from "@material-ui/icons";
import { Description as MDescriptionIcon } from "@material-ui/icons";
import { LocalOffer as MTagIcon } from "@material-ui/icons";
import { Style as MTicketsIcon } from "@material-ui/icons";
import { Notifications as NotificationImportant } from "@material-ui/icons";
import { CreditCard as MCreditCardIcon } from "@material-ui/icons";
import { Assignment as MAssignmentIcon } from "@material-ui/icons";
import { Ballot as MBallotIcon } from "@material-ui/icons";
import { AccountCircle as MAccountIcon } from "@material-ui/icons";
import { LibraryBooks as MBlogIcon } from "@material-ui/icons";
import { Photo as PhotoIcon} from "@material-ui/icons";
import { Favorite as MBFeedIcon} from "@material-ui/icons";
import { KeyboardArrowDown as MDropDownIcon } from "@material-ui/icons";

interface IProps extends IStores {
	backButton?: boolean;
}

interface IStores {
	routerStore: RouterStore;
	authStore: AuthStore;
	uiStore: UIStore;
	homeStore: HomeStore;
}

interface IState {
	open: boolean;
	teamOpen: boolean;
	anchorCity: any;
}

@inject("routerStore", "uiStore", "authStore", "homeStore")
@observer
export default class Navbar extends React.Component<IProps, IState> {
	public static defaultProps: IStores;

	public componentDidMount = () => {
		if (this.props.authStore.showIfUserIsAdmin()) {
			this.props.homeStore.getAvailableAdminFilterCities();
		}

		this.props.homeStore.selectAllCities();
	};

	private handleDrawerOpen = () => {
		this.setState({ open: true });
	}

	private handleDrawerClose = () => {
		this.setState({ open: false });
	}

	public onItemClick = (route: string) => {
		this.props.routerStore.push(route);
		this.handleDrawerClose();
	}

	constructor(props: IProps) {
		super(props);

		this.state = {
			open: false,
			teamOpen: false,
			anchorCity: null,
		};
	}

	// tslint:disable-next-line:max-func-body-length
	public render() {
		const { authStore, backButton, routerStore, homeStore } = this.props;

		return (
			<>
				<MAppBar className="appBar" position="fixed">
					<MToolbar className="appBarToolbar">
						<IconButton
							color="inherit"
							aria-label="Menu"
							onClick={() => !backButton ? this.handleDrawerOpen() : routerStore.goBack()}
						>
							{!backButton ?
								<MMenuIcon /> :
								<MChevronLeftIcon />
							}
						</IconButton>
						<div className="appToolBarAround">
							<img
								className="appBarToolbarImage"
								src={strings.images.logo.src}
								alt={strings.images.logo.alt}
							/>
							<div
								className="appToolBarDropdown"
								onClick={(e) => {
									this.setState({ anchorCity: e.currentTarget});
									homeStore.toggleCityMenu();
								}}
								role="button"
							>
								{strings.buttons.chooseCities}
								<MDropDownIcon />
							</div>
						</div>
					</MToolbar>
				</MAppBar>
				<MToolbar className="appBarPlaceholder"/>

				<Drawer
					className="sidedrawer"
					variant="persistent"
					anchor="left"
					open={this.state.open}
				>
					<div className="sidedrawerCloseButtonContainer">
						<IconButton onClick={this.handleDrawerClose}>
							<MChevronLeftIcon />
						</IconButton>
					</div>
					<List>
						{/* Pages */}
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard")}>
							<ListItemIcon>
								<MDashboardIcon />
							</ListItemIcon>
							<ListItemText primary={strings.pages.home} />
						</ListItem>
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard/users")}>
							<ListItemIcon>
								<MFaceIcon />
							</ListItemIcon>
							<ListItemText primary={strings.pages.users} />
						</ListItem>
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard/banners")}>
							<ListItemIcon>
								<PhotoIcon/>
							</ListItemIcon>
							<ListItemText primary={strings.pages.banners} />
						</ListItem>
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard/requests")}>
							<ListItemIcon>
								<MDescriptionIcon />
							</ListItemIcon>
							<ListItemText primary={strings.pages.requests} />
						</ListItem>
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard/sales")}>
							<ListItemIcon>
								<LoyaltyIcon />
							</ListItemIcon>
							<ListItemText primary={strings.pages.sales} />
						</ListItem>
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard/promotions")}>
							<ListItemIcon>
								<MTagIcon />
							</ListItemIcon>
							<ListItemText primary={strings.pages.promotions} />
						</ListItem>
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard/items")}>
							<ListItemIcon>
								<MTicketsIcon />
							</ListItemIcon>
							<ListItemText primary={strings.pages.items} />
						</ListItem>
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard/report")}>
							<ListItemIcon>
								<MAssignmentIcon />
							</ListItemIcon>
							<ListItemText primary={strings.pages.report} />
						</ListItem>
						{(authStore.showIfUserIsAdmin()) && (
							<ListItem button={true} onClick={() => this.onItemClick("/dashboard/adminUsers")}>
								<ListItemIcon>
									<MBallotIcon />
								</ListItemIcon>
								<ListItemText primary={strings.pages.register} />
							</ListItem>
						)}
						{(authStore.showIfUserIsAdmin()) && (
							<ListItem button={true} onClick={() => this.onItemClick("/dashboard/fines")}>
								<ListItemIcon>
									<MCreditCardIcon />
								</ListItemIcon>
								<ListItemText primary={strings.pages.fines} />
							</ListItem>
						)}
						<ListItem button={true} onClick={() => this.onItemClick("/dashboard/licensed")}>
							<ListItemIcon>
								<MAccountIcon />
							</ListItemIcon>
							<ListItemText primary={strings.pages.licensed} />
						</ListItem>
						{(authStore.showIfUserIsAdmin()) && (
							<ListItem button={true} onClick={() => this.onItemClick("/dashboard/blog")}>
								<ListItemIcon>
									<MBlogIcon />
								</ListItemIcon>
								<ListItemText primary={strings.pages.blog} />
							</ListItem>
						)}
						{(authStore.showIfUserIsAdmin()) && (
							<ListItem button={true} onClick={() => this.onItemClick("/dashboard/feed")}>
								<ListItemIcon>
									<MBFeedIcon />
								</ListItemIcon>
								<ListItemText primary={strings.pages.feed} />
							</ListItem>
						)}

						{/* Logout */}
						<ListItem button={true} onClick={() => authStore.logout()}>
							<ListItemIcon>
								<MKeyboardReturnIcon />
							</ListItemIcon>
							<ListItemText primary={strings.login.logout} />
						</ListItem>
					</List>
				</Drawer>

				<DropDownCity
					selectedCities={homeStore.selectedCities}
					isOpen={homeStore.openedCityMenu}
					onClose={homeStore.toggleCityMenu}
					anchorEl={this.state.anchorCity}
					cities={homeStore.cities}
					selectCity={homeStore.selectCity}
				/>
			</>
		);
	}
}

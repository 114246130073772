// MARK: React
import { Button } from "@material-ui/core";
// Mobx
import { observer } from "mobx-react";
import * as React from "react";
// Resources
import strings from "../../resources/strings";
import "./style.scss";

interface IProps {
	onSave: () => void;
	onCancel: () => void;
	loading: boolean;
}

@observer
export default class SaveAndCancelFormButtons extends React.Component<IProps> {
	public render() {
		const {loading} = this.props;
		return (
			<div className="saveAndCancelButtonsContainer">
				<div className="buttonSave">
					<Button
						onClick={this.props.onSave}
						disabled={loading}
					>
						{
							loading ?
								strings.common.createOrEdit.buttons.loading :
								strings.common.createOrEdit.buttons.save
						}
					</Button>
				</div>
				<div className="buttonCancel">
					<Button
						onClick={this.props.onCancel}
						disabled={loading}
					>
						{strings.common.createOrEdit.buttons.back}
					</Button>
				</div>
			</div>
		);
	}
}

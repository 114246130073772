// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: Stores
import BalanceStore from "../../stores/BalanceStore";

// MARK: Components
import DialogModified from "../DialogModified";
import InputText from "../InputText";

// MARK: Resources
import strings from "../../resources/strings";

interface IProps {
	balanceStore: BalanceStore;
}

@inject("balanceStore")
@observer
export default class TransferDialog extends React.Component<IProps> {
	public render() {
		const { balanceStore } = this.props;

		return balanceStore.dialogTransferIsOpen && (
			<DialogModified
				onDialogConfirm={balanceStore.onConfirmTransfer}
				clearDialog={balanceStore.toggleDialogTransfer}
				dialogTitle={strings.licensed.bankAccount.transferTitle}
				loading={balanceStore.balanceLoading}
				notCloseOnConfirmFunction={true}
			>
				<div className="transferContentContainer">
					<p>
						{strings.licensed.bankAccount.transferDetails}
					</p>
				</div>
			</DialogModified>
		);
	}
}

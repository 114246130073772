import * as React from "react";
import strings from "../../resources/strings";
import { DatePicker as MDatePicker } from "material-ui-pickers";
import { observer } from "mobx-react";
import "./style.scss";

interface IProps {
	label: string;
	name: string;
	value: Date | null;
	type?: string;
	minDate? : Date;
	format?: string;
	disabled?: boolean;
	style?: any;
	onChange: (date: Date) => void;
}

const DatePicker = observer((props: IProps) => {
	return (
		<MDatePicker
			InputProps={{
				className: "datePicker",
			}}
			keyboard={true}
			label={props.label}
			type={props.type}
			name={props.name}
			margin="normal"
			variant="outlined"
			value={props.value}
			disabled={props.disabled}
			invalidDateMessage={strings.datePicker.invalidDate}
			minDate={props.minDate}
			style={{ ...props.style, width: "100%" }}
			mask={(value) => {
				return value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [];
			}}
			format={props.format || strings.datePicker.format}
			onChange={props.onChange}
		/>
	);
});

export default DatePicker;

// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Components
import PageTable from "../../../../components/PageTable";
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Stores
import BannerStore from "../../../../stores/BannerStore";

// MARK: Resources
import strings from "../../../../resources/strings";

interface IProps {
	bannerStore: BannerStore;
}

@inject("bannerStore")
@observer
export default class BannersTable extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.bannerStore.getBanners(0);
	};

	public render() {
		const { bannerStore } = this.props;
		return (
			<DashboardLayout
				loading={bannerStore.loading}
			>
				<PageTable
					tableConfig={{
						title: strings.banners.title,
						rows: bannerStore.bannerTableRows,
						header: bannerStore.header,
						pageOffset: bannerStore.pageOffset,
						loading: bannerStore.loading,
						prevPage: bannerStore.previousPage,
						nextPage: bannerStore.nextPage,
						onDeleteClick: bannerStore.openDeleteBannerDialog,
						onAddClick: bannerStore.startBannerCreateOrEdit,
						onEditClick: bannerStore.startBannerCreateOrEdit,
					}}
				/>
			</DashboardLayout>
		);
	}
}

// MARK: React
import * as React from "react";

// Mobx
import { inject, observer } from "mobx-react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Pages
import BannersTable from "./BannersTable";
import BannerCreateOrEdit from "./BannerCreateOrEdit";
import DashboardLayout from "../../../layouts/DashboardLayout";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Banners extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render()  {
		return (
			<Switch>
				<Route path="/dashboard/banners/editor/:bannerId" component={BannerCreateOrEdit} />
				<Route path="/dashboard/banners/editor" component={BannerCreateOrEdit} />
				<Route path="/dashboard/banners" component={BannersTable} />
			</Switch>
		);
	}
}

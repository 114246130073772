// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, computed, observable } from "mobx";

// MARK: Stores
import VariableChangeHandler from "../../stores/_helpers/VariableChangeHandler";

export default class BannerQueryService extends VariableChangeHandler {
	@observable public search: string = "";

	// Filter
	@observable public itemOfferType: api.ItemOfferType = api.ItemOfferType.all;
	@observable public sizes: api.Size[] = [];
	@observable public orderBy: api.OrderBy = api.OrderBy.popular;
	@observable public occasions: api.Ocasion[] = [];
	@observable public gender: api.Gender[] = [];
	@observable public colors: api.Color[] = [];
	@observable public categories: api.Category[] = [];

	@computed
	public get query(): api.Query {
		return {
			search: this.search.length > 0 ? this.search : null,
			filter: {
				itemOfferType: this.itemOfferType,
				sizes: this.sizes,
				orderBy: this.orderBy,
				ocasions: this.occasions,
				gender: this.gender,
				colors: this.colors,
				categories: this.categories,
				distance: 50000,
			},
		};
	}

	@action
	public clear = () => {
		this.search = "";

		this.itemOfferType = api.ItemOfferType.all;
		this.sizes = [];
		this.orderBy = api.OrderBy.popular;
		this.occasions = [];
		this.gender = [];
		this.colors = [];
		this.categories = [];
	};

	@action
	public setFields = (search: string | null, filter: api.Filter) => {
		this.search = search || "";
		this.itemOfferType = filter.itemOfferType || api.ItemOfferType.all;
		this.sizes = filter.sizes;
		this.orderBy = filter.orderBy || api.OrderBy.popular;
		this.occasions = filter.ocasions;
		this.gender = filter.gender;
		this.colors = filter.colors;
		this.categories = filter.categories;
	};

	@action
	public toggleItemOfferType = (itemOfferType: api.ItemOfferType) => {
		this.itemOfferType = itemOfferType;
	};

	@action
	public toggleOrderBy = (orderBy: api.OrderBy) => {
		this.orderBy = orderBy;
	};

	// Occasion
	@observable public selectedOccasion: api.Ocasion = api.Ocasion.beach;

	@action
	public toggleOccasion = (occasion: api.Ocasion) => {
		this.selectedOccasion = occasion;
	};

	@action
	public addOccasion = () => {
		if (this.occasions.indexOf(this.selectedOccasion) === -1) {
			this.occasions.push(this.selectedOccasion);
			this.selectedOccasion = api.Ocasion.beach;
		}
	};

	@action
	public removeOccasion = (occasion: api.Ocasion) => {
		const index = this.occasions.indexOf(occasion);
		if (index >= 0) {
			this.occasions.splice(index, 1);
		}
	};

	// Size
	@observable public selectedSize: api.Size = api.Size.s33;

	@action
	public toggleSize = (size: api.Size) => {
		this.selectedSize = size;
	};

	@action
	public addSize = () => {
		if (this.sizes.indexOf(this.selectedSize) === -1) {
			this.sizes.push(this.selectedSize);
			this.selectedSize = api.Size.s33;
		}
	};

	@action
	public removeSize = (occasion: api.Size) => {
		const index = this.sizes.indexOf(occasion);
		if (index >= 0) {
			this.sizes.splice(index, 1);
		}
	};

	// Gender
	@observable public selectedGender: api.Gender = api.Gender.male;

	@action
	public toggleGender = (gender: api.Gender) => {
		this.selectedGender = gender;
	};

	@action
	public addGender = () => {
		if (this.gender.indexOf(this.selectedGender) === -1) {
			this.gender.push(this.selectedGender);
			this.selectedGender = api.Gender.male;
		}
	};

	@action
	public removeGender = (gender: api.Gender) => {
		const index = this.gender.indexOf(gender);
		if (index >= 0) {
			this.gender.splice(index, 1);
		}
	};

	// Color
	@observable public selectedColor: api.Color = api.Color.stamped;

	@action
	public toggleColor = (color: api.Color) => {
		this.selectedColor = color;
	};

	@action
	public addColor = () => {
		if (this.colors.indexOf(this.selectedColor) === -1) {
			this.colors.push(this.selectedColor);
			this.selectedColor = api.Color.stamped;
		}
	};

	@action
	public removeColor = (color: api.Color) => {
		const index = this.colors.indexOf(color);
		if (index >= 0) {
			this.colors.splice(index, 1);
		}
	};

	// Category
	@observable public selectedCategory: api.Category = api.Category.conjunct;

	@action
	public toggleCategory = (category: api.Category) => {
		this.selectedCategory = category;
	};

	@action
	public addCategory = () => {
		if (this.categories.indexOf(this.selectedCategory) === -1) {
			this.categories.push(this.selectedCategory);
			this.selectedCategory = api.Category.conjunct;
		}
	};

	@action
	public removeCategory = (category: api.Category) => {
		const index = this.categories.indexOf(category);
		if (index >= 0) {
			this.categories.splice(index, 1);
		}
	};
}

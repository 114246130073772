// MARK: React
import * as React from "react";
import "./style.scss";
// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Resources
import { match as Match } from "react-router";
import strings from "../../../../resources/strings";

// MARK: Components
import UserCard from "../../../../components/UserCard";
import ItemDetail from "../../../../components/ItemDetail";
import Button from "../../../../components/Button";

// MARK: Stores
import FeedStore from "src/stores/FeedStore";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

interface IProps extends IStores {}

interface IStores {
	feedStore: FeedStore;
	match: Match<{ postId: string }>;
}

@inject("feedStore")
@observer
export default class FeedPostDetails extends React.Component<IProps> {
	public static defaultProps: IStores;

	public componentDidMount = async () => {
		const id = this.props.match.params.postId;
		await this.props.feedStore.getOnePost(id);
	};

	public render() {
		const {feedStore} = this.props;
		return (
			<>
				{
					feedStore.selectedPost && (
						<DashboardLayout backButton={true} loading={feedStore.loading}>
							<div className="itemDetailsContainer">
								<div className="itemDetailsWrapper">
									<div className="itemDetailsTitle">
										<h2>{strings.feed.titleDetails}</h2>
									</div>
									<div className="itemRow">
										<div className="itemTreeColumns image">
										{feedStore.user && (
												<>
													<ItemDetail
														title={strings.feed.ownerPost}
														info={feedStore.user.name}
													/>
													<ItemDetail
														title={strings.feed.nickname}
														info={feedStore.user.nick}
													/>
												</>
											)}
											<ItemDetail
												title={strings.feed.table.header.text}
												info={feedStore.selectedPost.text}
											/>
											<div className="itemContainerImages">
												<div className="itemDetailsImage">
												{feedStore.selectedPost.image && (
													<img
														className="itemImage"
														src={feedStore.selectedPost.image?.url}
														alt={"Image Post"}
													/>
												)}
												</div>
											</div>

												<Button
													style={{ marginTop: 20 }}
													onClick={() => feedStore.openDeletePostDialog(this.props.match.params.postId)}
												>
														{strings.feed.deletePostDialog.title}
												</Button>
										</div>
									</div>
								</div>
							</div>
						</DashboardLayout>
					)
				}
			</>
		);
	}
}

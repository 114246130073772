// MARK: React
import * as React from "react";

// MARK: Styles
import "react-quill/dist/quill.snow.css";
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Components
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SaveAndCancelFormButtons from "../../../../components/SaveAndCancelFormButtons";
import TextField from "../../../../components/TextField";
import ImageCase from "../../../../components/ImageCase";

// MARK: Stores
import BlogStore from "../../../../stores/BlogStore";
import { routerStore } from "../../../../stores/_rootStore";

// MARK: Resources
import strings from "../../../../resources/strings";
import { match as Match } from "react-router";

// MARK: Libraries
import ReactQuill from "react-quill";

interface IProps {
	blogStore: BlogStore;
	match: Match<{ postId?: string }>;
}

@inject("blogStore")
@observer
export default class BlogCreateOrEdit extends React.Component<IProps> {
	public componentDidMount = async () => {
		const postId = this.props.match.params.postId;
		if (postId) {
			await this.props.blogStore.getPost(postId);
		}
	};

	public render() {
		const { blogStore } = this.props;
		return (
			<DashboardLayout>
				<div className="offerCreateOrEdit">
					{!blogStore.blogLoading && (
						<div className="offerCreateOrEditWrapper">
							<h2 className="createOrEditTitle">
								{
									blogStore.selectedPost ?
										strings.blog.createOrEdit.title.edit :
										strings.blog.createOrEdit.title.new
								}
							</h2>
							<div className="offerFormContainer">
								<ImageCase
									key="AboutImageCase"
									title={strings.blog.table.header.image}
									onAddItem={blogStore.imageService.selectSingleImage}
									onRemoveItem={blogStore.imageService.removeImage}
									onReloadItem={blogStore.imageService.reloadImage}
									imageUploaders={blogStore.imageService.singleImageUploader}
									loading={blogStore.loading}
								/>
								<TextField
									label={strings.blog.table.header.title}
									name="title"
									value={blogStore.title}
									onChange={(e) => blogStore.setTitle(e.target.value)}
									disabled={blogStore.blogLoading}
									required={true}
								/>
								<TextField
									label={strings.blog.table.header.slug}
									name="slug"
									value={blogStore.slugLoading ? strings.blog.slugLoading : blogStore.slug}
									onChange={blogStore.handleValueChange}
									disabled={blogStore.loading}
									required={true}
								/>
								<ReactQuill
									modules={blogStore.editorModules}
									value={blogStore.text}
									ref={blogStore.setQuillRef}
									onChange={blogStore.handleEditorChange}
								/>
							</div>
							<SaveAndCancelFormButtons
								onSave={blogStore.createOrEditBlog}
								onCancel={() => routerStore.push("/dashboard/blog")}
								loading={blogStore.blogLoading}
							/>
						</div>
					)}
				</div>
			</DashboardLayout>
		);
	}
}

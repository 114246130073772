// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Icons
import { default as MDeleteIcon } from "@material-ui/icons/Delete";
import { default as Chip } from "@material-ui/core/Chip";

interface IProps {
	array: any[];
	buttonLabel: string;
	onAdd: () => void;
	onRemove: (element: any) => void;
	translator?: (element: any) => string;
}

@observer
export default class ArrayField extends React.Component<IProps> {
	public render() {
		const { translator } = this.props;
		return (
			<div className="arrayField">
				<div className="addField">
					<div className="child">
						{this.props.children}
					</div>
					<button
						className="addButton"
						onClick={this.props.onAdd}
					>
						{this.props.buttonLabel}
					</button>
				</div>
				{ this.props.array.length > 0 && (
					<div className="arrayData">
						{this.props.array.map((element, i) => (
							<Chip
								key={`${element}-${i}`}
								className="metaTagChip"
								label={translator ? translator(element) : element}
								onDelete={() => this.props.onRemove(element)}
							/>
						))}
					</div>
				)}
			</div>
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: Material-UI
import { TableCell as MTableCell } from "@material-ui/core";
import { TableHead as MTableHead } from "@material-ui/core";
import { TableRow as MTableRow } from "@material-ui/core";
import { TableSortLabel as MTableSortLabel } from "@material-ui/core";

// MARK: Resources
import strings from "../../../resources/strings";

// MARK: Services
import SortTableService from "../../../services/SortTableService";

interface IProps {
	header: string[];
	hasActions: boolean;
	hasSelection?: boolean;
	hasActive: boolean;
	sortService?: SortTableService<any>;
	densePadding: boolean;
}

@observer
export default class TableHeader extends React.Component<IProps> {
	public render() {
		const { header, hasActions, hasSelection, hasActive, sortService } = this.props;
		return (
			<MTableHead>
				<MTableRow>
					{
						hasSelection && (
							<MTableCell
								align="center"
							>
								{strings.components.table.select}
							</MTableCell>
						)
					}
					{
						hasActions && (
							<MTableCell
								key={"header_cell_actions"}
								align="center"
							>
								{strings.components.table.actions}
							</MTableCell>
						)}
					{
						hasActive && (
							<MTableCell
								key={"header_cell_active"}
								align="center"
							>
								{strings.components.table.active}
							</MTableCell>
						)}
					{
						header.map((headerItem, index) => {
							return (sortService && sortService.allDisplayableValues().includes(headerItem)) ? (
								<MTableCell
									key={"header_cell_" + index}
									align="center"
								>
									<MTableSortLabel
										direction={
											sortService.selectedFieldOrderBy === sortService.valueFromTranslated(headerItem) ?
												sortService.currentSortOrder || "desc" :
												"desc"
										}
										active={sortService.selectedFieldOrderBy === sortService.valueFromTranslated(headerItem)}
										onClick={() => sortService.onSortClick(headerItem)}
									>
										{headerItem}
									</MTableSortLabel>
								</MTableCell>
							) : (
								<MTableCell
									key={"header_cell_" + index}
									align="center"
								>
									{headerItem}
								</MTableCell>
							);
						})
					}
				</MTableRow>
			</MTableHead>
		);
	}
}

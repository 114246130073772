// MARK: React
import * as React from "react";

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { inject, observer } from "mobx-react";
import ArrayField from "../../../../../components/ArrayField";
import ListSelect from "../../../../../components/ListSelect";
import SelectEnum from "../../../../../components/SelectEnum";

// MARK: Style
import "./style.scss";

// MARK: Components
import TextField from "../../../../../components/TextField";

// MARK: Resources
import strings from "../../../../../resources/strings";

// MARK: Stores
import BannerStore from "../../../../../stores/BannerStore";

interface IProps {
	bannerStore: BannerStore;
}

@observer
export default class BannerContentQuery extends React.Component<IProps> {
	public render() {
		const { bannerStore } = this.props;
		return (
			<>
			<h2 className="bannerSectionTitle">{strings.banners.component.bannerContentQuery.title}</h2>

				<TextField
					label={strings.banners.content.search}
					name="search"
					value={bannerStore.bannerQueryService.search}
					onChange={bannerStore.bannerQueryService.handleValueChange}
					disabled={bannerStore.loading}
				/>

				<div className="rowTwoEnum">
					<div className="enum">
						<SelectEnum
							label={strings.banners.filter.orderBy}
							enumValues={api.allValuesOrderBy()}
							enumTranslator={api.translateOrderBy}
							onChange={bannerStore.bannerQueryService.toggleOrderBy}
							selectedEnum={bannerStore.bannerQueryService.orderBy}
						/>
					</div>
					<div className="enum">
						<SelectEnum
							label={strings.banners.filter.itemOfferType}
							enumValues={api.allValuesItemOfferType()}
							enumTranslator={api.translateItemOfferType}
							onChange={bannerStore.bannerQueryService.toggleItemOfferType}
							selectedEnum={bannerStore.bannerQueryService.itemOfferType}
						/>
					</div>
				</div>
				<ArrayField
					array={bannerStore.bannerQueryService.occasions}
					buttonLabel={strings.common.add}
					onAdd={bannerStore.bannerQueryService.addOccasion}
					onRemove={bannerStore.bannerQueryService.removeOccasion}
					translator={api.translateOcasion}
				>
					<ListSelect
						label={strings.banners.filter.occasions}
						name="permission"
						value={bannerStore.bannerQueryService.selectedOccasion}
						handleChange={bannerStore.bannerQueryService.toggleOccasion}
						items={api.allValuesOcasion()}
						translator={api.translateOcasion}
					/>
				</ArrayField>

				<ArrayField
					array={bannerStore.bannerQueryService.sizes}
					buttonLabel={strings.common.add}
					onAdd={bannerStore.bannerQueryService.addSize}
					onRemove={bannerStore.bannerQueryService.removeSize}
					translator={api.translateSize}
				>
					<ListSelect
						label={strings.banners.filter.sizes}
						name="permission"
						value={bannerStore.bannerQueryService.selectedSize}
						handleChange={bannerStore.bannerQueryService.toggleSize}
						items={api.allValuesSize()}
						translator={api.translateSize}
					/>
				</ArrayField>

				<ArrayField
					array={bannerStore.bannerQueryService.gender}
					buttonLabel={strings.common.add}
					onAdd={bannerStore.bannerQueryService.addGender}
					onRemove={bannerStore.bannerQueryService.removeGender}
					translator={api.translateGender}
				>
					<ListSelect
						label={strings.banners.filter.gender}
						name="permission"
						value={bannerStore.bannerQueryService.selectedGender}
						handleChange={bannerStore.bannerQueryService.toggleGender}
						items={api.allValuesGender()}
						translator={api.translateGender}
					/>
				</ArrayField>

				<ArrayField
					array={bannerStore.bannerQueryService.categories}
					buttonLabel={strings.common.add}
					onAdd={bannerStore.bannerQueryService.addCategory}
					onRemove={bannerStore.bannerQueryService.removeCategory}
					translator={api.translateCategory}
				>
					<ListSelect
						label={strings.banners.filter.category}
						name="permission"
						value={bannerStore.bannerQueryService.selectedCategory}
						handleChange={bannerStore.bannerQueryService.toggleCategory}
						items={api.allValuesCategory()}
						translator={api.translateCategory}
					/>
				</ArrayField>
				<ArrayField
					array={bannerStore.bannerQueryService.colors}
					buttonLabel={strings.common.add}
					onAdd={bannerStore.bannerQueryService.addColor}
					onRemove={bannerStore.bannerQueryService.removeColor}
					translator={api.translateColor}
				>
					<ListSelect
						label={strings.banners.filter.colors}
						name="permission"
						value={bannerStore.bannerQueryService.selectedColor}
						handleChange={bannerStore.bannerQueryService.toggleColor}
						items={api.allValuesColor()}
						translator={api.translateColor}
					/>
				</ArrayField>
			</>
		);
	}
}

// MARK: React
import { translateGender, User } from "@startapp/loc-admin-api";
import moment from "moment";
import * as React from "react";
import "./style.scss";
import strings from "../../resources/strings";
import ItemDetail from "../ItemDetail";

interface IProps {
	user: User;
	title: string;
}

export default class UserCard extends React.Component<IProps> {
	public render() {
		return (
			<>
				<div className="userDetailsTitle"><h2>{this.props.title}</h2></div>
				<div className="userDataContainer">
					<div className="userContainerImage">
						<div className="userDetailsAvatar">
							<img
								className="userAvatar"
								src={
									this.props.user.avatar ? this.props.user.avatar.url :
										"/userPlaceholder.svg"}
								alt={this.props.user.name}
							/>
						</div>
					</div>
					<div className="userContainerData">
						<div className="userRow">
							<ItemDetail
								title={strings.users.table.header.id}
								info={this.props.user.id}
							/>
						</div>
						<div className="userRow">
							<ItemDetail
								title={strings.users.table.header.cpf}
								info={this.props.user.cpf}
								className="userTwoColumns"
							/>
							<ItemDetail
								title={strings.users.table.header.nick}
								info={this.props.user.nick}
								className="userTwoColumns"
							/>
						</div>
						<div className="userRow ">
							<ItemDetail
								title={strings.users.table.header.name}
								info={this.props.user.name}
								className="userTwoColumns"
							/>
							<ItemDetail
								title={strings.users.table.header.gender}
								info={
									this.props.user.gender ? translateGender(this.props.user.gender) :
										strings.users.table.header.genderUndefined
								}
								className="userTwoColumns"
							/>
						</div>
						<div className="userRow">
							<ItemDetail
								title={strings.users.table.header.birthday}
								info={
									this.props.user.birthday ? moment(this.props.user.birthday).format(strings.moment.date) :
										""
								}
								className="userTwoColumns"
							/>
							<ItemDetail
								title={strings.users.table.header.createdAt}
								info={moment(this.props.user.createdAt).format(strings.moment.date)}
								className="userTwoColumns"
							/>
						</div>
						<div className="userRow">
							<ItemDetail
								title={strings.users.table.header.email}
								info={this.props.user.email}
								className="userTwoColumns"
							/>
							<ItemDetail
								title={strings.users.table.header.phone}
								info={`+${this.props.user.phone.ddi} (${this.props.user.phone.ddd}) ${this.props.user.phone.number}`}
								className="userTwoColumns"
							/>
						</div>
						<div className="userRow">
							<ItemDetail
								title={strings.users.table.header.type}
								info={this.props.user.type}
								className="userTwoColumns"
							/>
							{this.props.user.blockedUntil && (
								<ItemDetail
									title={strings.users.table.header.blockedUntil}
									info={moment(this.props.user.blockedUntil).format(strings.moment.date)}
									className="userTwoColumns"
								/>
							)}
						</div>
						<div className="userRow">
							<ItemDetail
								title={strings.users.table.header.verifiedDocument}
								info={this.props.user.documentsVerified ? "Sim" : "Não"}
								className="userTwoColumns"
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}

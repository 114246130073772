import * as React from "react";
import "./style.scss";

// Mobx
import { observer } from "mobx-react";

// Material-UI Components
import { Button as MButton, FormControlLabel, Paper as MPaper } from "@material-ui/core";
import { Typography as MTypography } from "@material-ui/core";
import { IconButton as MIconButton } from "@material-ui/core";
import { Switch } from "@material-ui/core";

// Material-UI Icons
import { ChevronLeft as MChevronLeftIcon } from "@material-ui/icons";
import { ChevronRight as MChevronRightIcon } from "@material-ui/icons";
import { Add as MAddIcon } from "@material-ui/icons";

// MARK: Resources
import strings from "../../../resources/strings";

interface IProps {
	pageOffset: number;
	onNextClick?: () => void;
	onPrevClick?: () => void;
	onAddClick?: () => void;
	numberOfColumns: number;
	densePadding: boolean;
	toggleDensePadding: () => void;
}

@observer
export default class TableFooter extends React.Component<IProps> {
	public render() {
		const { pageOffset, onNextClick, onPrevClick, onAddClick } = this.props;

		return (
			<div className="footerDiv">
				{onAddClick && (
					<MButton
						style={{ marginLeft: 12 }}
						onClick={() => onAddClick()}
						variant="contained"
					>
						<MAddIcon />
						{strings.components.table.create}
					</MButton>
				)}
					<FormControlLabel
						control={<Switch checked={this.props.densePadding} onChange={this.props.toggleDensePadding} />}
						label={strings.components.table.densePadding}
					/>
				<div className="paginationControl">
					<MIconButton
						onClick={() => {
							if (onPrevClick) {
								onPrevClick();
							}
						}}
					>
						<MChevronLeftIcon />
					</MIconButton>
					<MTypography variant="subtitle2">{pageOffset}</MTypography>
					<MIconButton
						onClick={() => {
							if (onNextClick) {
								onNextClick();
							}
						}}
					>
						<MChevronRightIcon />
					</MIconButton>
				</div>
			</div>
		);
	}
}

// MARK: React
import * as React from "react";

// MARK: Styles
import "./style.scss";

// MARK: Components
import {
	FormControl as MFormControl,
	InputLabel as MInputLabel,
	OutlinedInput as MOutlinedInput, Select as MSelect,
} from "@material-ui/core";

interface IProps {
	label: string;
	value?: any;
	handleChange: (value: any) => void;
	itemIdentification: (value: any) => string;
	displayableValue?: (value: any) => string;
	items: any[];
	emptyItemsMessage?: string;
	labelSpacing?: number;
}

interface IState {
	isOpen: boolean;
}

export default class SelectList extends React.Component<IProps, IState> {
	public myButton: any;
	constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: false,
		};

		this.handleOpen = this.handleOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	public handleClose() {
		this.setState({isOpen: false});
		this.myButton = null;
	}

	public handleOpen(e: any) {
		this.setState({isOpen: true});
		this.myButton = e.target;
	}

	public render = () => {
		const {items, label, value, handleChange, displayableValue, emptyItemsMessage, itemIdentification, labelSpacing} = this.props;
		console.log(this.props);
		return (
			<div className="selectOptionsFormControl">
				<MFormControl variant="outlined" style={{width: "100%"}}>
					<MInputLabel
						htmlFor="outlined-age-native-simple"
						shrink={true}
					>
						{label}
					</MInputLabel>
					<MSelect
						native={true}
						onChange={(event) => handleChange(event.target.value)}
						input={
							<MOutlinedInput name="age" labelWidth={label.length * (labelSpacing || 9)} id="outlined-age -native-simple" notched={true}/>
						}
						style={{width: "100%"}}
						className="selectOptionsSelect"
						disabled={items.length === 0}
					>
						{
							items.length === 0 ?
								(
									<option
										aria-selected={true}
										value={emptyItemsMessage}
									>
										{emptyItemsMessage}
									</option>
								) :
									items.map((item) => {
										return (
											<option
												value={itemIdentification(item)}
												key={itemIdentification(item)}
												aria-selected={
													value && (itemIdentification(value) === itemIdentification(item))
												}
												selected={
													value && (itemIdentification(value) === itemIdentification(item))
												}
											>
												{
													displayableValue ?
														displayableValue(item) :
														item
												}
											</option>
										);
									})
						}
					</MSelect>
				</MFormControl>
			</div>
		);
	}
}

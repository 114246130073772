// MARK: React
import * as React from "react";
import "./style.scss";

import { FormControl as MFormControl } from "@material-ui/core";
import { InputLabel as MInputLabel } from "@material-ui/core";
import { Select as MSelect } from "@material-ui/core";
import { OutlinedInput as MOutlinedInput } from "@material-ui/core";

interface IProps {
	label: string;
	enumValues: string[];
	enumTranslator: (value: string) => string;
	onChange: (value: string) => void;
	selectedEnum: string | null;
	labelSpacing?: number;
}

export default class SelectEnum extends React.Component<IProps> {
	public render() {
		const { label, labelSpacing, onChange, enumValues, enumTranslator, selectedEnum } = this.props;
		return (
			<div className="selectOptionsFormControl">
				<MFormControl  variant="outlined" style={{ width: "100%" }}>
					<MInputLabel
						htmlFor="outlined-age-native-simple"
						shrink={true}
					>
						{label}
					</MInputLabel>
					<MSelect
						native={true}
						onChange={(event) => onChange(event.target.value as string)}
						input={
							<MOutlinedInput  name="age" labelWidth={label.length * (labelSpacing || 9)} id="outlined-age -native-simple" notched={true} />
						}
						style={{ width: "100%" }}
						className="selectOptionsSelect"
					>
						{
							enumValues.map((value) => {
								return (
									// tslint:disable-next-line: react-a11y-role-has-required-aria-props
									<option
										value={value}
										key={value}
										selected={selectedEnum === value}
									>
										{enumTranslator(value)}
									</option>
								);
							})
						}
					</MSelect>
				</MFormControl>
			</div>
		);
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, computed, observable } from "mobx";

// MARK: Resources
import { getBankNamesAndCode } from "../../resources/banksNamesAndCodes";

// MARK: Stores
import VariableChangeHandler from "../../stores/_helpers/VariableChangeHandler";

export default class BankAccountService extends VariableChangeHandler {
	// Controls
	@observable public customBankNameAndCode: boolean = false;
	////// Bank Account
	@observable public bankName: string = "";
	@observable public bankCode: string = "";
	@observable public agency: string = "";
	@observable public agencyDv: string = "";
	@observable public account: string = "";
	@observable public accountDv: string = "";
	@observable public documentNumber: string = "";
	@observable public bankAccountType: api.BankAccountType = api.BankAccountType.contaCorrente;
	@observable public legalName: string = "";

	@computed
	public get getBankAccount(): api.BankAccount {
		return {
			documentNumber: this.documentNumber,
			bankName: this.bankName,
			bankCode: this.bankCode,
			agency: this.agency,
			agencyDv: this.agencyDv,
			account: this.account,
			accountDv: this.accountDv,
			type: this.bankAccountType,
			legalName: this.legalName,
		};
	}

	@computed
	public get selectedBankAndCode() {
		const bankCode = (this.customBankNameAndCode) ? "000" : this.bankCode;
		return {
			code: bankCode,
		};
	}

	@action
	public toggleBankAccountType = (bankAccountType: api.BankAccountType) => {
		this.bankAccountType = bankAccountType;
	};

	@action
	public toggleBank = (bankCode: string) => {
		const bankNameAndCode = getBankNamesAndCode().find((bank) => bank.code === bankCode);
		if (bankNameAndCode) {
			if (bankCode === "000") {
				this.customBankNameAndCode = true;
				this.bankName = "";
				this.bankCode = "";
			} else {
				this.customBankNameAndCode = false;
				this.bankName = bankNameAndCode.name;
				this.bankCode = bankNameAndCode.code;
			}
		}
	};

	@action
	public setBankAccount = (bankAccount: api.BankAccount) => {
		const customBank = !!getBankNamesAndCode().find((bank) => bank.code === bankAccount.bankCode);
		if (!customBank) {
			this.customBankNameAndCode = true;
		}
		this.bankName = bankAccount.bankName;
		this.bankCode = bankAccount.bankCode;
		this.agency = bankAccount.agency;
		this.agencyDv = bankAccount.agencyDv || "";
		this.account = bankAccount.account;
		this.accountDv = bankAccount.accountDv;
		this.documentNumber = bankAccount.documentNumber;
		this.bankAccountType = bankAccount.type;
		this.legalName = bankAccount.legalName;
	};

	@action
	public clear = () => {
		this.bankName = getBankNamesAndCode()[0].name;
		this.bankCode = getBankNamesAndCode()[0].code;
		this.agency = "";
		this.agencyDv = "";
		this.account = "";
		this.accountDv = "";
		this.documentNumber = "";
		this.bankAccountType = api.BankAccountType.contaCorrente;
		this.legalName = "";
	}
}

// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Pages
import ItemsTable from "./ItemsTable";
import ItemDetails from "./ItemDetails";
import ItemCreateOrEdit from "./ItemCreateOrEdit";

interface IProps extends IStores {}
interface IStores {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Items extends React.Component<IProps> {
	public static defaultProps: IStores;

	public render() {
		return (
			<Switch>
				<Route exact={true} path="/dashboard/items" component={ItemsTable} />
				<Route path="/dashboard/items/editor/:itemId" component={ItemCreateOrEdit} />
				<Route path="/dashboard/items/:itemId" component={ItemDetails} />
			</Switch>
		);
	}
}

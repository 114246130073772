// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Components
import { Dialog as MDialog } from "@material-ui/core";
import { DialogActions as MDialogActions } from "@material-ui/core";
import { DialogContent as MDialogContent } from "@material-ui/core";
import { DialogContentText as MDialogContentText } from "@material-ui/core";
import { DialogTitle as MDialogTitle } from "@material-ui/core";
import { Button as MButton } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

// MARK: Icons
import { Close as MCloseIcon } from "@material-ui/icons";

interface IProps {
	onDialogConfirm: (() => void) | null;
	clearDialog: () => void | null;
	isOpen?: boolean;
	dialogMessage?: string | null;
	dialogTitle?: string | null;
	noActions?: boolean;
}

export default class Dialog extends React.Component<IProps> {
	private onConfirmClick = () => {
		const { onDialogConfirm } = this.props;
		if (onDialogConfirm) {
			onDialogConfirm();
		}
	};

	public render() {
		const { clearDialog, isOpen, dialogMessage, dialogTitle, noActions} = this.props;

		return (
			<MDialog
				open={isOpen ? isOpen : false}
				onClose={clearDialog}
			>
				<div className="header">
					{dialogTitle && <MDialogTitle>{dialogTitle}</MDialogTitle>}
					{noActions && (
						<IconButton onClick={() => clearDialog()}>
							<MCloseIcon/>
						</IconButton>
					)}
				</div>
				<MDialogContent className="content">
					{dialogMessage && (
						<MDialogContentText>
							{dialogMessage}
						</MDialogContentText>
					)}
					{this.props.children}
				</MDialogContent>
				{
					!noActions && (
						<MDialogActions>
							<MButton
								onClick={clearDialog}
								variant="outlined"
							>
								{strings.common.cancel}
							</MButton>
							<MButton
								onClick={this.onConfirmClick}
								variant="contained"
								autoFocus={true}
							>
								{strings.common.confirm}
							</MButton>
						</MDialogActions>
					)
				}
			</MDialog>
		);
	}
}

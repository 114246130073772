// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, observable, computed } from "mobx";

export default class FilterPeriodService {
	private readonly onFilter: () => Promise<void>;

	@observable public periodStartDate: Date | null = null;
	@observable public periodEndDate: Date | null = null;

	constructor(onFilter: () => Promise<void>) {
		this.onFilter = onFilter;
	}

	@action
	public onPeriodStartDateChange = async (date: Date | null) => {
		this.periodStartDate = date;

		if (!this.periodEndDate && date) {
			this.periodEndDate = date;
		}

		await this.onFilter();
	};

	@action
	public onPeriodEndDateChange = async (date: Date | null) => {
		this.periodEndDate = date;

		if (!this.periodStartDate && date) {
			this.periodStartDate = date;
		}

		await this.onFilter();
	};

	@action
	public clear = () => {
		this.periodStartDate = null;
		this.periodEndDate = null;
	};

	@computed
	public get period() : api.FilterPeriod | null {
		if (!this.periodStartDate && !this.periodEndDate) {
			return null;
		}

		return {
			startDate: this.periodStartDate,
			endDate: this.periodEndDate,
		};
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, observable } from "mobx";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, rootStore } from "../_rootStore";
import strings from "../../resources/strings";

export default class BalanceStore extends VariableChangeHandler {
	@observable public balanceLoading: boolean = false;
	@observable public balance: api.BalanceFunds | null = null;

	@action
	public getBalance = async () => {
		if (this.balanceLoading) {
			return;
		}

		this.balanceLoading = true;

		try {
			this.balance = await api.getBalance();

		} catch (e) {
			if (e.type !== "RecipientIdDoesntExist") {
				rootStore.uiStore.showErrorSnackbar(e);
			}
		} finally {
			this.balanceLoading = false;
		}
	};

	// Dialog
	@observable public dialogTransferIsOpen: boolean = false;

	@action
	public toggleDialogTransfer = () => {
		this.dialogTransferIsOpen = !this.dialogTransferIsOpen;
	};

	@action
	public onConfirmTransfer = async () => {
		if (!this.balance) {
			await this.getBalance();
		}

		try {
			if (this.balance) {
				this.balance = await api.withDraw(this.balance.available.amount);
				this.dialogTransferIsOpen = false;
				uiStore.showSnackbar(strings.licensed.bankAccount.amountTransferSuccess);
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.balanceLoading = false;
		}
	}
}

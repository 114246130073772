// MARK: mobx
import { action, observable, computed } from "mobx";

// MARK: Api
import * as api from "@startapp/loc-admin-api";

// MARK: Components
import { IRowItem, ColumnType } from "../../components/Table/TableRow";

// MARK: Resources
import VariableChangeHandler from "../../resources/VariableChangeHandler";
import strings from "../../resources/strings";
import BannerGroupService from "../../services/BannerGroupService";
import BannerQueryService from "../../services/BannerQueryService";

// MARK: Stores
import { uiStore, routerStore } from "../_rootStore";
import ImageService from "../../services/ImageService";

export default class BannerStore extends VariableChangeHandler {
	// Services
	public imageService: ImageService = new ImageService();
	public bannerQueryService: BannerQueryService = new BannerQueryService();
	public bannerGroupService: BannerGroupService = new BannerGroupService();

	// Controls
	@observable public loading: boolean = false;
	@observable public pageOffset: number = 0;

	@observable public selectedBanner: api.Banner | null = null;
	@observable public banners: api.Banner[] = [];

	// Variables
	@observable public nameBanner: string = "";
	@observable public type: api.BannerType;
	@observable public contentType: api.BannerContentType;
	@observable public slug: string = "";

	@observable public contentUrl: string = "";

	@computed
	public get header() {
		const header = strings.common.createOrEdit.fields;
		return [
			header.image,
			header.name,
			header.contentType,
		];
	}

	private static formatBanner(banner: api.Banner): IRowItem {
		return {
			id: banner.id,
			name: banner.title,
			columns: [
				{
					value: banner.image.url,
					type: ColumnType.avatar,
				},
				{
					value: banner.title,
				},
				{
					value: banner.contentType ? api.translateBannerContentType(banner.contentType) : strings.common.default,
				},
			],
		};
	}

	@computed
	public get bannerTableRows(): IRowItem[] {
		return this.banners.map(BannerStore.formatBanner);
	}

	@action
	public getBanners = async (pageOffset?: number) => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		if (!pageOffset) {
			pageOffset = 0;
		}

		try {
			this.banners = await api.getBanners(pageOffset);
			this.pageOffset = pageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}

	}

	@action
	public getBanner = async (bannerId: string) => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			this.selectedBanner = await api.getBanner(bannerId);
			this.setEditorFields(this.selectedBanner);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	@action
	public nextPage = async () => {
		await this.getBanners(this.pageOffset + 1);
	};

	@action
	public previousPage = async () => {
		if (this.pageOffset > 0) {
			await this.getBanners(this.pageOffset - 1);
		}
	};

	@action
	public deleteBanner = async (bannerId: string) => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			await api.deleteBanner(bannerId);
			this.banners = this.banners.filter((banner) => bannerId !== banner.id);
			routerStore.replace(`/dashboard/banners`);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	@action
	public openDeleteBannerDialog = async (bannerId: string, bannerName: string) => {
		uiStore.showDialog({
			title: strings.common.createOrEdit.deleteDialog.title,
			message: `${strings.common.createOrEdit.deleteDialog.message}: ${bannerName}?`,
		}, () => this.deleteBanner(bannerId));
	};

	@action
	public clear = () => {
		this.imageService.clear();
		this.nameBanner = "";
		this.type = api.BannerType.banner;
		this.slug = "";
		this.contentUrl = "";
		this.bannerQueryService.clear();
	};

	@action
	public setEditorFields = (banner: api.Banner) => {
		if (banner.contentType === api.BannerContentType.query && banner.content.query) {
			this.bannerQueryService.setFields(banner.content.query.search, banner.content.query.filter);
		}

		if (banner.contentType === api.BannerContentType.url && banner.content.url) {
			this.contentUrl = banner.content.url;
		}

		if (banner.contentType === api.BannerContentType.group && banner.content.group) {
			this.bannerGroupService.setFields(banner);
		}

		this.contentType = banner.contentType || api.BannerContentType.url;
		this.imageService.setSingleImage(banner.image);
		this.nameBanner = banner.title;
		this.type = banner.type;
		this.slug = banner.slug;
	};

	@action
	public createOrEditBanner = async () => {
		if (this.loading) {
			return;
		}

		this.loading = true;

		try {
			if (this.imageService.singleImageUploader && this.imageService.singleImageUploader.uploadedUncertainImage) {
				if (this.selectedBanner) {
					const bannerId = this.selectedBanner.id;
					const editedBanner: api.EditBanner = {
						image: this.imageService.singleImageUploader.uploadedUncertainImage,
						url: null,
						title: this.nameBanner,
						mobileImage: null,
						slug: this.slug,
						content: {
							query: this.contentType === api.BannerContentType.query ? this.bannerQueryService.query : null,
							group: this.contentType === api.BannerContentType.group ? this.bannerGroupService.group : null,
							url:  this.contentType === api.BannerContentType.url ? this.contentUrl : null,

						},
						innerBanner: this.contentType === api.BannerContentType.group ? this.bannerGroupService.innerBanner : null,
						contentType: (this.contentType === "null" as api.BannerContentType) ? null : this.contentType,
						type: this.type,
					};
					this.selectedBanner = await api.editBanner(bannerId, editedBanner);
					uiStore.showSnackbar(strings.banners.editSuccessful);
					routerStore.push("/dashboard/banners");

				} else {
					const newBanner: api.NewBanner = {
						image: this.imageService.singleImageUploader.uploadedUncertainImage,
						url: null,
						title: this.nameBanner,
						mobileImage: null,
						slug: this.slug,
						content: {
							query: this.bannerQueryService.query,
							group: this.contentType === api.BannerContentType.group ? this.bannerGroupService.group : null,
							url:  this.contentType === api.BannerContentType.url ? this.contentUrl : null,
						},
						innerBanner: this.contentType === api.BannerContentType.group ? this.bannerGroupService.innerBanner : null,
						contentType: (this.contentType === "null" as api.BannerContentType) ? null : this.contentType,
						type: this.type,
					};
					this.selectedBanner = await api.createBanner(newBanner);
					uiStore.showSnackbar(strings.banners.createSuccessful);
					routerStore.push("/dashboard/banners");
				}
			} else {
				uiStore.showSnackbar(strings.error.missingImage);
			}
			window.location.reload();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	@action
	public toggleBannerType = (bannerType: api.BannerType) => {
		this.type = bannerType;
	};

	@action
	public toggleBannerContentType = (bannerContentType: api.BannerContentType) => {
		this.contentType = bannerContentType;
	};

	public setValueEnum = (bannerContentType: api.BannerContentType | string) => {
		const setTranslator = api.translateBannerContentType(bannerContentType as api.BannerContentType);

		if (setTranslator.length > 0 ) {
			return setTranslator;
		} else {
			return "Nenhum";
		}
	};

	@action
	public startBannerCreateOrEdit(bannerId?: string) {
		if (bannerId) {
			routerStore.push(`/dashboard/banners/editor/${bannerId}`);
		} else {
			this.selectedBanner = null;
			routerStore.push(`/dashboard/banners/editor`);
		}
	}
}

// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import { RouterStore } from "mobx-react-router";
import AuthStore from "../../stores/AuthStore";

// MARK: Pages
import Home from "./Home";
import Items from "./Items";
import Users from "./Users";
import Requests from "./Requests";
import Fines from "./Fines";
import Promotions from "./Promotions";
import Report from "./Report";
import BankAccount from "./BankAccount";
import Licensed from "./Licensed";
import Blog from "./Blog";
import AdminUsers from "./AdminUsers";
import Banners from "./Banners";
import Sales from "./Sales";
import Feed from "./Feed";

interface IProps {
	routerStore: RouterStore;
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Dashboard extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
	};

	public render() {
		return (
			<Switch>
				<Route exact={true} path="/dashboard/" component={Home} />
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/items" component={Items} />
				<Route path="/dashboard/requests" component={Requests} />
				<Route path="/dashboard/fines" component={Fines} />
				<Route path="/dashboard/promotions" component={Promotions} />
				<Route path="/dashboard/report" component={Report} />
				<Route path="/dashboard/bankAccount" component={BankAccount} />
				<Route path="/dashboard/licensed" component={Licensed} />
				<Route path="/dashboard/adminUsers" component={AdminUsers} />
				<Route path="/dashboard/blog" component={Blog} />
				<Route path="/dashboard/feed" component={Feed} />
				<Route path="/dashboard/banners" component={Banners} />
				<Route path="/dashboard/sales" component={Sales} />
			</Switch>
		);
	}
}

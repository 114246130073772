// MARK: Components
import ChangeDateDialog from "../../../../components/ChangeDateDialog";
import FineDialog from "../../../../components/FineDialog";
import PageTable from "../../../../components/PageTable";

// MARK: Layout
import DashboardLayout from "../../../../layouts/DashboardLayout";

// MARK: Mobx
import { inject, observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Resources
import strings from "../../../../resources/strings";
import { currencyForBR } from "../../../../resources/format";

// MARK: Stores
import AuthStore from "../../../../stores/AuthStore";
import RequestsStore from "../../../../stores/RequestsStore";

// MARK: Style
import "./style.scss";

interface IProps extends IStores { }

interface IStores {
	authStore: AuthStore;
	requestsStore: RequestsStore;
}

@inject("authStore", "requestsStore")
@observer
export default class RequestsTable extends React.Component<IProps> {
	public static defaultProps: IStores;

	public async componentDidMount() {
		this.props.requestsStore.getRents();
		this.props.requestsStore.getRentsStats();
	}

	public render() {
		const { requestsStore } = this.props;

		return (
			<DashboardLayout>
				<PageTable
					filter={{
						filters: [
							{
								title: strings.filter.period.startRent,
								type: "period",
								filterPeriodService: requestsStore.filterStartRentService,
							},
							{
								title: strings.filter.period.endRent,
								type: "period",
								filterPeriodService: requestsStore.filterEndRentService,
							},
							{
								title: strings.filter.period.createdAt,
								type: "period",
								filterPeriodService: requestsStore.filterCreatedAtRentService,
							},
							{
								title: strings.filter.currentStatus,
								type: "enumList",
								filterEnumListService: requestsStore.filterCurrentStatusService,
							},
						],
						badgesFilter: requestsStore.badgesFilter,
						clearFilter: requestsStore.onClearFilter,
						onFilter: requestsStore.onFilter,
					}}
					editColumnsTable={{
						allColumns: requestsStore.allColumns,
						saveSelectedColumns: requestsStore.saveSelectedColumns,
						selectedColumns: requestsStore.selectedColumns,
					}}
					statsList={[
						{
							label: strings.requests.rentsStats.totalAmount,
							value: requestsStore.rentsStats ? requestsStore.rentsStats.totalAmount.toString() : "",
						}, {
							label: strings.requests.rentsStats.totalPrice,
							value: currencyForBR(requestsStore.rentsStats ? requestsStore.rentsStats.totalPrice : 0),
						}, {
							label: strings.requests.rentsStats.averageTicked,
							value: currencyForBR(requestsStore.rentsStats ? requestsStore.rentsStats.averageTicked : 0),
						},
					]}
					statsLoading={requestsStore.rentStatsLoading}
					onExportTable={requestsStore.getRequestReport}
					tableConfig={{
						title: strings.requests.title,
						header: requestsStore.headerTable,
						nextPage: requestsStore.nextPage,
						prevPage: requestsStore.previousPage,
						rows: requestsStore.rowsTable,
						pageOffset: requestsStore.requestsPageOffset,
						sortService: requestsStore.sortTableService,
						loading: requestsStore.requestsLoading,
						onDetailClick: requestsStore.redirectToRequestDetails,
						onDropDownClick: [{
							name: strings.requests.table.dropDown.generateFine,
							onClick: (requestId) => requestsStore.openFineDialog(requestId),
						}, {
							name: strings.requests.table.dropDown.changeData,
							onClick: (requestId) => requestsStore.openChangeRequestDateDialog(requestId),
						}],
					}}
				/>
				<FineDialog
					requestsStore={requestsStore}
					onDialogConfirm={() => requestsStore.createRequestFine()}
					clearDialog={() => requestsStore.closeFineDialog()}
					isOpen={requestsStore.fineDialogIsOpen}
					dialogTitle={requestsStore.fineDialogTitle}
				/>
				<ChangeDateDialog
					requestsStore={requestsStore}
				/>
			</DashboardLayout>
		);
	}
}

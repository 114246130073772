// MARK: Style
import "./style.scss";
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// TODO remove material from this component
// MARK: Components
import { default as TextField, TextFieldProps as MTextFieldProps } from "@material-ui/core/TextField";
import { default as Typography } from "@material-ui/core/Typography";

export type TextFieldProps = MTextFieldProps & { errorMessage?: string | null };
@observer
export default class InputText extends React.Component<TextFieldProps, {}> {
	public render = () => {
		const { errorMessage, variant, style, ...restProps } = this.props;

		return (
			<>
				<div
					className="textFieldContainer"
				>
					<TextField
						{...restProps}
						margin="normal"
						style={{ width: "100%", ...style }}
						variant={variant || "outlined" as any}
					>
						{restProps.children}
					</TextField>
				</div>
				{errorMessage && (
					<Typography
						className="errorTypography"
						variant="caption"
						style={{ color: "red" }}
					>
						{errorMessage}
					</Typography>
				)}
			</>
		);
	}
}

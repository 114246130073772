// MARK: System Stores
import { RouterStore } from "mobx-react-router";
import UIStore from "./_UIStore";

export const uiStore = new UIStore();
export const routerStore = new RouterStore();

// MARK: Stores
import AuthStore from "./AuthStore";
import UsersStore from "./UsersStore";
import RequestsStore from "./RequestsStore";
import ItemsStore from "./ItemsStore";
import PromotionsStore from "./PromotionsStore";
import FinesStore from "./FinesStore";
import ReportStore from "./ReportStore";
import RegisterStore from "./RegisterStore";
import BalanceStore from "./BalanceStore";
import BlogStore from "./BlogStore";
import BannerStore from "./BannerStore";
import SalesStore from "./SalesStore";
import HomeStore from "./HomeStore";
import FeedStore from "./FeedStore";

export const authStore = new AuthStore();
export const usersStore = new UsersStore();
export const requestsStore = new RequestsStore();
export const itemsStore = new ItemsStore();
export const promotionsStore = new PromotionsStore();
export const finesStore = new FinesStore();
export const reportStore = new ReportStore();
export const registerStore = new RegisterStore();
export const balanceStore = new BalanceStore();
export const blogStore = new BlogStore();
export const bannerStore = new BannerStore();
export const salesStore = new SalesStore();
export const homeStore = new HomeStore();
export const feedStore = new FeedStore();

export const rootStore = {
	routerStore,
	uiStore,

	authStore,
	usersStore,
	requestsStore,
	promotionsStore,
	itemsStore,
	finesStore,
	reportStore,
	registerStore,
	balanceStore,
	blogStore,
	bannerStore,
	salesStore,
	homeStore,
	feedStore,
};

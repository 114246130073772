export interface IBankNameAndCode {
	name: string;
	code: string;
}

export function getBankNamesAndCode(): IBankNameAndCode[] {
	return [
		{
			name: "Banco do Brasil S.A.",
			code: "001",
		},
		{
			name: "Banco Itaú S.A.",
			code: "341",
		},
		{
			name: "Banco Santander (Brasil) S.A.",
			code: "033",
		},
		{
			name: "Banco Real S.A.",
			code: "356",
		},
		{
			name: "Itaú Unibanco Holding S.A.",
			code: "652",
		},
		{
			name: "Banco Bradesco S.A.",
			code: "237",
		},
		{
			name: "Banco Citibank S.A.",
			code: "745",
		},
		{
			name: "HSBC Bank Brasil S.A. – Banco Múltiplo",
			code: "399",
		},
		{
			name: "Caixa Econômica Federal",
			code: "104",
		},
		{
			name: "Banco Mercantil do Brasil S.A.",
			code: "389",
		},
		{
			name: "Nu Pagamentos S.A.",
			code: "260",
		},
		{
			name: "Outro Banco",
			code: "000",
		},
	];
}

// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import {inject, observer} from "mobx-react";

// MARK: Components
import SaveAndCancelFormButtons from "../../../../components/SaveAndCancelFormButtons";
import Divider from "../../../../components/Divider";

// MARK: Stores
import UsersStore from "../../../../stores/UsersStore";
import {routerStore} from "../../../../stores/_rootStore";
import EditAddress from "./EditAddress";
import {match as Match} from "react-router";
import EditProfile from "./EditProfile";

interface IProps {
	usersStore: UsersStore;
	match: Match<{ userId?: string }>;
}

@inject("usersStore")
@observer
export default class EditUser extends React.Component<IProps> {
	public componentDidMount = async () => {
		const userId = this.props.match.params.userId;
		if (userId) {
			await this.props.usersStore.getUser(userId, true);
		}
	};

	public render() {
		const { usersStore } = this.props;
		return (
			<div className="containerEdit">
				<form
					className="userForm"
					onSubmit={(e) => {
						e.preventDefault();
					}}
				>
					<EditProfile
						usersStore={usersStore}
					/>
					<Divider className="divider" />
					<EditAddress
						addressService={usersStore.addressService}
						loading={usersStore.loading}
					/>
					<SaveAndCancelFormButtons
						onSave={usersStore.editUser}
						onCancel={() => routerStore.push (`/dashboard/users`)}
						loading={usersStore.loading}
					/>
				</form>
			</div>
		);
	}
}

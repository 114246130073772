// MARK: React
import * as React from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";

// MARK: Components

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";

// MARK: Layout
import FeedTable from "./FeedTable";
import FeedPostDetails from "./FeedPostDetails";

interface IProps {
	authStore: AuthStore;
}

@inject("authStore")
@observer
export default class Feed extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.authStore.authenticate();
		this.props.authStore.openPageIfIsAdmin();
	};

	public render() {
		return (
			<div className="blog">
				<Switch>
					<Route exact={true} path="/dashboard/feed/" component={FeedTable} />
					<Route path="/dashboard/feed/postDetails/:postId" component={FeedPostDetails} />
				</Switch>
			</div>
		);
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Components
import { IBadgesFilter } from "../../components/PageTable";
import { IColumnItem } from "../../components/Table/TableRow";

// MARK: Libraries
import moment from "moment";

// MARK: Mobx
import { action, observable, computed } from "mobx";

// MARK: Resources
import strings from "../../resources/strings";
import { currencyForBR } from "../../resources/format";
import { downloadFile } from "../../resources/FileManager";

// MARK: Services
import FilterPeriodService from "../../services/FilterService/FilterPeriodService";
import SortTableService from "../../services/SortTableService";
import FilterEnumListService from "../../services/FilterService/FilterEnumListService";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, routerStore } from "../_rootStore";

interface IColumnDynamicItem extends IColumnItem {
	header: string;
}
export default class RequestsStore extends VariableChangeHandler {
	public filterStartRentService: FilterPeriodService;
	public filterEndRentService: FilterPeriodService;
	public filterCreatedAtRentService: FilterPeriodService;
	public filterCurrentStatusService: FilterEnumListService<api.LocStatus>;
	public sortTableService: SortTableService<api.RequestFieldsOrderBy>;

	constructor() {
		super();

		this.selectedColumns = this.allColumns;

		this.filterStartRentService = new FilterPeriodService(this.onFilter);
		this.filterEndRentService = new FilterPeriodService(this.onFilter);
		this.filterCreatedAtRentService = new FilterPeriodService(this.onFilter);
		this.sortTableService = new SortTableService<api.RequestFieldsOrderBy>(
			this.onFilter,
			api.valueFromTranslationRequestFieldsOrderBy,
			api.allDisplayableValuesRequestFieldsOrderBy,
		);
		this.filterCurrentStatusService = new FilterEnumListService<api.LocStatus>(
			this.onFilter,
			api.allValuesLocStatus,
			api.translateLocStatus,
		);
	}

	// Controls
	@observable public requests: api.Request[] = [];
	@observable public requestsLoading: boolean = false;
	@observable public requestsPageOffset: number = 0;
	@observable public enumsValuesLoading: boolean = false;

	@computed
	public get loading() {
		return [
			this.requestsLoading,
			this.rentStatsLoading,
		].reduce((l, r) => l || r);
	}

	// Variables
	@observable public inputStart: Date = new Date();
	@observable public inputEnd: Date = new Date();
	@observable public selectedRequest: api.Request | null = null;

	@computed
	public get period() {
		return {
			start: this.inputStart,
			end: this.inputEnd,
		};
	}

	@computed
	public get filter() : api.RentsFilter {
		return {
			startRentDate: this.filterStartRentService.period,
			endRentDate: this.filterEndRentService.period,
			createdAtRentDate: this.filterCreatedAtRentService.period,
			status: this.filterCurrentStatusService.enumsList,
			orderBy: this.sortTableService.sortOrder,
		};
	}

	// RequestFine
	@observable public payedBy: api.OwnerOrRequester | "" = api.OwnerOrRequester.owner;
	@observable public payedTo: api.OwnerOrRequester | "" = api.OwnerOrRequester.owner;
	@observable public reason: string = "";
	@observable public amount: number = 0;

	@observable public requestFinePayeds: Array<api.OwnerOrRequester | string> = [];

	@computed
	// tslint:disable-next-line:cognitive-complexity
	public get badgesFilter() : IBadgesFilter[] {
		let listBadges: IBadgesFilter[] = [];

		if (this.filterStartRentService.period) {
			if (this.filterStartRentService.periodStartDate && this.filterStartRentService.periodEndDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.startAndEnd(
						strings.filter.rentPeriods.start,
						strings.formatter.date.date(this.filterStartRentService.periodStartDate),
						strings.formatter.date.date(this.filterStartRentService.periodEndDate),
					),
					onClear: () => {
						this.filterStartRentService.onPeriodStartDateChange(null);
						this.filterStartRentService.onPeriodEndDateChange(null);
					},
				});
			} else if (this.filterStartRentService.periodStartDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.onlyStart(
						strings.filter.rentPeriods.start,
						strings.formatter.date.date(this.filterStartRentService.periodStartDate),
					),
					onClear: () => this.filterStartRentService.onPeriodStartDateChange(null),
				});
			} else if (this.filterStartRentService.periodEndDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.onlyEnd(
						strings.filter.rentPeriods.start,
						strings.formatter.date.date(this.filterStartRentService.periodEndDate),
					),
					onClear: () => this.filterStartRentService.onPeriodEndDateChange(null),
				});
			}
		}
		if (this.filterEndRentService.period) {
			if (this.filterEndRentService.periodStartDate && this.filterEndRentService.periodEndDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.startAndEnd(
						strings.filter.rentPeriods.end,
						strings.formatter.date.date(this.filterEndRentService.periodStartDate),
						strings.formatter.date.date(this.filterEndRentService.periodEndDate),
					),
					onClear: () => {
						this.filterEndRentService.onPeriodStartDateChange(null);
						this.filterEndRentService.onPeriodEndDateChange(null);
					},
				});
			} else if (this.filterEndRentService.periodStartDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.onlyStart(
						strings.filter.rentPeriods.end,
						strings.formatter.date.date(this.filterEndRentService.periodStartDate),
					),
					onClear: () => this.filterEndRentService.onPeriodEndDateChange(null),
				});
			} else if (this.filterEndRentService.periodEndDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.onlyEnd(
						strings.filter.rentPeriods.end,
						strings.formatter.date.date(this.filterEndRentService.periodEndDate),
					),
					onClear: () => this.filterEndRentService.onPeriodEndDateChange(null),
				});
			}
		}
		if (this.filterCreatedAtRentService.period) {
			if (this.filterCreatedAtRentService.periodStartDate && this.filterCreatedAtRentService.periodEndDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.startAndEnd(
						strings.filter.createdAt,
						strings.formatter.date.date(this.filterCreatedAtRentService.periodStartDate),
						strings.formatter.date.date(this.filterCreatedAtRentService.periodEndDate),
					),
					onClear: () => {
						this.filterCreatedAtRentService.onPeriodStartDateChange(null);
						this.filterCreatedAtRentService.onPeriodEndDateChange(null);
					},
				});
			} else if (this.filterCreatedAtRentService.periodStartDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.onlyStart(
						strings.filter.createdAt,
						strings.formatter.date.date(this.filterCreatedAtRentService.periodStartDate),
					),
					onClear: () => this.filterCreatedAtRentService.onPeriodStartDateChange(null),
				});
			} else if (this.filterCreatedAtRentService.periodEndDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.onlyEnd(
						strings.filter.createdAt,
						strings.formatter.date.date(this.filterCreatedAtRentService.periodEndDate),
					),
					onClear: () => this.filterCreatedAtRentService.onPeriodEndDateChange(null),
				});
			}
		}
		listBadges = listBadges.concat(
			this.filterCurrentStatusService.enumsList.map((status) => ({
				label: strings.filter.badges.currentStatus(api.translateLocStatus(status)),
				onClear: () => this.filterCurrentStatusService.toggleEnum(status),
			})),
		);

		return listBadges;
	}

	// Input
	@action
	public onInputStartChange = (date: Date) => {
		this.inputStart = date;
	};
	@action
	public onInputEndChange = (date: Date) => {
		this.inputEnd = date;
	};

	// Columns Table
	@observable public allColumns: string[] = [
		strings.requests.table.header.id,
		strings.requests.table.header.numId,
		strings.common.owner,
		strings.requests.table.header.requester,
		strings.requests.table.header.period,
		strings.requests.table.header.currentStatus,
		strings.common.originalPrice,
		strings.enum.RequestFieldsOrderBy.rentPrice,
		strings.enum.RequestFieldsOrderBy.createdAt,
		strings.requests.table.header.cupom,
	];

	@observable public selectedColumns: string[] = [];

	@action
	public saveSelectedColumns = (selectedColumns: string[]) => {
		this.selectedColumns = selectedColumns;
	};

	@computed
	public get headerTable() {
		return this.selectedColumns;
	}

	// Table
	@computed
	public get rowsTable() {
		return this.requests.map((rent) => {
			const allColumns: IColumnDynamicItem[] = [
				{
					header: strings.requests.table.header.id,
					value: rent.id,
				},
				{
					header: strings.requests.table.header.numId,
					value: rent.numId,
				},
				{
					header: strings.common.owner,
					value: `${rent.owner.name} (${rent.owner.nick})`,
				},
				{
					header: strings.requests.table.header.requester,
					value: `${rent.requester.name} (${rent.requester.nick})`,
				},
				{
					header: strings.requests.table.header.period,
					value: `${moment(rent.period.start).format(strings.moment.date)} - ${moment(rent.period.end).format(strings.moment.date)}`,
				},
				{
					header: strings.requests.table.header.currentStatus,
					value: api.translateLocStatus(rent.currentStatus.status),
				},
				{
					header: strings.common.originalPrice,
					value:  currencyForBR(rent.item.originalPrice),
				},
				{
					header: strings.enum.RequestFieldsOrderBy.rentPrice,
					value: currencyForBR(rent.rentPrice),
				},
				{
					header: strings.enum.RequestFieldsOrderBy.createdAt,
					value: moment(rent.createdAt).format(strings.moment.date),
				},
				{
					header: strings.requests.table.header.cupom,
					value: rent.promotion.id ? "rent..id" : strings.common.noCupom,
				},
			];

			return {
				id: rent.id,
				name: rent.requester.name,
				columns: this.selectedColumns
					.map((selectedColumn) => {
						return allColumns.find((column) => column.header === selectedColumn);
					})
					.filter((x) => !!x) as IColumnItem[],
			};
		});
	}

	@action
	public getRequest = async (requestId: string) => {
		if (this.requestsLoading) {
			return;
		}
		this.requestsLoading = true;
		try {
			this.selectedRequest = await api.getRequest(requestId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.requestsLoading = false;
		}
	};

	@action
	public getRents = async (requestsPageOffset?: number) => {
		if (this.requestsLoading) {
			return;
		}

		this.requestsLoading = true;

		if (!requestsPageOffset) {
			requestsPageOffset = 0;
		}

		if (requestsPageOffset < 0) {
			this.requestsLoading = false;
			return;
		}

		try {
			this.requests = await api.getRents(requestsPageOffset, this.filter);
			this.requestsPageOffset = requestsPageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.requestsLoading = false;
		}
	};

	@action
	public cancelRequest = async (id: string) => {
		this.requestsLoading = true;
		try {
			await api.cancelRequest(id);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.requestsLoading = false;
		}
	};

	@action
	public nextPage = async () => {
		await this.getRents(this.requestsPageOffset + 1);
	};

	@action
	public previousPage = async () => {
		await this.getRents(this.requestsPageOffset - 1);
	};

	@action
	public redirectToRequestDetails = async (requestId: string) => {
		routerStore.push(`/dashboard/requests/${requestId}`);
	};

	@action
	public createRequestFine = async () => {
		if (this.loading) {
			return;
		}
		this.requestsLoading = true;
		try {
			if (this.selectedRequest) {
				const createRequestFine: api.CreateRequestFine = {
					requestId: this.selectedRequest.id,
					payedBy: this.payedBy === "" ? null : this.payedBy,
					payedTo: this.payedTo === "" ? null : this.payedTo,
					reason: this.reason,
					amount: +this.amount,
				};
				await api.createRequestFine(createRequestFine);
				this.closeFineDialog();
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.requestsLoading = false;
		}
	};

	@action
	public getRequestFineEnumValues = async () => {
		if (this.enumsValuesLoading) {
			return;
		}
		this.enumsValuesLoading = true;
		try {
			this.requestFinePayeds = api.allValuesOwnerOrRequester();
			this.requestFinePayeds.push("");
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		}	finally {
			this.enumsValuesLoading = false;
		}
	};

	@action
	public togglePayedBy = (payedBy: api.OwnerOrRequester) => {
		this.payedBy = payedBy;
	};

	@action
	public togglePayedTo = (payedTo: api.OwnerOrRequester) => {
		this.payedTo = payedTo;
	};

	@action
	public changeRequestDate = async () =>  {
		if (this.loading) {
			return;
		}
		this.requestsLoading = true;
		try {
			if (this.selectedRequest) {
				this.selectedRequest =  await api.changeDatesRequest(this.selectedRequest.id, this.period);
				this.closeChangeRequestDateDialog();
				window.location.reload();
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.requestsLoading = false;
		}
	};

	// FineDialog
	@observable public fineDialogIsOpen: boolean = false;
	@observable public fineDialogTitle: string = "";

	@action
	public openFineDialog = (requestId: string) => {
		this.getRequest(requestId);
		this.getRequestFineEnumValues();
		this.fineDialogTitle = strings.requests.table.fineDialog.title;
		this.fineDialogIsOpen = true;
	};

	@action
	public clearFineDialog = () => {
		this.payedBy = api.OwnerOrRequester.owner;
		this.payedTo = api.OwnerOrRequester.owner;
		this.reason = "";
		this.amount = 0;
		this.selectedRequest = null;
	}

	@action
	public closeFineDialog = () => {
		this.fineDialogIsOpen = false;
		this.clearFineDialog();
	};

	// ChangeDateDialog
	@observable public changeDateDialogIsOpen: boolean = false;
	@observable public changeDateDialogTitle: string = "";

	@action
	public openChangeRequestDateDialog = (requestId: string) => {
		this.getRequest(requestId);
		this.changeDateDialogIsOpen = true;
		this.changeDateDialogTitle = strings.requests.table.changeDateDialog.title;
	};

	@action
	public clearChangeRequestDateDialog = () => {
		this.inputStart = new Date();
		this.inputEnd = new Date();
		this.selectedRequest = null;
	}

	@action
	public closeChangeRequestDateDialog = () => {
		this.changeDateDialogIsOpen = false;
		this.clearChangeRequestDateDialog();
	};

	// Export CSV

	@action
	public getRequestReport = async () => {
		if (this.requestsLoading ) {
			return;
		}
		this.requestsLoading = true;
		try {
			const url =  await api.getRentsReportForAdminUser(this.filter);
			downloadFile(url);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.requestsLoading = false;
		}
	};

	// OnFilter
	@action
	public onFilter = async () => {
		this.getRentsStats();
		this.getRents(0);
	};

	@action
	public onClearFilter = async () => {
		this.filterStartRentService.clear();
		this.filterEndRentService.clear();
		this.filterCreatedAtRentService.clear();
		this.filterCurrentStatusService.clear();

		await this.onFilter();
	};

	// RequestsStats
	@observable public rentsStats: api.TableStats | null;
	@observable public rentStatsLoading: boolean = false;

	@action
	public getRentsStats = async () => {
		if (this.rentStatsLoading) {
			return;
		}

		this.rentStatsLoading = true;

		try {
			this.rentsStats = await api.getRentsStats(this.filter);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.rentStatsLoading = false;
		}
	};
}

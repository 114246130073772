// MARK: React
import * as React from "react";
import "./style.scss";
// MARK: Components
import { default as MenuItem } from "@material-ui/core/MenuItem";
import { default as Menu } from "@material-ui/core/Menu";
import { default as KeyboardArrowDown } from "@material-ui/icons/KeyboardArrowDown";

interface IProps {
	label: string;
	name: string;
	value: any;
	itemIdentification?: (value: any) => string;
	handleChange: (value: any) => any;
	items: any[];
	translator?: (element: any) => string;
	disabled?: boolean;
}

interface IState {
	isOpen: boolean;
}

export default class ListSelect extends React.Component<IProps, IState> {
	public myButton: any;
	constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: false,
		};

		this.handleOpen = this.handleOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	public handleClose() {
		this.setState({isOpen: false});
		this.myButton = null;
	}

	public handleOpen(e: any) {
		this.setState({isOpen: true});
		this.myButton = e.target;
	}

	public render = () => {
		const { label, value, handleChange, items, translator } = this.props;

		return (
			<div className="listSelectContainer">
				<label>{label}</label>
				<button
					ref={this.myButton}
					className="example-custom-input"
					onClick={(e) => this.handleOpen(e)}
				>
					{translator ? translator(value) : value}
					<KeyboardArrowDown/>
				</button>
				<Menu
					id="simple-menu"
					keepMounted={true}
					anchorEl={this.myButton}
					open={this.state.isOpen}
					onClose={this.handleClose}
				>
					{
						items.map((item, index) => (
							<MenuItem
								disabled={this.props.disabled}
								key={item + index}
								onClick={() => {
									this.handleClose();
									handleChange(item);
								}}
							>
								{translator ? translator(item) : item}
							</MenuItem>
						))
					}
				</Menu>
			</div>
		);
	}
}


// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import {inject, observer} from "mobx-react";

// MARK: API
import { allValuesGender, allValuesLocUserType, translateGender, translateLocUserType } from "@startapp/loc-admin-api";

// MARK: Components
import InputText from "../../../../../components/InputText";
import SelectEnum from "../../../../../components/SelectEnum";
import DatePicker from "../../../../../components/DatePicker";

// MARK: Stores
import UsersStore from "../../../../../stores/UsersStore";

// MARK: Resources
import strings from "../../../../../resources/strings";
import DashboardLayout from "../../../../../layouts/DashboardLayout";

interface IProps {
	usersStore: UsersStore;
}

@observer
export default class EditProfile extends React.Component<IProps> {
	public render() {
		const { usersStore } = this.props;
		return (
			<>
				<DashboardLayout backButton={true}>
					<h2 className="title">{strings.users.table.title}</h2>
					<div className="registerForm">
						<InputText
							label={strings.users.table.header.name}
							name="userName"
							value={usersStore.userName}
							type="text"
							onChange={usersStore.handleValueChange}
							disabled={usersStore.loading}
						/>
						<InputText
							label={strings.users.table.header.nick}
							name="userNick"
							value={usersStore.userNick}
							type="text"
							onChange={usersStore.handleValueChange}
							disabled={usersStore.loading}
						/>
						<InputText
							label={strings.users.table.header.email}
							name="userEmail"
							value={usersStore.userEmail}
							type="email"
							onChange={usersStore.handleValueChange}
							disabled={usersStore.loading}
						/>
						<div className="twoFields">
							<InputText
								label={strings.users.table.header.phone}
								name="userPhone"
								value={usersStore.userPhone}
								type="text"
								onChange={usersStore.handleValueChange}
								disabled={usersStore.loading}
							/>
							{usersStore.userBirthday &&
							// tslint:disable-next-line:jsx-wrap-multiline
								<DatePicker
									label={strings.users.table.header.birthday}
									name="userBirthday"
									value={usersStore.userBirthday}
									onChange={usersStore.handleDateChange}
								/>
							}
						</div>
						<div className="twoFields">
							<SelectEnum
								label={strings.users.table.header.gender}
								enumTranslator={translateGender}
								selectedEnum={usersStore.userGender}
								onChange={usersStore.toggleGender}
								enumValues={allValuesGender()}
							/>
							<InputText
								label={strings.users.table.header.height}
								name="userHeight"
								value={usersStore.userHeight}
								type="text"
								onChange={usersStore.handleValueChange}
								disabled={usersStore.loading}
							/>
							<div>
								<h3>{strings.users.verifiedDocument}</h3>
								<form>
									<label>
										<input
											type="radio"
											value="sim"
											aria-checked={usersStore.verifiedDocument}
											checked={usersStore.verifiedDocument}
											onClick={() => usersStore.toggleVerifiedDocument(true)}
										/>
										Sim
									</label>
									<label>
										<input
											type="radio"
											value="nao"
											aria-checked={!usersStore.verifiedDocument}
											checked={!usersStore.verifiedDocument}
											onClick={() => usersStore.toggleVerifiedDocument(false)}
										/>
										Não
									</label>
								</form>
							</div>
						</div>
						<div className="extraUsersInfo">
							<InputText
								label={strings.users.table.header.popularity}
								name="userBasePopularity"
								value={usersStore.userBasePopularity}
								type="text"
								onChange={usersStore.handleValueChange}
								disabled={usersStore.loading}
							/>
							<SelectEnum
								label={strings.users.table.header.type}
								enumTranslator={translateLocUserType}
								selectedEnum={usersStore.userType}
								onChange={usersStore.toggleLocType}
								enumValues={allValuesLocUserType()}
							/>
							<DatePicker
								label={strings.users.table.header.blockedUntil}
								name={"blockedUntil"}
								value={usersStore.blockedUntil}
								onChange={usersStore.handleBlock}
							/>
							<button
								style={{
									height: 58,
									marginTop: 15,
									cursor: "pointer",
								}}
								onClick={() => usersStore.handleBlock(null)}
							>
								{strings.users.table.header.unBlock}
							</button>
						</div>
					</div>
				</DashboardLayout>
			</>
		);
	}
}

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Mobx
import { action, computed, observable } from "mobx";

// MARK: Libraries
import moment from "moment";
import { IBadgesFilter, IColumnDynamicItem } from "../../components/PageTable";

// MARK: Components
import { ColumnType, IColumnItem } from "../../components/Table/TableRow";
import { currencyForBR } from "../../resources/format";

// MARK: Resources
import strings from "../../resources/strings";
import VariableChangeHandler from "../_helpers/VariableChangeHandler";

// MARK: Stores
import { routerStore, uiStore } from "../_rootStore";
import FilterPeriodService from "../../services/FilterService/FilterPeriodService";
import SortTableService from "../../services/SortTableService";
import { downloadFile } from "../../resources/FileManager";

export default class ItemsStore extends VariableChangeHandler {
	public sortTableService: SortTableService<api.ItemFieldsOrderBy>;
	public filterCreatedAtService: FilterPeriodService;

	// Controls
	@observable public itemPageOffset: number = 0;
	@observable public itemsLoading: boolean = false;

	// Variables
	@observable public items : api.Item[] = [];
	@observable public selectedItem: api.Item | null = null;

	constructor() {
		super();

		this.selectedColumns = this.allColumns;
		this.sortTableService = new SortTableService<api.ItemFieldsOrderBy>(
			this.onFilter,
			api.valueFromTranslationItemFieldsOrderBy,
			api.allDisplayableValuesItemFieldsOrderBy,
		);
		this.filterCreatedAtService = new FilterPeriodService(this.onFilter);
	}

	@computed
	public get loading() {
		return [
			this.itemStatsLoading,
			this.itemsLoading,
		].reduce((l, r) => l || r);
	}

	@computed
	public get filter(): api.ItemFilter {
		return {
			createdAtRentDate: this.filterCreatedAtService.period,
			orderBy: this.sortTableService.sortOrder,
		};
	}

	// Input Variables
	@observable public adjustment: boolean = false;
	@observable public category: api.Category | null = null;
	@observable public color: api.Color | null = null;
	@observable public details: string = "";
	@observable public gender: api.Gender = api.Gender.female;
	@observable public brand: string = "";
	@observable public occasions: api.Ocasion[] = [];
	@observable public selectedOccasion: api.Ocasion = api.Ocasion.beach;
	@observable public rentPrice: string = "";
	@observable public salePrice: string = "";
	@observable public originalPrice: string = "";
	@observable public size: api.Size | null = null;

	// Table Columns
	@observable public selectedColumns: string[] = [];

	@observable public allColumns: string[] = [
		strings.item.table.header.images,
		strings.item.table.header.id,
		strings.item.ownerItem,
		strings.enum.ItemFieldsOrderBy.rentPrice,
		strings.enum.ItemFieldsOrderBy.originalPrice,
		strings.enum.ItemFieldsOrderBy.salePrice,
		strings.enum.ItemFieldsOrderBy.createdAt,
	];

	@action
	public saveSelectedColumns = (selectedColumns: string[]) => {
		this.selectedColumns = selectedColumns;
	};

	@computed
	public get headerTable() {
		return this.selectedColumns;
	}
	@computed
	public get rowsTable() {
		return this.items.map((item) => {
			const allColumns: IColumnDynamicItem[] = [
				{
					header: strings.item.table.header.images,
					type: ColumnType.image,
					value: item.images[0].url,
				},
				{
					header: strings.item.table.header.id,
					value: item.id,
				},
				{
					header: strings.item.ownerItem,
					value: `${item.owner.name} (${item.owner.nick})`,
				},
				{
					header: strings.enum.ItemFieldsOrderBy.rentPrice,
					value: currencyForBR(item.rentPrice || 0),
				},
				{
					header: strings.enum.ItemFieldsOrderBy.originalPrice,
					value: currencyForBR(item.originalPrice),
				},
				{
					header: strings.enum.ItemFieldsOrderBy.salePrice,
					value: currencyForBR(item.salePrice || 0),
				},
				{
					header: strings.enum.ItemFieldsOrderBy.createdAt,
					value: strings.formatter.date.date(item.createdAt),
				},
			];

			return {
				id: item.id,
				name: item.owner.name,
				columns: this.selectedColumns
					.map((selectedColumn) => {
						return allColumns.find((column) => column.header === selectedColumn);
					})
					.filter((x) => !!x) as IColumnItem[],
			};
		});
	}

	@action
	public getItem = async (itemId: string, onEdit: boolean = false) => {
		if (this.itemsLoading) {
			return;
		}

		this.itemsLoading = true;

		try {
			const findItem = this.items.find((item) => itemId === item.id);
			this.selectedItem = findItem ? findItem : await api.getItem(itemId);
			if (onEdit && this.selectedItem) {
				this.setEditorFields(this.selectedItem);
			}
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.itemsLoading = false;
		}
	};

	// Export CSV
	@action
	public getUsersReport = async () => {
		if (this.itemsLoading) {
			return;
		}
		this.itemsLoading = true;
		try {
			const url = await api.getItemsReportForAdmin(this.filter);
			downloadFile(url);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.itemsLoading = false;
		}
	};

	@action
	public getItems = async (itemsPageOffset?: number, ownerId?: string) => {
		if (this.itemsLoading) {
			return;
		}

		this.itemsLoading = true;

		if (!itemsPageOffset) {
			itemsPageOffset = 0;
		}

		if (itemsPageOffset < 0) {
			this.itemsLoading = false;
			return;
		}

		try {
			let newItems: api.Item[] | null;

			if (ownerId) {
				newItems = await api.getLocker(ownerId, { pageNumber: itemsPageOffset });
			} else {
				newItems = await api.getItems(itemsPageOffset, this.filter);
			}

			this.items = newItems;
			this.itemPageOffset = itemsPageOffset;
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.itemsLoading = false;
		}
	};

	public periodCalendar = (periods: api.Period[]) => {
		let calendar: string = "";
		periods.forEach((period) => {
			calendar += strings.item.period(moment(period.start).format(strings.moment.date), moment(period.end).format(strings.moment.date));
		});
		return calendar;
	};

	public translateOccasions = (occasions: Array<api.Ocasion | null>) => {
		const existentOccasions: api.Ocasion[] = [];

		occasions.forEach((occasion) => {
			if (occasion) {
				existentOccasions.push(occasion);
			}
		});

		let occasionStrings: string = "";
		existentOccasions.forEach((occasion, index) => {
			occasionStrings += `
				${api.translateOcasion(occasion)}${index === (existentOccasions.length - 1) ? "." : " ," }
			`;
		});
		return occasionStrings;
	};

	@action
	public nextPage = async (ownerId?: string) => {
		await this.getItems(this.itemPageOffset + 1, ownerId);
	};

	@action
	public previousPage = async (ownerId?: string) => {
		await this.getItems(this.itemPageOffset - 1, ownerId);
	};

	@action
	public redirectToItemDetails = async (itemId: string) => {
		this.getItem(itemId);
		routerStore.push(`/dashboard/items/${itemId}`);
	};

	@action
	public deleteItem = async (itemId: string) => {
		if (this.itemsLoading) {
			return;
		}
		this.itemsLoading = true;
		try {
			await api.deleteItem(itemId);
			this.items = this.items.filter((item) => item.id !== itemId);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.itemsLoading = false;
		}
	};

	@action
	public openDeleteItemDialog = async (itemId: string, itemName: string) => {
		uiStore.showDialog(
			{
				title: strings.item.table.deleteItemDialog.title,
				message: `${strings.item.table.deleteItemDialog.message}: ${itemName}`,
			},
			() => this.deleteItem(itemId),
		);
	}

	@action
	public setEditorFields = (item: api.Item) => {
		this.adjustment = item.adjustment;
		this.category = item.category;
		this.color = item.color;
		this.details = item.details;
		this.gender = item.gender;
		this.brand = item.brand || "";
		this.rentPrice = (item.rentPrice || 0).toString();
		this.salePrice = (item.salePrice || 0).toString();
		this.originalPrice = item.originalPrice.toString();
		this.size = item.size;
		this.occasions = item.ocasions.reduce(
			(l, r) => {
				if (r) {
					l.push(r);
				}
				return l;
			},
			[] as api.Ocasion[],
		);
	};

	@action
	public clearEditorFields = () => {
		this.adjustment = false;
		this.category = null;
		this.color = null;
		this.details = "";
		this.gender = api.Gender.female;
		this.brand = "";
		this.rentPrice = "";
		this.salePrice = "";
		this.originalPrice = "";
		this.size = null;
	};

	@action
	public createOrEditItem = async () => {
		if (this.itemsLoading) {
			return;
		}

		try {
			if (this.selectedItem) {
				const itemDetails : api.ItemDetails = {
					adjustment: this.adjustment,
					category: this.category || api.Category.accessory,
					color: this.color || api.Color.beige,
					details: this.details,
					gender: this.gender,
					images: this.selectedItem.images,
					brand: this.brand,
					ocasions: this.occasions,
					rentPrice: this.rentPrice.length > 0 ? +this.rentPrice : null,
					salePrice: this.salePrice.length > 0 ? +this.salePrice : null,
					originalPrice: +this.originalPrice,
					size: this.size || api.Size.sXXL,
				};
				await api.editItem(this.selectedItem.id, itemDetails);
				routerStore.replace("/dashboard/items");
				// window.location.reload();
			}

		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.itemsLoading = false;
		}
	};

	@action
	public startEventCreateOrEdit(itemId: string) {
		this.getItem(itemId);
		if (this.selectedItem) {
			this.setEditorFields(this.selectedItem);
			routerStore.push(`/dashboard/items/editor/${itemId}`);
		}
	}

	@action
	public toggleColor = (color: api.Color) => {
		this.color = color;
	};

	@action
	public toggleCategory = (category: api.Category) => {
		this.category = category;
	};

	@action
	public toggleSize = (size: api.Size) => {
		this.size = size;
	};

	@action
	public toggleGender = (gender: api.Gender) => {
		this.gender = gender;
	};

	@action
	public toggleOccasion = (ocasion: api.Ocasion) => {
		this.selectedOccasion = ocasion;
	};

	@action
	public toggleAdjustment = () => {
		this.adjustment = !this.adjustment;
	};

	@action
	public addOccasion = () => {
		if (this.occasions.indexOf(this.selectedOccasion) === -1) {
			this.occasions.push(this.selectedOccasion);
			this.selectedOccasion = api.Ocasion.beach;
		}
	};

	@action
	public removeOccasion = (occasion: api.Ocasion) => {
		const index = this.occasions.indexOf(occasion);
		if (index >= 0) {
			this.occasions.splice(index, 1);
		}
	};

	// ItemStats
	@observable public itemStats: api.ItemStats | null = null;
	@observable public itemStatsLoading: boolean = false;

	@action
	public getItemStats = async () => {
		if (this.itemStatsLoading) {
			return;
		}

		this.itemStatsLoading = true;

		try {
			this.itemStats = await api.getItemsStats(this.filter);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.itemStatsLoading = false;
		}
	};

	// Sort
	@observable public selectedItemFieldsOrderBy: api.ItemFieldsOrderBy = api.ItemFieldsOrderBy.createdAt;
	@observable public currentSortOrder: api.TableOrderBy = api.TableOrderBy.asc;

	@action
	public onSortClick = async (sortHeader: string) => {
		if (api.allDisplayableValuesItemFieldsOrderBy().includes(sortHeader)) {
			const sort = api.valueFromTranslationItemFieldsOrderBy(sortHeader);
			if (this.selectedItemFieldsOrderBy !== sort) {
				this.selectedItemFieldsOrderBy = sort;
				this.currentSortOrder = api.TableOrderBy.desc;
			} else {
				this.selectedItemFieldsOrderBy = sort;
				this.currentSortOrder = this.currentSortOrder === api.TableOrderBy.asc ?
					api.TableOrderBy.desc :
					api.TableOrderBy.asc
				;
			}
			this.getItems();
		}
	};

	// filter
	@action
	public onFilter = async () => {
		this.getItems(0);
		this.getItemStats();
	};

	@action
	public onClearFilter = async () => {
		this.filterCreatedAtService.clear();
	};

	@computed
	public get badgesFilter() : IBadgesFilter[] {
		let listBadges: IBadgesFilter[] = [];
		if (this.filterCreatedAtService.period) {
			if (this.filterCreatedAtService.periodStartDate && this.filterCreatedAtService.periodEndDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.startAndEnd(
						strings.filter.createdAt,
						strings.formatter.date.date(this.filterCreatedAtService.periodStartDate),
						strings.formatter.date.date(this.filterCreatedAtService.periodEndDate),
					),
					onClear: () => {
						this.filterCreatedAtService.onPeriodStartDateChange(null);
						this.filterCreatedAtService.onPeriodEndDateChange(null);
					},
				});
			} else if (this.filterCreatedAtService.periodStartDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.onlyStart(
						strings.filter.createdAt,
						strings.formatter.date.date(this.filterCreatedAtService.periodStartDate),
					),
					onClear: () => this.filterCreatedAtService.onPeriodStartDateChange(null),
				});
			} else if (this.filterCreatedAtService.periodEndDate) {
				listBadges = listBadges.concat({
					label: strings.filter.badges.onlyEnd(
						strings.filter.createdAt,
						strings.formatter.date.date(this.filterCreatedAtService.periodEndDate),
					),
					onClear: () => this.filterCreatedAtService.onPeriodEndDateChange(null),
				});
			}
		}

		return listBadges;
	}
}

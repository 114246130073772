// MARK: Components
import Button from "../../components/Button";
import Table from "../../components/Table";
import { IColumnItem, IRowItem } from "../Table/TableRow";
import { IDropDownItem } from "../Table/TableRow/TableDropDown";
import EditTable from "./componets/EditTable";
import StatsTable, { IStatsTable } from "./componets/StatsTable";
import Chip from "@material-ui/core/Chip";
import { Paper } from "@material-ui/core";
import FilterPeriodPicker from "./componets/FilterPeriodPicker";
import FilterTextPopOver from "./componets/FilterTextPopOver";
import FilterEnumPicker from "./componets/FilterEnumPicker";
import FilterEnumListPicker from "./componets/FilterEnumListPicker";

// MARK: Mobx
import { observer } from "mobx-react";

// MARK: React
import * as React from "react";

// MARK: Resources
import strings from "../../resources/strings";

// MARK: Services
import SortTableService from "../../services/SortTableService";
import FilterTextService from "../../services/FilterService/FilterTextService";
import FilterPeriodService from "../../services/FilterService/FilterPeriodService";
import FilterEnumService from "../../services/FilterService/FilterEnumService";
import FilterEnumListService from "../../services/FilterService/FilterEnumListService";

// MARK: Icons
import { CloudDownload as DownloadIcon } from "@material-ui/icons";
import { FilterList as FilterIcon } from "@material-ui/icons";
import { Edit as EditIcon } from "@material-ui/icons";

// Style
import "./style.scss";

type IFilterType = "period" | "text" | "enum" | "enumList";
interface IFilter {
	type: IFilterType;
	title: string;
	filterPeriodService?: FilterPeriodService;
	filterTextService?: FilterTextService;
	filterEnumService?: FilterEnumService<any>;
	filterEnumListService?: FilterEnumListService<any>;
}

export interface IBadgesFilter {
	label: string;
	onClear: () => void;
}

export interface IFilterComponent {
	title: string;
	component: React.ReactNode;
}

export interface IColumnDynamicItem extends IColumnItem {
	header: string;
}

interface IProps {
	editColumnsTable?: {
		selectedColumns: string[];
		allColumns: string[];
		saveSelectedColumns: (selectedColumns: string[]) => void;
	};

	filter?: {
		badgesFilter: IBadgesFilter[];
		// filterComponents: IFilterComponent[];
		filters: IFilter[];
		onFilter: () => void;
		clearFilter: () => void;
	};

	statsList?: IStatsTable[];
	statsLoading?: boolean;

	topListTable?: React.ReactNode;
	onExportTable?: () => void;

	tableConfig: {
		sortService?: SortTableService<any>;
		title: string;
		header: string[];
		nextPage?: () => void;
		loading: boolean;
		prevPage?: () => void;
		rows: IRowItem[];
		pageOffset?: number;
		onAddClick?: () => void;
		onClick?: (id: string) => void;
		onEditClick?: (itemId: string) => void;
		onDetailClick?: (itemId: string) => void;
		onDeleteClick?: (id: string, name: string) => void;
		onSelectClick?: (id: string) => void;
		onActiveClick?: (id: string, name: string) => void;
		onDropDownClick?: IDropDownItem[];
		activeSelectionsToolbar?: React.ReactNode;
	};
}

interface IState {
	filterOpen: boolean;
	editTableOpen: boolean;
}

@observer
export default class PageTable extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

		this.state = {
			filterOpen: !isMobile ? !!props.filter : false,
			editTableOpen: false,
		};
	}

	private toggleFilter = () => {
		this.setState({
			filterOpen: !this.state.filterOpen,
		});
	};

	private toggleEditTable = () => {
		this.setState({
			editTableOpen: !this.state.editTableOpen,
		});
	};

	private clearButtonElement = () => {
		return this.props.filter ? (
			<Button
				className="onClear"
				onClick={this.props.filter.clearFilter}
			>
				{strings.filter.textField.clear}
			</Button>
		) : null;
	};

	private getFilterComponent = (filter: IFilter) => {
		switch (filter.type) {
			case "period":
				return <FilterPeriodPicker service={filter.filterPeriodService!} />;
			case "text":
				return <FilterTextPopOver service={filter.filterTextService!} />;
			case "enum":
				return <FilterEnumPicker service={filter.filterEnumService!} />;
			case "enumList":
				return <FilterEnumListPicker service={filter.filterEnumListService!} />;
		}
	}

	public render = () => {
		const { topListTable, statsList, onExportTable, tableConfig, filter, editColumnsTable } = this.props;
		const { filterOpen, editTableOpen } = this.state;

		return (
			<>
				<div className="pageTableContainer">
					<Paper className={filterOpen ? "pageTableFilter" : "pageTableFilterClosed"}>
						{filter && filter.filters.map((mapFilter) => {
							return (
								<div className="filterSection" key={mapFilter.title}>
									<h4>{mapFilter.title}</h4>
									<div className="filterForm">
										{this.getFilterComponent(mapFilter)}
									</div>
								</div>
							);
						})}
						<div className="fieldButton">
							{this.clearButtonElement()}
							<Button
								className="onClose"
								onClick={this.toggleFilter}
							>
								{strings.buttons.close}
							</Button>
						</div>
						{filter && (
							<Button
								className="submitFilterButtonMobile"
								onClick={ () => {
									filter.onFilter();
									this.toggleFilter();
								}}
							>
								{strings.buttons.filter}
							</Button>
						)}
						{this.clearButtonElement()}
					</Paper>
					<div className={filterOpen ? "pageTableOnFilter" : "pageTable"}>
						{(filter && filter.badgesFilter.length > 0) && (
							<div className="badgesView">
								{filter.badgesFilter.map((badge) => (
									<Chip
										key={badge.label}
										className="metaTagChip"
										label={badge.label}
										onDelete={badge.onClear}
									/>
								))}
							</div>
						)}
						{topListTable}
						{statsList && (
							<StatsTable
								statsList={statsList}
								statsLoading={this.props.statsLoading}
							/>
						)}
						{(editColumnsTable && editTableOpen) ? (
							<div className="editTableView">
								<EditTable
									selectedColumns={editColumnsTable.selectedColumns}
									allColumns={editColumnsTable.allColumns}
									saveSelectedColumns={(selectedColumns) => {
										editColumnsTable.saveSelectedColumns(selectedColumns);
										this.toggleEditTable();
									}}
								/>
							</div>
						) : <></>}
						<div className="table">
							<Table
								toolbar={(
									<div className="toolbar">
										{editColumnsTable && (
											<EditIcon
												titleAccess={strings.components.table.titleIcon.editTable}
												className={editTableOpen ? "toolbarIconSelected" : "toolbarIcon"}
												onClick={this.toggleEditTable}
											/>
										)}
										{filter && (
											<FilterIcon
												titleAccess={strings.components.table.titleIcon.openFilter}
												className={filterOpen ? "toolbarIconSelected" : "toolbarIcon"}
												onClick={this.toggleFilter}
											/>
										)}
										{onExportTable && (
											<DownloadIcon
												titleAccess={strings.components.table.titleIcon.exportCSV}
												className="toolbarIcon"
												onClick={onExportTable}
											/>
										)}
									</div>
								)}
								activeSelectionsToolbar={(
									<div className="toolbar">
										{tableConfig.activeSelectionsToolbar}
									</div>
								)}
								tableIsEmptyMessage={strings.common.noMoreResults}
								header={tableConfig.header}
								title={tableConfig.title}
								loading={tableConfig.loading}
								nextPage={tableConfig.nextPage}
								prevPage={tableConfig.prevPage}
								rows={tableConfig.rows}
								pageOffset={tableConfig.pageOffset}
								onAddClick={tableConfig.onAddClick}
								onEditClick={tableConfig.onEditClick}
								onDetailsClick={tableConfig.onDetailClick}
								onDropDownClick={tableConfig.onDropDownClick}
								onClick={tableConfig.onClick}
								onSelectClick={tableConfig.onSelectClick}
								onActiveClick={tableConfig.onActiveClick}
								sortService={tableConfig.sortService}
								onDeleteClick={tableConfig.onDeleteClick}
							/>
						</div>
					</div>
				</div>
			</>
		);
	};
}

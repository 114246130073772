export const openInputImageDialog = (onResolve: (file: File, url: string) => void, onError: (message: string) => void) => {
	const input = document.createElement("input");
	input.setAttribute("type", "file");
	input.setAttribute("accept", "image/*");
	input.click();
	input.onchange = () => {

		if (input.files && input.files.length > 0) {

			const file = input.files[0];
			const url = URL.createObjectURL(file);

			onResolve(file, url);
		} else {
			onError("Arquivo selecionado é inválido");
		}
	};
};

// MARK: API
import * as api from "@startapp/loc-admin-api";

// MARK: Libraries
import moment from "moment";

// MARK: Mobx
import { action, computed, observable } from "mobx";
import strings from "../../resources/strings";

// MARK: Stores
import VariableChangeHandler from "../_helpers/VariableChangeHandler";
import { uiStore, authStore } from "../_rootStore";

export interface ICity {
	id: string;
	name: string;
}

export interface IGraphicData {
	sales: Array<{
		value: number;
		label: string;
		sortable: Date | number;
	}>;
	rents: Array<{
		value: number;
		label: string;
		sortable: Date | number;
	}>;
}

export enum TypeButtons {
	today = "today",
	yesterday = "yesterday",
	sevenBefore = "sevenBefore",
	fifteenBefore = "fifteenBefore",
	thirtyBefore = "thirtyBefore",
	custom = "custom",
}

export default class HomeStore extends VariableChangeHandler {
	@observable public homeData: api.AdminHomeData | null = null;
	@observable public homeDataLoading: boolean = false;

	@observable public previousHomeData: api.AdminHomeData | null = null;
	@observable public previousHomeDataLoading: boolean = false;

	@observable public startDate: Date = new Date();
	@observable public endDate: Date = new Date();

	@observable public dataPerDate: Date = new Date();
	@observable public dataPerDateHour: Date = new Date();
	@observable public dataPerHour: Date = new Date();

	@observable public selectedPeriodFilter: "hour" | "date" | "dateAndHour" = "hour";
	@observable public selectedAmountFilter: api.HomeGraphicType = api.HomeGraphicType.value;

	@observable public typeButton: TypeButtons = TypeButtons.today;

	@computed
	public get loading() {
		return [
			this.homeDataLoading,
			this.graphicDataLoading,
			this.previousHomeDataLoading,
		].reduce((l, r) => l || r);
	}

	@action
	public setTypeButton = (button: TypeButtons) => {
		if (button === TypeButtons.today || button === TypeButtons.yesterday) {
			this.selectedPeriodFilter = "hour";
		} else {
			this.selectedPeriodFilter = "date";
		}

		this.typeButton = button;
	};

	@action
	public onClick = () => {
		this.setPeriod(
			moment().subtract(1, "days").toDate(),
			moment().toDate(),
		);
		this.setTypeButton(
			TypeButtons.yesterday,
		);
	};

	@action
	public setSelectedPeriodGraphic = (filter: "hour" | "date" | "dateAndHour") => {
		this.selectedPeriodFilter = filter;

		this.graphicDataPerPeriod();
	};
	@action
	public setSelectedAmountGrafic = (filter: api.HomeGraphicType) => {
		this.selectedAmountFilter = filter;

		this.graphicDataPerPeriod();
	};

	@action
	public onStartDateChange = (date: Date) => {
		this.startDate = date;
	};

	@action
	public onEndDateChange = (date: Date) => {
		this.endDate = date;
	};

	@action
	public setPeriod = async (startDate: Date, endDate: Date) => {
		this.startDate = startDate;
		this.endDate = endDate;

		this.getInitialHomeData();
	};

	@action
	public setDatePeriod = async (amountDays: number) => {
		this.setPeriod(
			moment().subtract(amountDays, "days").toDate(),
			moment().toDate(),
		);

	};
	@action
	public getInitialHomeData = async () => {
		this.getHomeData();
		this.getPreviousHomeData();
		this.graphicDataPerPeriod();
	};
	@action
	public getHomeData = async () => {
		if (this.homeDataLoading) {
			return;
		}

		this.homeDataLoading = true;
		try {
			const startDate = moment(this.startDate).startOf("day").toDate();
			const endDate = moment(this.endDate).endOf("day").toDate();

			this.homeData = await api.getHomeData(startDate, endDate, this.selectedCities);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.homeDataLoading = false;
		}
	};

	@action
	public getPreviousHomeData = async () => {
		if (this.previousHomeDataLoading) {
			return;
		}

		this.previousHomeDataLoading = true;

		try {
			const period = moment(this.endDate).diff(this.startDate);
			const startDate = moment(this.startDate).subtract(period).subtract(1, "day").startOf("day").toDate();
			const endDate = moment(this.endDate).subtract(period).subtract(1, "day").endOf("day").toDate();

			this.previousHomeData = await api.getHomeData(startDate, endDate, this.selectedCities);
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.previousHomeDataLoading = false;
		}
	}

	// AdminCities
	@observable public adminFilterCitiesLoading: boolean = false;
	@observable public selectedAdminCities: api.FilterCity[] = [];

	@action
	public getAvailableAdminFilterCities = async () => {
		if (this.adminFilterCitiesLoading) {
			return;
		}

		this.adminFilterCitiesLoading = true;
		try {
			this.selectedAdminCities = await api.getAvailableAdminFilterCities();
		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.adminFilterCitiesLoading = false;
		}
	};

	@observable public openedCityMenu: boolean = false;
	@observable public selectedCities: string[] = [];

	@computed
	public get cities(): ICity[] {
		if (authStore.adminUser && authStore.adminUser.licensedInfo) {
			return authStore.adminUser.licensedInfo.licenses.map((license) => ({
				id: license.city.id,
				name: license.city.name,
			}));
		}

		if (authStore.adminUser ) {
			if (authStore.adminUser.type === "admin") {
				return this.selectedAdminCities;

			}
		}

		return [];
	}

	@action
	public toggleCityMenu = () => {
		this.openedCityMenu = !this.openedCityMenu;
	};

	@action
	public selectCity = async (cityId: string) => {
		const index = this.selectedCities.findIndex((cityIds) => cityId === cityIds);

		if (index > -1) {
			this.selectedCities.splice(index, 1);
		} else {
			this.selectedCities.push(cityId);
		}

		await this.getHomeData();
	};

	@action
	public selectAllCities = () => {
		this.selectedCities = this.cities.map((city) => city.id);
	};

	@observable public graphicData: IGraphicData;
	@observable public graphicDataLoading = false;

	@action
	public graphicDataPerPeriod = async () => {
		if (this.graphicDataLoading) {
			return;
		}

		this.graphicDataLoading = true;

		try {
			const startDate = moment(this.startDate).startOf("day").toDate();
			const endDate = moment(this.endDate).endOf("day").toDate();

			switch (this.selectedPeriodFilter) {
				case "date": {
					const data = await api.getGraphicDataPerDate(
						startDate,
						endDate,
						this.selectedCities,
						this.selectedAmountFilter,
					);

					this.graphicData = {
						rents: data.rents.map((rent) => ({
							label: moment(rent.date).format(strings.moment.date),
							value: rent.value,
							sortable: rent.date,
						})),
						sales: data.sales.map((sale) => ({
							label: moment(sale.date).format(strings.moment.date),
							value: sale.value,
							sortable: sale.date,
						})),
					};
					break;

				}
				case "hour": {
					const data = await api.getGraphicDataPerHour(
						startDate,
						endDate,
						this.selectedCities,
						this.selectedAmountFilter,
					);

					this.graphicData = {
						rents: data.rents.map((rent) => ({
							label: `${rent.hour}h`,
							value: rent.value,
							sortable: rent.hour,
						})),
						sales: data.sales.map((sale) => ({
							label: `${sale.hour}h`,
							value: sale.value,
							sortable: sale.hour,
						})),
					};
					break;

				}
				case "dateAndHour": {
					const data = await api.getGraphicDataPerDateHour(
						startDate,
						endDate,
						this.selectedCities,
						this.selectedAmountFilter,
					);

					this.graphicData = {
						rents: data.rents.map((rent) => ({
							label: moment(rent.dateHour).format(strings.moment.dateHour),
							value: rent.value,
							sortable: rent.dateHour,

						})),
						sales: data.sales.map((sale) => ({
							label: moment(sale.dateHour).format(strings.moment.dateHour),
							value: sale.value,
							sortable: sale.dateHour,
						})),
					};

					break;
				}
			}

		} catch (e) {
			uiStore.showErrorSnackbar(e);
		} finally {
			this.graphicDataLoading = false;
		}
	};

	@computed
	// tslint:disable-next-line:cognitive-complexity
	public get getGraphicData() {
		const graphicData = this.graphicData;
		const returnTimeOrNumber = (value: Date | number): number => {
			return typeof value !== "number" ? value.getTime() : value;
		};

		return graphicData ?

			Array.from(new Set([
				...graphicData.rents.map((r) => JSON.stringify({
					label: r.label,
					sort: returnTimeOrNumber(r.sortable),
				})),
				...graphicData.sales.map((r) => JSON.stringify({
					label: r.label,
					sort: returnTimeOrNumber(r.sortable),
				})),
			]))
				.map((x) => JSON.parse(x) as { label: string, sort: number})
				.sort((a, b) => a.sort - b.sort)
				.map((label) => {
					const rentReportDate = graphicData.rents.find((r) =>  returnTimeOrNumber(r.sortable) === label.sort) || null;
					const saleReportDate = graphicData.sales.find((r) => returnTimeOrNumber(r.sortable) === label.sort) || null;
					const rentAmount = rentReportDate ? rentReportDate.value : 0;
					const saleAmount = saleReportDate ? saleReportDate.value : 0;

					return {
						rentAmount,
						saleAmount,
						totalAmount: rentAmount + saleAmount,
						label: label.label,
					};
				}) : [];
	}

	@observable public modalCustomPeriod: boolean = false;

	@action
	public toggleModalCustomPeriod = () => {
		this.modalCustomPeriod = !this.modalCustomPeriod;
	};

	@action
	public setCustomPeriod = async () => {
		this.modalCustomPeriod = false;
		this.typeButton = TypeButtons.custom;
		await this.getInitialHomeData();
	};
}

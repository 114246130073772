// MARK: React
import * as React from "react";

// MARK: Style
import "./style.scss";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Stores
import AuthStore from "../../../stores/AuthStore";
import BalanceStore from "../../../stores/BalanceStore";
import { routerStore } from "../../../stores/_rootStore";

// MARK: Layout
import DashboardLayout from "../../../layouts/DashboardLayout";

// MARK: Component
import Button from "../../../components/Button";
import BankAccount from "../BankAccount";
import Divider from "../../../components/Divider";

// MARK: API
import { Balance } from "@startapp/loc-admin-api";

// MARK: Resources
import strings from "../../../resources/strings";
import { currencyForBR } from "../../../resources/format";
import TransferDialog from "../../../components/TransferDialog";

// MARK: Libraries
import moment from "moment";

interface IProps {
	authStore: AuthStore;
	bankAccount: BankAccount | null;
	balanceStore: BalanceStore;
	balance: Balance | null;
}

@inject("authStore", "balanceStore")
@observer
export default class Licensed extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.balanceStore.getBalance();
	};

	public render() {
		const { authStore, balanceStore } = this.props;
		const adminUser = authStore.adminUser;

		return (
			<DashboardLayout>
				{adminUser && (
					<div className="licensedContainer">
						<div className="licensedWrapper">
							<div className="licensedTitle">
								<h1>{strings.licensed.title}</h1>
									<Button
										onClick={() => {
											routerStore.push(`/register/${adminUser.id}`);
										}}
									>
										{strings.common.edit}
									</Button>
							</div>
							<div className="licensedContent">
								<div className="licensedBlock">
									{adminUser.licensedInfo &&
										adminUser.licensedInfo.licenses.map((license) => (
											<>
												<h2>{strings.licensed.licenses.title + `${license.city.name}-${license.city.uf}`}</h2>
												<div className="licensedLine">
													<div className="licensedDetail">
														<label>{strings.licensed.licenses.percentage}</label>
														<p>{`${license.percentage} %`}</p>
													</div>
												</div>
												<div className="licensedLine">
													<div className="licensedDetail">
														<label>{strings.licensed.licenses.expirationDate}</label>
														<p>{moment(license.expirationDate).format(strings.moment.date)}</p>
													</div>
												</div>
											</>
										))
									}
								</div>
								{balanceStore.balance && (
									<div className="licensedBlock">
										<div className="blockTitle"><h2>{strings.licensed.bankAccount.balance}</h2></div>
										<div className="balance">
											<div className="balanceTitle">{strings.licensed.bankAccount.waitingFounds}</div>
											<div className="balanceAmount">{currencyForBR(balanceStore.balance.waitingFunds.amount)}</div>
										</div>
										<div className="balance strong">
											<div className="balanceTitle">{strings.licensed.bankAccount.availableFounds}</div>
											<div className="balanceAmount">{currencyForBR(balanceStore.balance.available.amount)}</div>
										</div>
										<div className="balance">
											<div className="balanceTitle">{strings.licensed.bankAccount.totalFounds}</div>
											<div className="balanceAmount">{currencyForBR(balanceStore.balance.available.amount + balanceStore.balance.waitingFunds.amount)}</div>
										</div>
										<div className="buttonArea">
											<Button onClick={balanceStore.toggleDialogTransfer}>
												{strings.licensed.bankAccount.transfer}
											</Button>
										</div>
									</div>
								)}
								<Divider />
								<div className="licensedBlock">
									<h2>{strings.licensed.bankAccount.title}</h2>
									<div className="licensedLine">
										{adminUser.licensedInfo ?
											(
												<div className="licensedDetail">
													<div className="bank">
														<div className="boxDetails">
															<p className="bankTitle">{strings.account.bank}</p>
															<p className="bankDetails"> {adminUser.licensedInfo.bankAccount.bankName} - {adminUser.licensedInfo.bankAccount.bankCode} </p>
														</div>
														<div className="boxDetails">
															<p className="bankTitle">{strings.account.agency}</p>
															<p className="bankDetails">{adminUser.licensedInfo.bankAccount.agency} </p>
														</div>
														<div className="boxDetails">
															<p className="bankTitle">{strings.account.account}</p>
															<p className="bankDetails">{adminUser.licensedInfo.bankAccount.account} - {adminUser.licensedInfo.bankAccount.accountDv}</p>
														</div>
														<div className="boxDetails">
															<p className="bankTitle">{strings.account.legalName}</p>
															<p className="bankDetails">{adminUser.licensedInfo.bankAccount.legalName} </p>
														</div>
													</div>
												</div>
											) :
											(
												<div className="licensedDetail">
													<label>{strings.licensed.bankAccount.notFound}</label>
												</div>
											)
										}
										<div className="buttonEditOrAddAccount">
											<Button
												onClick={() => routerStore.push(`/dashboard/bankAccount`)}
											>
												{(adminUser.licensedInfo && adminUser.licensedInfo.bankAccount) ?
													strings.common.edit :
													strings.common.add
												}
											</Button>
										</div>
										<TransferDialog balanceStore={balanceStore} />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</DashboardLayout>
		);
	}
}

// MARK: React
import * as React from "react";

// MARK: Mobx
import { observer, inject } from "mobx-react";

// MARK: Components
import DashboardLayout from "../../../../layouts/DashboardLayout";
import PageTable from "../../../../components/PageTable";
import ComponentButton from "../../../../components/ComponentButton";

// MARK: Stores
import FeedStore from "../../../../stores/FeedStore";

// MARK: Resources
import strings from "../../../../resources/strings";

interface IProps {
	feedStore: FeedStore;
}

@inject("feedStore")
@observer
export default class FeedTable extends React.Component<IProps> {
	public componentDidMount = async () => {
		await this.props.feedStore.getPosts(0);
		await this.props.feedStore.getAmountPosts();
	};

	public render() {
		const {feedStore} = this.props;
		return (
			<DashboardLayout>
				<div
					style={{
						width: 200,
						background: "white",
						borderRadius: 10,
						boxShadow: "1px 2px 1px rgba(0, 0, 0, .2)",
						marginLeft: 20,
						marginTop: 20,
					}}
				>
					<ComponentButton
						className="card"
						descriptionDate={strings.feed.totalTwit}
						descriptionLabel={feedStore.amountPosts}
						loadingLabel={feedStore.loading}
					/>
				</div>
				<PageTable
					tableConfig={{
						title: strings.feed.title,
						rows: feedStore.feedTableRows,
						header: feedStore.header,
						pageOffset: feedStore.feedPageOffset,
						loading: feedStore.loading,
						prevPage: feedStore.previousPage,
						nextPage: feedStore.nextPage,
						onDeleteClick: feedStore.openDeletePostDialog,
						onDetailClick: feedStore.redirectToPostDetails,
					}}
				/>
			</DashboardLayout>
		);
	}
}

// MARK: React
import { Checkbox, FormControlLabel, Menu, MenuItem } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import "./style.scss";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	anchorEl: any;
	cities: Array<{
		id: string;
		name: string;
	}>;
	selectedCities: string[];
	selectCity: (cityId: string) => void;
}

@observer
export default class DropDownCity extends React.Component<IProps, {}> {
	public render() {
		const { isOpen, onClose } = this.props;

		return (
			<Menu
				open={isOpen}
				onClose={onClose}
				anchorEl={this.props.anchorEl}
			>
				{this.props.cities.map((city) => (
					<MenuItem
						key={city.id}
						onChange={() => this.props.selectCity(city.id)}
					>
						<FormControlLabel
							control={(
								<Checkbox
									checked={this.props.selectedCities.includes(city.id)}
								/>
							)}
							label={city.name}
						/>
					</MenuItem>
				))}
			</Menu>
		);
	}
}

// MARK: React
import * as React from "react";
import "./style.scss";

// MARK: Components
import { IconButton as MIconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// MARK: Icons
import { MoreHoriz as MDropDownIcon } from "@material-ui/icons";

export interface IDropDownItem {
	name: string;
	onClick: (id: string) => void;
}

interface IProps {
	rowItemId: string;
	dropDownItems: IDropDownItem[];
}

interface IState {
	anchorEl: any;
}

export default class TableDropDown extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {anchorEl: null};
	}

	public handleClick = (event) => {
		this.setState({anchorEl: event.currentTarget});
	}

	public handleClose = () => {
		this.setState({anchorEl: null});
	}

	public render() {
		return (
			<div className="dropDownContainer">
				<MIconButton aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
					<MDropDownIcon/>
				</MIconButton>
				<Menu
					id="simple-menu"
					anchorEl={this.state.anchorEl}
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleClose}
				>
					{this.props.dropDownItems.map((item) => {
						return (
							<MenuItem
								key={`${item.name}_${this.props.rowItemId}`}
								onClick={() => { item.onClick(this.props.rowItemId); }}
							>
								{item.name}
							</MenuItem>
						);
					})}
				</Menu>
			</div>
		);
	}
}
